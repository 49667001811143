import axios from "axios";
import ConfigData from "../ConfigurationData/ConfigData.json";

let baseUrl = ConfigData.apiURL;
let appId = ConfigData.appId;

export function getHeaders() {
  return {
    headers: {
      "Content-Type": "application/json, text/plain, */*",
    },
  };
}
export function getTokenFromStorage() {
  return localStorage.getItem("TokenId");
}

export function getToken() {
  return axios
    .get(baseUrl + "GetToken?AppId=" + appId)
    .then(function (response) {
      localStorage.setItem("TokenId", response.data.TokenId);
    })
    .catch(function (error) {
      console.log(error);
    });
}
export function setUserId(userId, PaymentId) {
  localStorage.setItem("UserId", userId);
  localStorage.setItem("PaymentID", JSON.stringify(PaymentId));
}
export function getUserId() {
  return localStorage.getItem("userID");
  // return localStorage.getItem('UserId');
}
export function getUserIdAfterLogin() {
  return localStorage.getItem("UserId");
}

export function getPaymentId() {
  return localStorage.getItem("PaymentID");
}
export function get(url) {
  return axios.get(baseUrl + url + appId);
}
export function getLoginEmail(url, email) {
  return axios.get(
    baseUrl +
      url +
      appId +
      "&TokenId=" +
      getTokenFromStorage() +
      "&EmailId=" +
      email
  );
}
export function getLoginWithPassword(url, uname, email, password) {
  return axios.get(
    baseUrl +
      url +
      appId +
      "&TokenId=" +
      getTokenFromStorage() +
      "&UserName=" +
      uname +
      "&EmailId=" +
      email +
      "&Password=" +
      password
  );
}
export function getWithUserId(url) {
  return axios.get(baseUrl + url + getUserId());
}
export function getHorseMemberIdFromUrl(url) {
  return localStorage.getItem("HorseMemberId");
}
export function getHorseMemberId() {
  return localStorage.getItem("HorseMemberId");
}
