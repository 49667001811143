import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import ConfigData from "../../ConfigurationData/ConfigData.json";
import HorsesTransfer from "./HorseTransfer";
import {
  getToken,
  get,
  getLoginEmail,
  getLoginWithPassword,
  getHorseMemberIdFromUrl,
  getUserId,
  getTokenFromStorage,
} from "../../api/RestServices";

const AppId = ConfigData.appId;
const TokenId = getTokenFromStorage();
let userId = localStorage.getItem("UserId");
let urlAPI = ConfigData.apiURL;
let LoggedInUserId = localStorage.getItem("UserId");
const renewToken = async () => {
  getToken();
};

function HorsesTable() {
  const [horseData, setHorseData] = useState({});
  let [a,setA]=useState();
  const [HorseTransferStatusListDetails, setHorseTransferStatusListDetails] =
    useState("");
  const [HorseTransferStatus, setHorseTransferStatus] = useState("");

  useEffect(() => {
    axios
      .get(
        `${urlAPI}GETOSPortalHorseTransferStatusListDetails?AppId=${AppId}&TokenId=${TokenId}&LoggedInUserId=${LoggedInUserId}`
      )
      .then(function (response) {
        console.log(response.data);
        const horseResponseData = response.data;
        if (
          response.data.TokenInfoDetails.TokenResponse === "Expired" ||
          response.data.TokenInfoDetails.TokenResponse === "Invalid"
        ) {
          getToken();
          renewToken();
          setTimeout(() => {
            axios
              .get(
                `${urlAPI}GETOSPortalHorseTransferStatusListDetails?AppId=${AppId}&TokenId=${TokenId}&LoggedInUserId=${LoggedInUserId}`
              )
              .then(function (response) {
                const horseResponseData = response.data;
                if(response.data.HorseTransferStatusListDetails != null){
                setHorseData(response.data.HorseTransferStatusListDetails);
                }
                // setA(response.data.HorseTransferStatusListDetails.HorseMemberId);
              });
          }, 1000);
        } else {
          if(response.data.HorseTransferStatusListDetails != null){
            setHorseData(response.data.HorseTransferStatusListDetails);
            }
          // setHorseData(response.data.HorseTransferStatusListDetails);
          // setA(response.data.HorseTransferStatusListDetails.HorseMemberId);

        }
      });
  }, []);

  const navigate = useNavigate(); // Initialize the useNavigate hook

  const [step, setStep] = useState(1);
 
  const handleLinkClick = () => {
    // Redirect to '/horse-transfer' when the link is clicked
    setStep(2);
  };

  return (
    <div>
      <div>
        
        {Object.keys(horseData).length > 0 ? (
          <Box
            sx={{ backgroundColor: "#F7F7F9", p: "25px 0" }}
            className="desktop_table"
          >
            <Box sx={{ maxWidth: "800px", margin: "0 auto" }}>
              <Card className="card_box horse_table">
                <CardContent>
                  <Typography
                    variant="h6"
                    sx={{
                      textAlign: "center",
                      pb: 2,
                      fontWeight: "500 !important",
                    }}
                  >
                    Horse Transfer Status List
                  </Typography>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Horse#</TableCell>
                          <TableCell>Name</TableCell>
                          <TableCell>Transfer Status</TableCell>
                          <TableCell>New Manager</TableCell>
                          <TableCell>Initiation</TableCell>
                          <TableCell>Expiration</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                      {Object.keys(horseData).map(
                        (object, i) => (
              <TableRow>
                          <TableCell>{horseData[object].HorseMemberId}</TableCell>
                          <TableCell>{horseData[object].HorseName}</TableCell>
                          <TableCell>
                            <Link to={{
        pathname: '/horse-transfer',
        search: '?HorseMemberId='+horseData[object].HorseMemberId,
        
      }}
      state={{ TransferID: horseData[object].HorseTransferId }}
      onClick={handleLinkClick}
      >
                              {horseData[object].HorseTransferStatus}
                            </Link>
                           
                          </TableCell>
                          <TableCell>{horseData[object].NewManagerName}</TableCell>
                          <TableCell>{horseData[object].InitiationDate}</TableCell>
                          <TableCell>{horseData[object].ExpirationDate}</TableCell>
                        </TableRow>
                        )
                      )}
                        
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>
            </Box>
          </Box>
        ) : (
          <Box
          sx={{ backgroundColor: "#F7F7F9", p: "25px 0" }}
          className=""
        >
          <Box sx={{ maxWidth: "750px", margin: "0 auto" }}>
            <Card className="card_box ">
              <CardContent>
              <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Horse#</TableCell>
                          <TableCell>Name</TableCell>
                          <TableCell>Transfer Status</TableCell>
                          <TableCell>New Manager</TableCell>
                          <TableCell>Initiation</TableCell>
                          <TableCell>Expiration</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                     
                    <TableRow>
                         <TableCell colSpan={6} align="center">No Records Found</TableCell>
                         
                    </TableRow>
                        
                        
                      </TableBody>
                    </Table>
                  </TableContainer>
              </CardContent>
            </Card>
          </Box>
        </Box>
        )}
      </div>
      <div>
        {Object.keys(horseData).length > 0 ? (
          <Box
            sx={{ backgroundColor: "#F7F7F9", p: "25px 0" }}
            className="mobile_table"
          >
            <Box sx={{ maxWidth: "750px", margin: "0 auto" }}>
              <Card className="card_box horse_mobiletable">
                <CardContent>
                  <Typography
                    variant="h6"
                    sx={{
                      textAlign: "center",
                      pb: 2,
                      fontWeight: "500 !important",
                    }}
                  >
                    Horse Transfer Status List
                  </Typography>
                  <TableContainer>
                    <Table>
                      <TableBody>
                        <TableRow>
                          <TableCell>
                            <strong>Horse#: </strong>
                            {horseData.HorseMemberId}
                          </TableCell>
                          <TableCell>
                            <strong>Name: </strong>
                            {horseData.HorseName}
                          </TableCell>
                          <TableCell>
                            <strong>Transfer Status: </strong>
                            <Link to={"/horse-transfer"}>
                              {horseData.HorseTransferStatus}
                            </Link>
                          </TableCell>
                          <TableCell>
                            <strong>New Manager: </strong>
                            {horseData.NewManagerName}
                          </TableCell>
                          <TableCell>
                            <strong>Initiation: </strong>
                            {horseData.InitiationDate}
                          </TableCell>
                          <TableCell>
                            <strong>Expiration: </strong>
                            {horseData.ExpirationDate}
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </CardContent>
              </Card>
            </Box>
          </Box>
        ) : (
          <p>Loading...</p>
        )}
      </div>
    </div>
  );
}

export default HorsesTable;
