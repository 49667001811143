import React, { useEffect, useRef, useState } from "react";
import { Form, Field, FormElement, Text } from "@progress/kendo-react-form";
import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";

import { Error, Hint } from "@progress/kendo-react-labels";
import {
  Input,
  Checkbox,
  TextBox,
  MaskedTextBox,
} from "@progress/kendo-react-inputs";

import ConfigData from "../../ConfigurationData/ConfigData.json";
import axios from "axios";
import {
  getToken,
  get,
  getLoginEmail,
  getLoginWithPassword,
  getUserId,
  getTokenFromStorage,
} from "../../api/RestServices";
import { DropDownList } from "@progress/kendo-react-dropdowns";
// import { Checkbox } from "@progress/kendo-react-inputs";
import { useNavigate } from "react-router-dom";
// import { DatePicker } from "@progress/kendo-react-dateinputs";
import {
  DateInput,
  DateInputChangeEvent,
} from "@progress/kendo-react-dateinputs";
//  import DatePicker from "react-datepicker";
import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";
import { Oval } from "react-loader-spinner";

// ** MUI Imports
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// import Button from '@mui/material/Button'
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import MuiFormControlLabel from "@mui/material/FormControlLabel";
import { Link } from "@mui/material";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";

import queryString from "query-string";
import moment, { relativeTimeRounding } from "moment";
import { useLocation } from "react-router-dom";
import { returnFalse } from "@progress/kendo-react-inputs/dist/npm/maskedtextbox/utils";
const PhoneRegex = new RegExp(/^[0-9_@./#&+-]*$/);
const PhoneValidator = (value) =>
  PhoneRegex.test(value) ? "" : "Please Enter Digit Only";

const FormControlLabel = styled(MuiFormControlLabel)(({ theme }) => ({
  "& .MuiFormControlLabel-label": {
    fontSize: "0.875rem",
    color: theme.palette.text.secondary,
  },
}));

const Phone = (fieldRenderProps) => {
  const { validationMessage, visited, ...others } = fieldRenderProps;
  return (
    <div>
      <Input {...others} />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};

// function validatePhoneNumber(input_str) {
//   var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
//   console.log(re.test(input_str));
//   return re.test(input_str);

// }
// function validateForm(event) {
//   var phone = document.getElementById('primary_phone').value;
//   if (!validatePhoneNumber(phone)) {
//     return true;
//   } else {
//     return false;

//   }
//   // event.preventDefault();
// }
// document.getElementById('update_btn').addEventListener('click', validateForm);

function AccountSettings() {
  const [loading, setLoading] = useState(false);

  const UserTypeIDUser = "CAAFE0BA-6A20-49BF-AA86-8F95F7D9346D";
  const UserTypeIDBusiness = "E19C7428-F0D4-4A78-94CA-93D38DCADD7E";
  const UserTypeIDAffiliate = "75FCFE6C-1A2B-4E02-AEF4-CA9A64DBC348";

  const UserPreferenceIDFirstName = "2C46D3BA-A126-4D81-B834-77BF9E4A76B6";
  const UserPreferenceIDLastName = "78C8257F-03E5-4D0E-A081-54BD59259CCB";
  const UserPreferenceIDDOB = "432BCD17-91AB-4C52-8CB6-0F4EAA191A59";
  const UserPreferenceIDMailingAddress = "F682B5F0-12F9-4B4F-9C65-4B6CDB377FBD";
  const UserPreferenceIDPrimaryPhone = "75DCB0E3-A6F8-4BBD-A206-448715199A6F";
  const UserPreferenceIDAllowTexts = "4F6F1F02-28F9-47E9-A384-1B57568C9273";
  const UserPreferenceIDEmailVerified = "364F4462-38FE-423B-91BA-5B86170B9B13";
  const UserPreferenceIDEmailRequested = "A98A3C43-9007-41EE-BD81-F1E7E92DDA1F";
  const UserPreferenceIDEmergencyContactName =
    "49EC2B57-06D7-4B41-9667-25DC32E81913";
  const UserPreferenceIDEmergencyContactRelationship =
    "9F821361-DD8B-4E37-8BA8-5BDAB71BE995";
  const UserPreferenceIDEmergencyContactPhone =
    "96EAE5FF-0737-4C50-9AF9-86A66D149D5D";
  const UserPreferenceIDParentGuardianName =
    "7B0D69C0-3A06-4110-969D-78DC31F41A2F";
  const UserPreferenceIDParentGuardianPhone =
    "7424E81B-2687-4E94-B328-55BBE24E4EB0";
  const UserPreferenceIDParentGuardianEmail =
    "EC8D765A-A5D7-42F7-A5C7-EF95B9E60406";

  const UserPreferenceIDCompanyName = "AECBE6DF-C58E-4637-A336-6476B6258CC5";
  const UserPreferenceIDCompanyMailingAddress =
    "6E3E58F0-BD43-4C97-9A5D-243CF13B8AD9";
  const UserPreferenceIDCompanyPrimaryPhone =
    "AA6F0DFA-BC6F-4254-96FB-DFB17C3F71B5";
  const UserPreferenceIDCompanyAllowTexts =
    "89BFD2F0-EBFB-4101-9443-52D7AA794F1A";
  const UserPreferenceIDCompanyEmailVerified =
    "0F8B09B6-CA18-4054-AE3B-7599F97EB3B0";
  const UserPreferenceIDCompanyEmailRequested =
    "954055E4-3EEB-4B34-A8C5-9EB4ED3B71B2";

  const UserPreferenceIDAffiliateName = "DDCBB70C-F285-4E9D-9899-2A872218A0F0";
  const UserPreferenceIDAffiliateMailingAddress =
    "CA3946D3-E0E2-42D1-A50B-8834F7C572E0";
  const UserPreferenceIDAffiliatePrimaryPhone =
    "3E611766-14F1-4678-893E-C267938ED5D0";
  const UserPreferenceIDAffiliateAllowTexts =
    "AF7982F3-7966-4DEE-9232-C5DBE0D7137B";
  const UserPreferenceIDAffiliateEmailVerified =
    "11A74733-BF0E-4581-B8EE-9BDB5752ECB5";
  const UserPreferenceIDAffiliateEmailRequested =
    "4F5B0030-EB04-43CE-B419-0E1585A26F19";

  const UserPreferenceIDSubscriptionWeeklyUseaNewsletter =
    "EE676D3B-B95A-41CD-A363-E507D0E3A19B";
  const UserPreferenceIDSubscriptionOfficialAnnouncementNotifications =
    "30B49D68-46F9-4600-A735-2E34FF355FAC";
  const UserPreferenceIDSubscriptionPartnersSpecialOffers =
    "1247D2F4-3805-4B1B-9751-AFC42AD9A6B8";

  const navigate = useNavigate();
  const optionsPref = [];
  const gender = [];
  const [value, setValue] = React.useState("User Profile");
  const [genderValue, setgenderValue] = React.useState();
  const [step, setStep] = React.useState();
  const [userProfileData, setUserProfileData] = useState("");
  const [UserProfileDetails, setUserProfileDetails] = useState("");
  const [UserProfileEmergencyDetails, setUserProfileEmergencyDetails] =
    useState("");
  const [BasicDetails, setBasicDetails] = useState([]);
  const [UserSubscriptionDetails, setUserSubscriptionDetails] = useState("");
  const [UserProfileCommunicationDetails, setUserProfileCommunicationDetails] =
    useState("");
  const [UserPreference, setUserPreference] = useState("");
  const [UserProfileType, setUserProfileType] = useState("");
  const [show, setshow] = useState("");
  const [hide, sethide] = useState(false);
  const [isEmailInvalid, setisEmailInvalid] = useState(true);
  const [hideUnverified,setHideUnverified] = useState(false);

  const [requiredField, setrequiredField] = useState(false);
  const [msg, setMsg] = useState("");
  const [errormsg, setErrorMsg] = useState("");
  const [errorEmail, setErrorEmail] = useState("");
  const [errorPhone, setErrorPhone] = useState("");
  const [objstrMembershipSettingsData, setobjstrMembershipSettingsData] =
    useState();

  let [firstName, setFirstname] = useState("");
  let [firstNameID, setFirstnameID] = useState("");
  let [firstNameDisplayName, setFirstnameDisplayName] = useState("");

  let [lastName, setLastname] = useState("");
  let [lastNameID, setLastnameID] = useState("");
  let [lastNameDisplayName, setLastnameDisplayName] = useState("");

  let [primaryPhone, setPrimaryPhone] = useState("");
  let [primaryPhoneID, setPrimaryPhoneID] = useState("");
  let [primaryPhoneDisplayName, setPrimaryPhoneDisplayName] = useState("");

  let [primaryPhoneAllowText, setPrimaryPhoneAllowText] = useState("");
  let [primaryPhoneAllowTextID, setPrimaryPhoneAllowTextID] = useState("");
  let [primaryPhoneAllowTextDisplayName, setPrimaryPhoneAllowTextDisplayName] =
    useState("");

  const [userProfileParentDetailsName, setUserProfileParentName] = useState("");
  const [userProfileParentDetailsNameID, setUserProfileParentNameID] =
    useState("");
  const [
    userProfileParentDetailsNameDisplayName,
    setUserProfileParentNameDisplayName,
  ] = useState("");

  const [userProfileParentDetailsPhone, setUserProfileParentPhone] =
    useState("");
  const [userProfileParentDetailsPhoneID, setUserProfileParentPhoneID] =
    useState("");
  const [
    userProfileParentDetailsPhoneDisplayName,
    setUserProfileParentPhoneDisplayName,
  ] = useState("");

  const [userProfileParentDetailsEmail, setUserProfileParentEmail] =
    useState("");
  const [userProfileParentDetailsEmailOld, setUserProfileParentEmailOld] =
    useState("");
  const [userProfileParentDetailsPhoneOld, setUserProfileParentPhoneOld] =
    useState("");
  const [userProfileParentDetailsNameOld, setUserProfileParentNameOld] =
    useState("");
  const [userProfileParentDetailsEmailID, setUserProfileParentEmailID] =
    useState("");
  const [
    userProfileParentDetailsEmailDisplayName,
    setUserProfileParentEmailDisplayName,
  ] = useState("");

  const [userProfileEmergencyDetailsName, setUserProfileEmergencyDetailsName] =
    useState("");
  const [
    userProfileEmergencyDetailsNameID,
    setUserProfileEmergencyDetailsNameID,
  ] = useState("");
  const [
    userProfileEmergencyDetailsNameDisplayName,
    setUserProfileEmergencyDetailsNameDisplayName,
  ] = useState("");

  const [
    userProfileEmergencyDetailsPhone,
    setUserProfileEmergencyDetailsPhone,
  ] = useState("");
  const [
    userProfileEmergencyDetailsPhoneID,
    setUserProfileEmergencyDetailsPhoneID,
  ] = useState("");
  const [
    userProfileEmergencyDetailsPhoneDisplayName,
    setUserProfileEmergencyDetailsPhoneDisplayName,
  ] = useState("");

  const [
    userProfileEmergencyDetailsRelation,
    setUserProfileEmergencyDetailsRelation,
  ] = useState("");
  const [
    userProfileEmergencyDetailsRelationID,
    setUserProfileEmergencyDetailsRelationID,
  ] = useState("");
  const [
    userProfileEmergencyDetailsRelationDisplayName,
    setUserProfileEmergencyDetailsRelationDisplayName,
  ] = useState("");

  const [addressPrefID, setaddressPrefID] = useState("");
  const [modelAddressDetails, setModelAddressDetails] = useState({});
  const [addressPrefDisplayName, setaddressPrefDisplayName] = useState("");

  const [emailVerfied, setemailVerified] = useState("");
  const [emailVerfiedID, setemailVerifiedID] = useState("");
  const [emailVerfiedDisplayName, setemailVerifiedDisplayName] = useState("");

  const [emailUnverfiedOld, setemailUnverifiedOld] = useState("");
  const [emailUnverfied, setemailUnverified] = useState("");
  const [emailUnverfiedID, setemailUnverifiedID] = useState("");
  const [emailUnverfiedDisplayName, setemailUnverifiedDisplayName] =
    useState("");
  const [emailVerification, setEmailVerification] = useState(false);
  const [userDob, setuserDob] = useState();
  const [userDobID, setuserDobID] = useState(new Date());
  const [userDobDisplayName, setuserDobDisplayName] = useState(new Date());
  const [isdisableDob, setDisableDob] = useState(true);

  const [GenderPreferenceId, setGenderPreferenceId] = useState("");
  const [GenderPreferenceValue, setGenderPreferenceValue] = useState("");
  const [GenderPreferenceDisplayName, setGenderPreferenceDisplayName] =
    useState("");
  const [GenderPreferenceOptionId, setGenderPreferenceOptionId] = useState("");

  const [CompanyName, setComapanyName] = useState("");
  const [CompanyNameID, setComapanyNameID] = useState("");
  const [CompanyNameDisplayName, setComapanyNameDisplayName] = useState("");

  const [dob, setdob] = useState(moment(userDob).format("YYYY-MM-DD"));
  const [username, setUsername] = useState("");
  const [usernameID, setUsernameID] = useState("");
  const [editEmail, setEditEmail] = useState(false);
  const [showEdit, setShowEdit] = useState(false);
  const [showEdit1, setShowEdit1] = useState(false);
  const [editnewEmail, seteditnewEmail] = useState(true);
  const [editPhone, setEditPhone] = useState(false);
  const [updateEmail, setUpdateEmail] = useState(false);
  const [updatePhone, setUpdatePhone] = useState(false);
  const [emailRequested, setnewEmail] = useState("");
  const [linkSent, setlinkSent] = useState(false);
  const [linkInvalid, setlinkInvalid] = useState(false);
  const [updateEmailDisabled, setupdateEmailDisabled] = useState(false);
  const [prefDisable, setprefDisable] = useState(false);
  const [disableupdate, setDisableUpdate] = useState(false);
  const [empty, setEmpty] = useState(false);
  const [addressSelected,setAddressSelected]=useState(true);
  const PageUrl = useLocation();
  const [disableSaveandNext, setDisableSaveandNext] = useState(false);
  let appId = ConfigData.appId;
  let serviceUrl = ConfigData.serviceUrl;
  let curDT = moment().format("MM/DD/YYYY hh:mm:ssA");

  for (var i = 0; i < UserProfileType.length; i++) {
    if (UserProfileType[i].UserTypeName == "Individual") {
      optionsPref.push("User Profile");
    } else if (UserProfileType[i].UserTypeName == "Company") {
      optionsPref.push("Business Profile");
    } else {
      optionsPref.push(UserProfileType[i].UserTypeName);
    }
  }

  if (UserPreference) {
    for (var j = 0; j < UserPreference.length; j++) {
      gender.push(UserPreference[j].UserProfilePreferenceValue);
    }
  }

  const RightWrapper = styled(Box)(({ theme }) => ({
    width: "100%",
    [theme.breakpoints.up("md")]: {
      maxWidth: 750,
      margin: "0 auto",
    },
    [theme.breakpoints.up("lg")]: {
      maxWidth: 750,
      margin: "0 auto",
    },
  }));

  const checkUpdateDisable = () => {
    if (
      primaryPhone == null ||
      primaryPhone == "" ||
      primaryPhone == "___-___-____"
    ) {
      setDisableUpdate(true);
    } else {
      setDisableUpdate(false);
    }
  };

  const handleChange = (event) => {
    setValue(event.target.value);
    setStep(event.target.value);
    if (
      primaryPhone == null ||
      primaryPhone == "" ||
      primaryPhone == "___-___-____"
    ) {
      setDisableUpdate(true);
    }
  };

  let urlAPI = ConfigData.apiURL;
  let AppId = ConfigData.appId;
  let TokenId = getTokenFromStorage();
  let userId = localStorage.getItem("UserId");

  const renewToken = async () => {
    getToken();
  };

  useEffect(() => {
    
    axios
      .get(
        urlAPI +
          "GetOSPortalUserProfileData?AppId=" +
          AppId +
          "&TokenId=" +
          TokenId +
          "&UserId=" +
          userId
      )
      .then(function (response) {
        console.log(response.data);
        if (
          response.data.TokenInfoDetails.TokenResponse == "Expired" ||
          response.data.TokenInfoDetails.TokenResponse == "Invalid"
        ) {
          getToken();
          renewToken();
          setTimeout(() => {
            axios.get(
              urlAPI +
                "GetOSPortalUserProfileData?AppId=" +
                AppId +
                "&TokenId=" +
                TokenId +
                "&UserId=" +
                userId
            );
          }, 1000);
        }
        if(!response.data.UserProfileData.ShowProfile){
          window.open(
            serviceUrl + "/Login.aspx?user=" + localStorage.getItem("UserId"),
            "_self"
          );

        }
        if (
          response.data.UserProfileData.UserTypeId.toUpperCase() ==
          UserTypeIDAffiliate
        ) {
          setValue("Affiliate Profile");
          setStep("Affiliate Profile");

          if (response.data.UserProfileDetails) {
            setUsername(response.data.UserProfileDetails.LoginName);
            localStorage.setItem('USEA ID',response.data.UserProfileDetails.MemberId);
          }

          if (
            response.data.UserProfileCommunicationDetails &&
            response.data.UserProfileCommunicationDetails.length > 0
          ) {
            for (
              var i = 0;
              i < response.data.UserProfileCommunicationDetails.length;
              i++
            ) {
              if (
                response.data.UserProfileCommunicationDetails[
                  i
                ].UserProfilePreferenceId.toUpperCase() ==
                UserPreferenceIDAffiliateAllowTexts
              ) {
                setPrimaryPhoneAllowText(
                  response.data.UserProfileCommunicationDetails[i]
                    .UserProfilePreferenceValue
                );
                setPrimaryPhoneAllowTextID(
                  response.data.UserProfileCommunicationDetails[i]
                    .UserProfilePreferenceId
                );
                setPrimaryPhoneAllowTextDisplayName(
                  response.data.UserProfileCommunicationDetails[i]
                    .UserProfilePreferenceDisplayName
                );
              }
            }
          }

          for (
            var i = 0;
            i < response.data.UserProfileDetails.UserProfileUserDetails.length;
            i++
          ) {
            if (
              response.data.UserProfileDetails.UserProfileUserDetails[
                i
              ].UserProfilePreferenceId.toUpperCase() ==
              UserPreferenceIDAffiliateName
            ) {
              setComapanyName(
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceValue
              );
              setComapanyNameID(
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceId
              );
              setComapanyNameDisplayName(
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceDisplayName
              );
              if (
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceValue == "" ||
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceValue == null
              ) {
                document.body.classList.add("newUser");
              }
            } else if (
              response.data.UserProfileDetails.UserProfileUserDetails[
                i
              ].UserProfilePreferenceId.toUpperCase() ==
              UserPreferenceIDAffiliateMailingAddress
            ) {
              setaddressPrefID(
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceId
              );
              setaddressPrefDisplayName(
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceDisplayName
              );
            } else if (
              response.data.UserProfileDetails.UserProfileUserDetails[
                i
              ].UserProfilePreferenceId.toUpperCase() ==
              UserPreferenceIDAffiliatePrimaryPhone
            ) {
              setPrimaryPhone(
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceValue
              );
              if (
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceValue == null ||
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceValue == "" ||
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceValue == "___-___-____"
              ) {
                setEditPhone(false);

                document.body.classList.add("newUser");

                setEmpty(true);
              } else {
                setEditPhone(true);
              }
              setPrimaryPhoneID(
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceId
              );
              setPrimaryPhoneDisplayName(
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceDisplayName
              );
            } else if (
              response.data.UserProfileDetails.UserProfileUserDetails[
                i
              ].UserProfilePreferenceId.toUpperCase() ==
              UserPreferenceIDAffiliateEmailVerified
            ) {
              setemailVerified(
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceValue
              );
              setemailVerifiedID(
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceId
              );
              setemailVerifiedDisplayName(
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceDisplayName
              );
            } else if (
              response.data.UserProfileDetails.UserProfileUserDetails[
                i
              ].UserProfilePreferenceId.toUpperCase() ==
              UserPreferenceIDAffiliateEmailRequested
            ) {
              setemailUnverified(
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceValue
              );
              setemailUnverifiedOld(
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceValue
              );
              setemailUnverifiedID(
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceId
              );
              setemailUnverifiedDisplayName(
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceDisplayName
              );
              if (
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceVerification == "EXPIRED"
              ) {
                setEmailVerification(true);
              }

              console.log(emailVerification);
            }
          }

          // if (response.data.UserProfileDetails.UserProfileUserDetails[3].UserProfilePreferenceValue == "" || CompanyName
          // ) {
          //   setprefDisable(true);
          // }
          // if (CompanyName == "") {
          //   setprefDisable(true);
          // }
        } else if (
          response.data.UserProfileData.UserTypeId.toUpperCase() ==
          UserTypeIDBusiness
        ) {
          setValue("Business Profile");
          setStep("Business Profile");

          if (response.data.UserProfileDetails) {
            setUsername(response.data.UserProfileDetails.LoginName);
            localStorage.setItem('USEA ID',response.data.UserProfileDetails.MemberId);

          }

          if (
            response.data.UserProfileCommunicationDetails &&
            response.data.UserProfileCommunicationDetails.length > 0
          ) {
            for (
              var i = 0;
              i < response.data.UserProfileCommunicationDetails.length;
              i++
            ) {
              if (
                response.data.UserProfileCommunicationDetails[
                  i
                ].UserProfilePreferenceId.toUpperCase() ==
                UserPreferenceIDCompanyAllowTexts
              ) {
                setPrimaryPhoneAllowText(
                  response.data.UserProfileCommunicationDetails[i]
                    .UserProfilePreferenceValue
                );
                setPrimaryPhoneAllowTextID(
                  response.data.UserProfileCommunicationDetails[i]
                    .UserProfilePreferenceId
                );
                setPrimaryPhoneAllowTextDisplayName(
                  response.data.UserProfileCommunicationDetails[i]
                    .UserProfilePreferenceDisplayName
                );
              }
            }
          }

          for (
            var i = 0;
            i < response.data.UserProfileDetails.UserProfileUserDetails.length;
            i++
          ) {
            if (
              response.data.UserProfileDetails.UserProfileUserDetails[
                i
              ].UserProfilePreferenceId.toUpperCase() ==
              UserPreferenceIDCompanyName
            ) {
              setComapanyName(
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceValue
              );
              if (
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceValue == "" ||
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceValue == null
              ) {
                document.body.classList.add("newUser");
              }
              setComapanyNameID(
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceId
              );
              setComapanyNameDisplayName(
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceDisplayName
              );
            } else if (
              response.data.UserProfileDetails.UserProfileUserDetails[
                i
              ].UserProfilePreferenceId.toUpperCase() ==
              UserPreferenceIDCompanyMailingAddress
            ) {
              setaddressPrefID(
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceId
              );
              setModelAddressDetails(
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceValue
              );
              setaddressPrefDisplayName(
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceDisplayName
              );
            } else if (
              response.data.UserProfileDetails.UserProfileUserDetails[
                i
              ].UserProfilePreferenceId.toUpperCase() ==
              UserPreferenceIDCompanyPrimaryPhone
            ) {
              setPrimaryPhone(
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceValue
              );
              if (
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceValue == null ||
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceValue == "" ||
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceValue == "___-___-____"
              ) {
                setEditPhone(false);
                document.body.classList.add("newUser");
              } else {
                setEditPhone(true);
              }
              setPrimaryPhoneID(
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceId
              );
              setPrimaryPhoneDisplayName(
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceDisplayName
              );
            } else if (
              response.data.UserProfileDetails.UserProfileUserDetails[
                i
              ].UserProfilePreferenceId.toUpperCase() ==
              UserPreferenceIDCompanyEmailVerified
            ) {
              setemailVerified(
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceValue
              );
              setemailVerifiedID(
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceId
              );
              setemailVerifiedDisplayName(
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceDisplayName
              );
            } else if (
              response.data.UserProfileDetails.UserProfileUserDetails[
                i
              ].UserProfilePreferenceId.toUpperCase() ==
              UserPreferenceIDCompanyEmailRequested
            ) {
              setemailUnverified(
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceValue
              );
              setemailUnverifiedOld(
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceValue
              );
              setemailUnverifiedID(
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceId
              );
              setemailUnverifiedDisplayName(
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceDisplayName
              );
              // setEmailVerification(response.data.UserProfileDetails.UserProfileUserDetails[i].UserProfilePreferenceVerification);
              if (
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceVerification == "EXPIRED"
              ) {
                setEmailVerification(false);
              }
            }
          }
        } else {
          setValue("User Profile");
          setStep(0);

          if (response.data.UserProfileDetails) {
            setUsername(response.data.UserProfileDetails.LoginName);
            localStorage.setItem('USEA ID',response.data.UserProfileDetails.MemberId);

          }

          if (
            response.data.UserProfileCommunicationDetails &&
            response.data.UserProfileCommunicationDetails.length > 0
          ) {
            for (
              var i = 0;
              i < response.data.UserProfileCommunicationDetails.length;
              i++
            ) {
              if (
                response.data.UserProfileCommunicationDetails[
                  i
                ].UserProfilePreferenceId.toUpperCase() ==
                UserPreferenceIDAllowTexts
              ) {
                setPrimaryPhoneAllowText(
                  response.data.UserProfileCommunicationDetails[i]
                    .UserProfilePreferenceValue
                );
                setPrimaryPhoneAllowTextID(
                  response.data.UserProfileCommunicationDetails[i]
                    .UserProfilePreferenceId
                );
                setPrimaryPhoneAllowTextDisplayName(
                  response.data.UserProfileCommunicationDetails[i]
                    .UserProfilePreferenceDisplayName
                );
              }
            }
          }

          for (
            var i = 0;
            i < response.data.UserProfileDetails.UserProfileUserDetails.length;
            i++
          ) {
            if(response.data.UserProfileDetails.UserProfileUserDetails[i]
              .UserProfilePreferenceDisplayName != "Email Requested"){
            if (
              response.data.UserProfileDetails.UserProfileUserDetails[i]
                .UserProfilePreferenceValue == "" ||
              response.data.UserProfileDetails.UserProfileUserDetails[i]
                .UserProfilePreferenceValue == null
            ) {
              document.body.classList.add("newUser");
            }}
            if (
              response.data.UserProfileDetails.UserProfileUserDetails[
                i
              ].UserProfilePreferenceId.toUpperCase() ==
              UserPreferenceIDFirstName
            ) {
              setFirstname(
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceValue
              );

              setFirstnameID(
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceId
              );
              setFirstnameDisplayName(
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceDisplayName
              );
            } else if (
              response.data.UserProfileDetails.UserProfileUserDetails[
                i
              ].UserProfilePreferenceId.toUpperCase() ==
              UserPreferenceIDLastName
            ) {
              setLastname(
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceValue
              );

              setLastnameID(
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceId
              );
              setLastnameDisplayName(
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceDisplayName
              );
            } else if (
              response.data.UserProfileDetails.UserProfileUserDetails[
                i
              ].UserProfilePreferenceId.toUpperCase() ==
              UserPreferenceIDMailingAddress
            ) {
              setaddressPrefID(
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceId
              );
              setModelAddressDetails(
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceValue
              );
              setaddressPrefDisplayName(
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceDisplayName
              );
            } else if (
              response.data.UserProfileDetails.UserProfileUserDetails[
                i
              ].UserProfilePreferenceId.toUpperCase() == UserPreferenceIDDOB
            ) {
              let dob =  new Date(
                moment(
                  response.data.UserProfileDetails.UserProfileUserDetails[i]
                    .UserProfilePreferenceValue
                ).format("MM-DD-YYYY")
              );
              setdob(moment(
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceValue
              ).format("MM-DD-YYYY")
              );
              console.log(moment(
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceValue
              ).format("MM-DD-YYYY"));
              console.log(new Date(
                moment(String(response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceValue),'MM-DD-YYYY')
              ).toUpperCase);
              if (
                new Date(
                  response.data.UserProfileDetails.UserProfileUserDetails[
                    i
                  ].UserProfilePreferenceValue
                ) == "Invalid Date"
              ) {
                setuserDob();
                console.log(userDob)
              } else {
                setuserDob(
                  new Date(
                    response.data.UserProfileDetails.UserProfileUserDetails[
                      i
                    ].UserProfilePreferenceValue
                  )
                );
                // alert(response.data.UserProfileDetails.UserProfileUserDetails[i].UserProfilePreferenceValue);
                parentRequired(
                  response.data.UserProfileDetails.UserProfileUserDetails[i]
                    .UserProfilePreferenceValue
                );
              }
              if (
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceValue == "" ||
                response.data.UserProfileDetails.ActualAge >= 100 ||
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceValue == "Invalid date"
              ) {
                setDisableDob(false);
              }
              setuserDobID(
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceId
              );
              setuserDobDisplayName(
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceDisplayName
              );
            } else if (
              response.data.UserProfileDetails.UserProfileUserDetails[
                i
              ].UserProfilePreferenceId.toUpperCase() ==
              UserPreferenceIDPrimaryPhone
            ) {
              setPrimaryPhone(
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceValue
              );
              if (
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceValue == null ||
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceValue == " " ||
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceValue == "___-___-____"
              ) {
                setEditPhone(false);
                setEmpty(true);
              } else {
                setEditPhone(true);
              }
              setPrimaryPhoneID(
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceId
              );
              setPrimaryPhoneDisplayName(
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceDisplayName
              );
            } else if (
              response.data.UserProfileDetails.UserProfileUserDetails[
                i
              ].UserProfilePreferenceId.toUpperCase() ==
              UserPreferenceIDEmailVerified
            ) {
              setemailVerified(
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceValue
              );
              setemailVerifiedID(
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceId
              );
              setemailVerifiedDisplayName(
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceDisplayName
              );
            } else if (
              response.data.UserProfileDetails.UserProfileUserDetails[
                i
              ].UserProfilePreferenceId.toUpperCase() ==
              UserPreferenceIDEmailRequested
            ) {
              setemailUnverified(
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceValue
              );
              setemailUnverifiedOld(
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceValue
              );
              setemailUnverifiedID(
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceId
              );
              setemailUnverifiedDisplayName(
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceDisplayName
              );
              if (
                response.data.UserProfileDetails.UserProfileUserDetails[i]
                  .UserProfilePreferenceVerification == "EXPIRED"
              ) {
                setEmailVerification(true);
              }
            }
          }

          for (
            var j = 0;
            j < response.data.UserProfileGenderDetails.length;
            j++
          ) {
            setGenderPreferenceId(
              response.data.UserProfileGenderDetails[j].UserProfilePreferenceId
            );
            setGenderPreferenceDisplayName(
              response.data.UserProfileGenderDetails[j]
                .UserProfilePreferenceDisplayName
            );

            if (
              response.data.UserProfileGenderDetails[j]
                .UserProfilePreferenceValue == "" ||
              response.data.UserProfileGenderDetails[j]
                .UserProfilePreferenceValue == null
            ) {
              document.body.classList.add("newUser");
            }

            if (
              response.data.UserProfileGenderDetails[j]
                .UserProfilePreferenceSelected
            ) {
              setgenderValue(
                response.data.UserProfileGenderDetails[j]
                  .UserProfilePreferenceValue
              );
              setGenderPreferenceValue(
                response.data.UserProfileGenderDetails[j]
                  .UserProfilePreferenceValue
              );
              setGenderPreferenceId(
                response.data.UserProfileGenderDetails[j]
                  .UserProfilePreferenceId
              );
              setGenderPreferenceDisplayName(
                response.data.UserProfileGenderDetails[j]
                  .UserProfilePreferenceDisplayName
              );
              setGenderPreferenceOptionId(
                response.data.UserProfileGenderDetails[j]
                  .UserProfilePreferenceOptionId
              );
            }
          }

          if (response.data.UserProfileDetails != null) {
          }

          if (
            response.data.UserProfileDetails != null &&
            response.data.UserProfileDetails.ActualAge <= 22
          ) {
            sethide(true);
          }

          if (
            response.data.UserProfileParentGuardianDetails &&
            response.data.UserProfileParentGuardianDetails.length > 0
          ) {
            for (
              var k = 0;
              k < response.data.UserProfileParentGuardianDetails.length;
              k++
            ) {
              if (
                response.data.UserProfileParentGuardianDetails[k]
                  .UserProfilePreferenceValue == "" ||
                response.data.UserProfileParentGuardianDetails[k]
                  .UserProfilePreferenceValue == null
              ) {
                // document.body.classList.add("newUser");
              }
              if (
                response.data.UserProfileParentGuardianDetails[
                  k
                ].UserProfilePreferenceId.toUpperCase() ==
                UserPreferenceIDParentGuardianName
              ) {
                setUserProfileParentName(
                  response.data.UserProfileParentGuardianDetails[k]
                    .UserProfilePreferenceValue
                );
                setUserProfileParentNameOld(
                  response.data.UserProfileParentGuardianDetails[k]
                    .UserProfilePreferenceValue
                );
                setUserProfileParentNameID(
                  response.data.UserProfileParentGuardianDetails[k]
                    .UserProfilePreferenceId
                );
                setUserProfileParentNameDisplayName(
                  response.data.UserProfileParentGuardianDetails[k]
                    .UserProfilePreferenceDisplayName
                );
              } else if (
                response.data.UserProfileParentGuardianDetails[
                  k
                ].UserProfilePreferenceId.toUpperCase() ==
                UserPreferenceIDParentGuardianPhone
              ) {
                setUserProfileParentPhone(
                  response.data.UserProfileParentGuardianDetails[k]
                    .UserProfilePreferenceValue
                );
                setUserProfileParentPhoneOld(
                  response.data.UserProfileParentGuardianDetails[k]
                    .UserProfilePreferenceValue
                );
                setUserProfileParentPhoneID(
                  response.data.UserProfileParentGuardianDetails[k]
                    .UserProfilePreferenceId
                );
                setUserProfileParentPhoneDisplayName(
                  response.data.UserProfileParentGuardianDetails[k]
                    .UserProfilePreferenceDisplayName
                );
              } else if (
                response.data.UserProfileParentGuardianDetails[
                  k
                ].UserProfilePreferenceId.toUpperCase() ==
                UserPreferenceIDParentGuardianEmail
              ) {
                setUserProfileParentEmail(
                  response.data.UserProfileParentGuardianDetails[k]
                    .UserProfilePreferenceValue
                );
                setUserProfileParentEmailOld(
                  response.data.UserProfileParentGuardianDetails[k]
                    .UserProfilePreferenceValue
                );
                setUserProfileParentEmailID(
                  response.data.UserProfileParentGuardianDetails[k]
                    .UserProfilePreferenceId
                );
                setUserProfileParentEmailDisplayName(
                  response.data.UserProfileParentGuardianDetails[k]
                    .UserProfilePreferenceDisplayName
                );
              }
            }
          }
          if (
            response.data.UserProfileDetails != null &&
            response.data.UserProfileDetails.CompetitionAge <= 18
          ) {
            //  setrequiredField(true);
          }
          if (
            response.data.UserProfileDetails.UserProfileEmergencyDetails &&
            response.data.UserProfileDetails.UserProfileEmergencyDetails
              .length > 0
          ) {
            for (
              var p = 0;
              p <
              response.data.UserProfileDetails.UserProfileEmergencyDetails
                .length;
              p++
            ) {
              if (
                response.data.UserProfileDetails.UserProfileEmergencyDetails[p]
                  .UserProfilePreferenceValue == "" ||
                response.data.UserProfileDetails.UserProfileEmergencyDetails[p]
                  .UserProfilePreferenceValue == null
              ) {
                document.body.classList.add("newUser");
              }
              if (
                response.data.UserProfileDetails.UserProfileEmergencyDetails[
                  p
                ].UserProfilePreferenceId.toUpperCase() ==
                UserPreferenceIDEmergencyContactName
              ) {
                setUserProfileEmergencyDetailsName(
                  response.data.UserProfileDetails.UserProfileEmergencyDetails[
                    p
                  ].UserProfilePreferenceValue
                );
                setUserProfileEmergencyDetailsNameID(
                  response.data.UserProfileDetails.UserProfileEmergencyDetails[
                    p
                  ].UserProfilePreferenceId
                );
                setUserProfileEmergencyDetailsNameDisplayName(
                  response.data.UserProfileDetails.UserProfileEmergencyDetails[
                    p
                  ].UserProfilePreferenceDisplayName
                );
              }
              if (
                response.data.UserProfileDetails.UserProfileEmergencyDetails[
                  p
                ].UserProfilePreferenceId.toUpperCase() ==
                UserPreferenceIDEmergencyContactPhone
              ) {
                setUserProfileEmergencyDetailsPhone(
                  response.data.UserProfileDetails.UserProfileEmergencyDetails[
                    p
                  ].UserProfilePreferenceValue
                );
                setUserProfileEmergencyDetailsPhoneID(
                  response.data.UserProfileDetails.UserProfileEmergencyDetails[
                    p
                  ].UserProfilePreferenceId
                );
                setUserProfileEmergencyDetailsPhoneDisplayName(
                  response.data.UserProfileDetails.UserProfileEmergencyDetails[
                    p
                  ].UserProfilePreferenceDisplayName
                );
              }
              if (
                response.data.UserProfileDetails.UserProfileEmergencyDetails[
                  p
                ].UserProfilePreferenceId.toUpperCase() ==
                UserPreferenceIDEmergencyContactRelationship
              ) {
                setUserProfileEmergencyDetailsRelation(
                  response.data.UserProfileDetails.UserProfileEmergencyDetails[
                    p
                  ].UserProfilePreferenceValue
                );
                setUserProfileEmergencyDetailsRelationID(
                  response.data.UserProfileDetails.UserProfileEmergencyDetails[
                    p
                  ].UserProfilePreferenceId
                );
                setUserProfileEmergencyDetailsRelationDisplayName(
                  response.data.UserProfileDetails.UserProfileEmergencyDetails[
                    p
                  ].UserProfilePreferenceDisplayName
                );
              }
            }
          }
        }

        setUserProfileData(response.data.UserProfileData);
        setUserProfileDetails(response.data.UserProfileDetails);
        setUserPreference(response.data.UserProfileGenderDetails);
        setUserProfileType(response.data.UserProfileTypeDetails);
        if (response.data.UserProfileDetails != null) {
          setUserProfileEmergencyDetails(
            response.data.UserProfileDetails.UserProfileEmergencyDetails
          );
        }
        if (response.data.UserProfileDetails != null) {
          setBasicDetails(
            response.data.UserProfileDetails.UserProfileUserDetails
          );
        }
        setUserProfileCommunicationDetails(
          response.data.UserProfileCommunicationDetails
        );
        setUserSubscriptionDetails(
          response.data.UserProfileSubscriptionDetails
        );
        if (response.data.UserProfileSubscriptionDetails) {
          // for (j = 0; j < UserSubscriptionDetails.length; j++) {
          // }
        }
        if (
          firstName == "" ||
          lastName == "" ||
          userDob == "" ||
          userDob == null ||
          userProfileEmergencyDetailsName == "" ||
          userProfileEmergencyDetailsPhone == "" ||
          userProfileEmergencyDetailsRelation == ""
        ) {
          setEmpty(true);
        }
        if (response.data.UserProfileData.NewUser || empty) {
          console.log(empty);
          document.body.classList.add("newUser");
          return () => {
            document.body.classList.remove("newUser");
          };
        }

        if (userProfileData.NewUser) {
          // Add the 'newuser' class to the body element
          document.body.classList.add("newUser");
        }
      })
      .catch(function (error) {
        const data = {
          AppId: ConfigData.appId,
          TokenId: getTokenFromStorage(),
          UserId: localStorage.getItem("UserId"),
          DateTime: curDT,
          PageUrl: PageUrl.pathname,
          ExceptionDetails: error.stack,
        };

        console.log(error.toString());
        console.log(error.stack);
        console.log(error.stack.split("\n")[1].split(":")[2]);
        axios
          .post(urlAPI + "PostOSPortalLoggingExceptions", data)
          .then(function (response) {
            if (response.data.TokenInfoDetails.TokenResponse == "Expired") {
              getToken();
              axios.post(urlAPI + "PostOSPortalLoggingExceptions", data);
            }
          });
      });
  }, []);

  const parentRequired = (date) => {
    const date_chosen = new Date(date);
    const today = new Date();
    var distance = today.getTime() - date_chosen.getTime();
    var daysold = Math.floor(distance / (1000 * 60 * 60 * 24));
    var yearsold = Number((daysold / 365).toFixed(0));

    if (yearsold < 23) {
      setrequiredField(true);
      sethide(true);
      setUserProfileParentName(userProfileParentDetailsNameOld);
      setUserProfileParentPhone(userProfileParentDetailsPhoneOld);
      setUserProfileParentEmail(userProfileParentDetailsEmailOld);
    } else {
      setrequiredField(false);
      sethide(false);
      setUserProfileParentPhone("");
      setUserProfileParentName("");
      setUserProfileParentEmail("");
    }
  };
  const checkDisableCompanyProfile = () => {
    // console.log(prefDisable);
    // console.log(CompanyName);
    if (CompanyName == "") {
      setprefDisable(true);
    } else {
      setprefDisable(false);
    }
  };

  const checkDisable = () => {
    if (
      firstName == "" ||
      lastName == "" ||
      userDob == "" ||
      userDob == null ||
      userProfileEmergencyDetailsName == "" ||
      userProfileEmergencyDetailsPhone == "" ||
      userProfileEmergencyDetailsRelation == ""
    ) {
      setprefDisable(true);
    } else {
      setprefDisable(false);
    }
  };

  const checkDisableCommunication = () => {
    if (
      primaryPhone == "" ||
      UserProfileDetails.FormattedMailingAddress == ""
    ) {
      setprefDisable(true);
    } else {
      setprefDisable(false);
    }
  };
  const resetPasswordlink = () => {
    const email = emailVerfied;
    console.log(emailVerfied);
    let resetUrl = "";
    resetUrl =
      urlAPI +
      "GetOSPortalResetPassCode?AppId=" +
      appId +
      "&TokenId=" +
      localStorage.getItem("TokenId") +
      "&UserName=" +
      username +
      "&Email=" +
      email;
    axios
      .get(resetUrl)
      .then(function (response) {
        if (
          response.data.TokenInfoDetails.TokenResponse == "Expired" ||
          response.data.TokenInfoDetails.TokenResponse == "Invalid"
        ) {
          getToken();
          console.log("Token");
          setTimeout(() => {
            axios.get(resetUrl);
          }, 1000);
        }
        if (
          response.data.ResetPassCodeDetails.Message === "VALID" &&
          response.data.ResetPassCodeDetails.LongMessage === "VALID"
        ) {
          setMsg("Reset your password link send to your email");
        } else {
          setMsg(
            "We are sorry. This is an invalid email/username combination. Please try again and if you continue to have problems, please contact the USEA office at 703-779-0440 for assistance."
          );
        }
      })
      .catch(function (error) {
        const data = {
          AppId: ConfigData.appId,
          TokenId: getTokenFromStorage(),
          UserId: localStorage.getItem("UserId"),
          DateTime: curDT,
          PageUrl: PageUrl.pathname,
          ExceptionDetails: error.stack,
        };
        axios
          .post(urlAPI + "PostOSPortalLoggingExceptions", data)
          .then(function (response) {
            if (response.data.TokenInfoDetails.TokenResponse == "Expired") {
              getToken();
              axios.post(urlAPI + "PostOSPortalLoggingExceptions", data);
            }
          });
      });
  };
  function validatePhoneNumber(input_str) {
    var re = /^\(?(\d{3})\)?[- ]?(\d{3})[- ]?(\d{4})$/;
    return re.test(input_str);
  }
  const validateForm = (e) => {
    if (!validatePhoneNumber(e.target.value)) {
      setDisableUpdate(true);
      console.log("update", disableupdate);
    } else {
      setDisableUpdate(false);
      console.log("update", disableupdate);
    }
  };
  function ValidateEmail(input_str) {
    var re = /\S+@\S+\.\S+/;
    return re.test(input_str);
  }
  const validateEmailForm = (e) => {
    console.log(e.target.value);
    if (!ValidateEmail(e.target.value) || emailVerfied == e.target.value) {
      setErrorEmail("Enter valid email");
      setupdateEmailDisabled(true);
      console.log("update", disableupdate);
    } else {
      setupdateEmailDisabled(false);
      console.log("update", disableupdate);
      setErrorEmail("");
    }
  };
  const callUserProfilePost = async () => {
    let strMembershipSettingsData = "";
    let addressValue = "";
    let swap = "test";
    strMembershipSettingsData +=
      '{"UserID":"' +
      userProfileData.UserId +
      '","UserTypeID":"' +
      userProfileData.UserTypeId +
      '","PreferenceData":[';

    if (modelAddressDetails == "") addressValue = '""';
    else addressValue = modelAddressDetails;

    if (userProfileData.UserTypeId.toUpperCase() == UserTypeIDUser) {
      strMembershipSettingsData +=
        '{"UserProfilePreferenceID":"' +
        firstNameID +
        '","UserProfilePreferenceDisplayName":"' +
        firstNameDisplayName +
        '","UserProfilePreferenceValue":"' +
        firstName +
        '","UserProfilePreferenceOptionID": null},' +
        '{"UserProfilePreferenceID":"' +
        lastNameID +
        '","UserProfilePreferenceDisplayName":"' +
        lastNameDisplayName +
        '","UserProfilePreferenceValue":"' +
        lastName +
        '","UserProfilePreferenceOptionID": null},' +
        '{"UserProfilePreferenceID":"' +
        userDobID +
        '","UserProfilePreferenceDisplayName":"' +
        userDobDisplayName +
        '","UserProfilePreferenceValue":"' +
        moment(userDob).format("MM-DD-YYYY") +
        '","UserProfilePreferenceOptionID":null},' +
        '{"UserProfilePreferenceID":"' +
        addressPrefID +
        '","UserProfilePreferenceDisplayName":"' +
        addressPrefDisplayName +
        '","UserProfilePreferenceValue":' +
        addressValue +
        ',"UserProfilePreferenceOptionID":null},' +
        '{"UserProfilePreferenceID":"' +
        emailVerfiedID +
        '","UserProfilePreferenceDisplayName":"' +
        emailVerfiedDisplayName +
        '","UserProfilePreferenceValue":"' +
        emailVerfied +
        '","UserProfilePreferenceOptionID":null},' +
        '{"UserProfilePreferenceID":"' +
        emailUnverfiedID +
        '","UserProfilePreferenceDisplayName":"' +
        emailUnverfiedDisplayName +
        '","UserProfilePreferenceValue":"' +
        emailUnverfied +
        '","UserProfilePreferenceOptionID":null},' +
        '{"UserProfilePreferenceID":"' +
        primaryPhoneID +
        '","UserProfilePreferenceDisplayName":"' +
        primaryPhoneDisplayName +
        '","UserProfilePreferenceValue":"' +
        primaryPhone +
        '","UserProfilePreferenceOptionID":null},' +
        '{"UserProfilePreferenceID":"' +
        primaryPhoneAllowTextID +
        '","UserProfilePreferenceDisplayName":"' +
        primaryPhoneAllowTextDisplayName +
        '","UserProfilePreferenceValue":"' +
        primaryPhoneAllowText +
        '","UserProfilePreferenceOptionID":null},' +
        '{"UserProfilePreferenceID":"' +
        GenderPreferenceId +
        '","UserProfilePreferenceDisplayName":"' +
        GenderPreferenceDisplayName +
        '","UserProfilePreferenceValue":"' +
        GenderPreferenceValue +
        '","UserProfilePreferenceOptionID": "' +
        GenderPreferenceOptionId +
        '"},' +
        '{"UserProfilePreferenceID": "' +
        userProfileParentDetailsPhoneID +
        '","UserProfilePreferenceDisplayName": "' +
        userProfileParentDetailsPhoneDisplayName +
        '","UserProfilePreferenceValue": "' +
        userProfileParentDetailsPhone +
        '","UserProfilePreferenceOptionID": null},' +
        '{"UserProfilePreferenceID":"' +
        userProfileParentDetailsNameID +
        '","UserProfilePreferenceDisplayName":"' +
        userProfileParentDetailsNameDisplayName +
        '","UserProfilePreferenceValue":"' +
        userProfileParentDetailsName +
        '","UserProfilePreferenceOptionID": null},' +
        '{"UserProfilePreferenceID": "' +
        userProfileParentDetailsEmailID +
        '","UserProfilePreferenceDisplayName": "' +
        userProfileParentDetailsEmailDisplayName +
        '","UserProfilePreferenceValue": "' +
        userProfileParentDetailsEmail +
        '","UserProfilePreferenceOptionID": null},' +
        '{"UserProfilePreferenceID":"' +
        userProfileEmergencyDetailsNameID +
        '","UserProfilePreferenceDisplayName":"' +
        userProfileEmergencyDetailsNameDisplayName +
        '","UserProfilePreferenceValue":"' +
        userProfileEmergencyDetailsName +
        '","UserProfilePreferenceOptionID":null},' +
        '{"UserProfilePreferenceID":"' +
        userProfileEmergencyDetailsPhoneID +
        '","UserProfilePreferenceDisplayName":"' +
        userProfileEmergencyDetailsPhoneDisplayName +
        '","UserProfilePreferenceValue":"' +
        userProfileEmergencyDetailsPhone +
        '","UserProfilePreferenceOptionID":null},' +
        '{"UserProfilePreferenceID":"' +
        userProfileEmergencyDetailsRelationID +
        '","UserProfilePreferenceDisplayName":"' +
        userProfileEmergencyDetailsRelationDisplayName +
        '","UserProfilePreferenceValue":"' +
        userProfileEmergencyDetailsRelation +
        '","UserProfilePreferenceOptionID":null}';
    } else if (
      userProfileData.UserTypeId.toUpperCase() != UserTypeIDBusiness ||
      userProfileData.UserTypeId.toUpperCase() != UserTypeIDAffiliate
    ) {
      strMembershipSettingsData +=
        '{"UserProfilePreferenceID":"' +
        CompanyNameID +
        '","UserProfilePreferenceDisplayName": "' +
        CompanyNameDisplayName +
        '","UserProfilePreferenceValue":"' +
        CompanyName +
        '","UserProfilePreferenceOptionID":null},' +
        '{"UserProfilePreferenceID":"' +
        addressPrefID +
        '","UserProfilePreferenceDisplayName":"' +
        addressPrefDisplayName +
        '","UserProfilePreferenceValue":' +
        addressValue +
        ',"UserProfilePreferenceOptionID":null},' +
        '{"UserProfilePreferenceID":"' +
        emailVerfiedID +
        '","UserProfilePreferenceDisplayName":"' +
        emailVerfiedDisplayName +
        '","UserProfilePreferenceValue":"' +
        emailVerfied +
        '","UserProfilePreferenceOptionID":null},' +
        '{"UserProfilePreferenceID":"' +
        emailUnverfiedID +
        '","UserProfilePreferenceDisplayName":"' +
        emailUnverfiedDisplayName +
        '","UserProfilePreferenceValue":"' +
        emailUnverfied +
        '","UserProfilePreferenceOptionID":null},' +
        '{"UserProfilePreferenceID":"' +
        primaryPhoneID +
        '","UserProfilePreferenceDisplayName":"' +
        primaryPhoneDisplayName +
        '","UserProfilePreferenceValue":"' +
        primaryPhone +
        '","UserProfilePreferenceOptionID":null},' +
        '{"UserProfilePreferenceID":"' +
        primaryPhoneAllowTextID +
        '","UserProfilePreferenceDisplayName":"' +
        primaryPhoneAllowTextDisplayName +
        '","UserProfilePreferenceValue":"' +
        primaryPhoneAllowText +
        '","UserProfilePreferenceOptionID":null}';
    }

    if (UserSubscriptionDetails && UserSubscriptionDetails.length > 0) {
      for (var i = 0; i < UserSubscriptionDetails.length; i++) {
        strMembershipSettingsData +=
          ',{"UserProfilePreferenceID":"' +
          UserSubscriptionDetails[i].UserProfilePreferenceId +
          '","UserProfilePreferenceDisplayName":"' +
          UserSubscriptionDetails[i].UserProfilePreferenceDisplayName +
          '","UserProfilePreferenceValue":"' +
          UserSubscriptionDetails[i].UserProfilePreferenceValue +
          '","UserProfilePreferenceOptionID": null}';
      }
    }

    strMembershipSettingsData += "]}";
    console.log(strMembershipSettingsData);
    setobjstrMembershipSettingsData(strMembershipSettingsData);

    const data = {
      AppId: ConfigData.appId,
      TokenId: getTokenFromStorage(),
      UserProfileData: strMembershipSettingsData,
    };
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        AppId: ConfigData.appId,
        TokenId: getTokenFromStorage(),
        UserProfileData: strMembershipSettingsData,
      }),
    };

    let postMembershipSettingsURLAPI =
      urlAPI + "OSPortalUserProfileDetailsPost";
    const response = await fetch(postMembershipSettingsURLAPI, requestOptions);
    if (!response.ok) {
      throw new Error("Data Account Settings Post cound not be fetched!");
    } else {
      return response.json();
    }
    // axios.post(urlAPI + 'OSPortalUserProfileDetailsPost', data).then(function (response) {
    //   if (response.data.TokenInfoDetails.TokenResponse == 'Expired') {
    //     getToken();
    //     axios.post(urlAPI + 'OSPortalUserProfileDetailsPost', data)
    //   }
    //   console.log(response.data);
    //   return response.data;
    // }).catch(
    //   function (error) {

    //     const data = {
    //       AppId: ConfigData.appId,
    //       TokenId: getTokenFromStorage(),
    //       UserId: localStorage.getItem('UserId'),
    //       DateTime: curDT,
    //       PageUrl: PageUrl.pathname,
    //       ExceptionDetails: error.stack
    //     }

    //     axios.post(urlAPI + 'PostOSPortalLoggingExceptions', data).then(function (response) {
    //       setDisableSaveandNext(true);
    //       if (response.data.TokenInfoDetails.TokenResponse == 'Expired') {
    //         getToken();
    //         axios.post(urlAPI + 'PostOSPortalLoggingExceptions', data)
    //       }
    //     });
    //   }
    // );
  };

  const handleSubmit1 = () => {
    setDisableSaveandNext(true);
    setLoading(true);

    callUserProfilePost().then(function (response) {
      console.log(response);
      if (response.UserProfilePostData.Message == "SUCCESS") {
        setLoading(false);
        if (userProfileData.NewUser) {
          if (
            primaryPhone == "" ||
            userProfileData.FormattedMailingAddress == ""
          ) {
            if (
              primaryPhone == null ||
              primaryPhone == "" ||
              primaryPhone == "___-___-____"
            ) {
              setEditPhone(false);
              setDisableUpdate(true);
            }
            setLoading(false);

            setStep("Communication Preferences");
            setValue("Communication Preferences");
            setDisableSaveandNext(false);
            setLoading(false);

            setErrorMsg("");
          } else {
            console.log(serviceUrl);
            window.open(
              serviceUrl + "/Login.aspx?user=" + localStorage.getItem("UserId"),
              "_self"
            );
          }
        } else {
          if (
            primaryPhone == null ||
            primaryPhone == "" ||
            primaryPhone == "___-___-____"
          ) {
            setEditPhone(false);
            setDisableUpdate(true);
          }
          setStep("Communication Preferences");
          setValue("Communication Preferences");
          setDisableSaveandNext(false);
          setLoading(false);

          setErrorMsg("");
        }
      } else {
        setErrorMsg(response.UserProfilePostData.LongMessage);
      }
    });
  };

  // const [objUserProfile, setObjUserProfile] = useState('');
  const handleSubmit = () => {
    setDisableSaveandNext(true);
    setLoading(true);
    callUserProfilePost().then(function (response) {
      setDisableSaveandNext(true);
      if (response.UserProfilePostData.Message == "SUCCESS") {
        setLoading(false);
        
        if (userProfileData.NewUser) {
          // console.log(response.data);
          if (
            primaryPhone == "" ||
            userProfileData.FormattedMailingAddress == ""
          ) {
            if (
              primaryPhone == null ||
              primaryPhone == "" ||
              primaryPhone == "___-___-____"
            ) {
              setEditPhone(false);
              setDisableUpdate(true);
            }
            setStep("Communication Preferences");
            setValue("Communication Preferences");
            setDisableSaveandNext(false);
            setLoading(false);

            setErrorMsg("");
          } else {
            console.log(serviceUrl);
            window.open(
              serviceUrl + "/Login.aspx?user=" + localStorage.getItem("UserId"),
              "_self"
            );
          }
        } else {
          if (
            primaryPhone == null ||
            primaryPhone == "" ||
            primaryPhone == "___-___-____"
          ) {
            setEditPhone(false);
            setDisableUpdate(true);
          }
          setDisableSaveandNext(false);
          setStep("Communication Preferences");
          setValue("Communication Preferences");
          setErrorMsg("");
        }
      } else {
        setErrorMsg(response.UserProfilePostData.LongMessage);
      }
    });
  };

  // const handleSaveCommunication = () => {

  //   setDisableSaveandNext(true);
  //   setLoading(true);

  //   callUserProfilePost().then(function (response) {
  //     console.log("response");
  //     console.log(response);
  //     if (response.UserProfilePostData.Message == "SUCCESS") {
  //       window.open(
  //         serviceUrl + "/Login.aspx?user=" + localStorage.getItem("UserId"),
  //         "_self"
  //       );
  //     } else {
  //       setErrorMsg(response.UserProfilePostData.LongMessage);
  //     }
  //   });
  // };
  const [addressError, setAddressError] = useState(null);

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setAddressSelected(false);
    console.log(addressSelected);
    // Check if the input value is null or contains only spaces
    if (!inputValue || !inputValue.trimStart() ) {
      setAddressError("Please Enter valid mailing address.");
      
    } else {
      setAddressError(null); // Reset the error state if the input is valid
    }
  };

  const handleSaveCommunication = () => {
    const mailingAddress = UserProfileDetails.FormattedMailingAddress;
    console.log("add",mailingAddress);
    // if (!mailingAddress || !mailingAddress.trimStart() ) {
    //   setAddressError("Mailing address is empty or contains only spaces");
    //   return;
    // }
    if( !addressSelected){

      setAddressError("Please select from the postal-approved mailing addresses to continue.");
      // setAddressSelected()
    console.log('addressSelected1',addressSelected);

      return;
    }else{
      setAddressError(null);
    }

    // Check if the addressError state is set
    if (addressError) {
      return;
    }

    
    setDisableSaveandNext(true);
    setLoading(true);

    callUserProfilePost().then(function (response) {
      console.log("response");
      console.log(response);
      if (response.UserProfilePostData.Message == "SUCCESS") {
        setLoading(false);

        window.open(
          serviceUrl + "/Login.aspx?user=" + localStorage.getItem("UserId"),
          "_self"
        );
      } else {
        setErrorMsg(response.UserProfilePostData.LongMessage);
      }
    });
  };

  const clickForgot = () => {
    navigate("/forgot-password", {
      state: { step: 1, email: username },
    });
  };
  const userProfileMobile = (event) => {
    UserProfileDetails.Mobile = event.target.value;
  };

  const onchnageEmail = () => {
    setShowEdit(false);
    
    linkSent(false);
    linkInvalid(false);
    setShowEdit1(false);
  };

  const onEditEmail = () => {
    setEditEmail(true);
    setHideUnverified(true);

    //setemailUnverified(username);
    setupdateEmailDisabled(true);
    // setUpdateEmail(true);
    setisEmailInvalid(true);
  };

  const onUpdateEmail = () => {
    setEditEmail(false);
    setShowEdit(true);
    setShowEdit1(true);
    setHideUnverified(false);

    axios
      .get(
        urlAPI +
          "GetOSPortalUserEmailUpdateCheck?AppId=" +
          AppId +
          "&TokenId=" +
          TokenId +
          "&UserId=" +
          userId +
          "&EmailRequested=" +
          emailUnverfied
      )
      .then(function (response) {
        if (response.data.UserEmailUpdateCheckDetails.Message == "VALID") {
          setlinkSent(true);
          setemailUnverified(emailUnverfied);
          setErrorMsg(false);
        } else if (
          response.data.UserEmailUpdateCheckDetails.Message == "INVALID"
        ) {
          setlinkInvalid(true);
          setlinkSent(false);
          // setShowEdit(false);
          setemailUnverified(emailUnverfiedOld);
          setisEmailInvalid(false);
          setErrorMsg(response.data.UserEmailUpdateCheckDetails.LongMessage);
        }
      })
      .catch(function (error) {
        const data = {
          AppId: ConfigData.appId,
          TokenId: getTokenFromStorage(),
          UserId: localStorage.getItem("UserId"),
          DateTime: curDT,
          PageUrl: PageUrl.pathname,
          ExceptionDetails: error.stack,
        };
        console.log(error.stack);
        setlinkInvalid(true);
        setlinkSent(false);
        setShowEdit(false);
        setemailVerified(emailVerfied);
        setemailUnverified("");
        axios
          .post(urlAPI + "PostOSPortalLoggingExceptions", data)
          .then(function (response) {
            if (response.data.TokenInfoDetails.TokenResponse == "Expired") {
              getToken();
              axios.post(urlAPI + "PostOSPortalLoggingExceptions", data);
            }
          });
      });
  };

  const onEditPhone = () => {
    setEditPhone(false);
    setUpdatePhone(true);
  };

  const onupdatePhone = (e) => {
    console.log(primaryPhone);
    setEditPhone(true);
    setUpdatePhone(false);
    checkUpdateDisable();
  };
  const onResendEmail = () => {
    setEditEmail(false);
    axios
      .get(
        urlAPI +
          "GetOSPortalUserEmailUpdateCheck?AppId=" +
          AppId +
          "&TokenId=" +
          TokenId +
          "&UserId=" +
          userId +
          "&EmailRequested=" +
          emailUnverfied
      )
      .then(function (response) {
        if (response.data.UserEmailUpdateCheckDetails.Message == "VALID") {
          setlinkSent(true);
          setEmailVerification(false);
        }
      })
      .catch(function (error) {
        const data = {
          AppId: ConfigData.appId,
          TokenId: getTokenFromStorage(),
          UserId: localStorage.getItem("UserId"),
          DateTime: curDT,
          PageUrl: PageUrl.pathname,
          ExceptionDetails: error.stack,
        };
        console.log(error.stack);
        axios
          .post(urlAPI + "PostOSPortalLoggingExceptions", data)
          .then(function (response) {
            if (response.data.TokenInfoDetails.TokenResponse == "Expired") {
              getToken();
              axios.post(urlAPI + "PostOSPortalLoggingExceptions", data);
            }
          });
      });
  };

  const onEmailIdChange = (e) => {
    setnewEmail(e.target.value);
    //  if(UserProfileCommunicationDetails[2].UserProfileCommunicationDetailsSelectedValue == e.target.value){
    //   setupdateDisabled(true);
    // }
    // else{
    //   setupdateDisabled(false)
    // }
  };

  /*onchange events Start*/

  const onChangeFirstname = (e) => {
    //UserProfileDetails.FirstName = e.target.value;
    setFirstname(e.target.value.trimStart());
  };
  const onChangeLastname = (e) => {
    //UserProfileDetails.LastName = e.target.value;
    setLastname(e.target.value.trimStart());
  };

  const onSetDob = (event) => {
    // setdob(moment(new Date(event.target.value)).format('YYYY-MM-DD'));
    setuserDob(new Date(event.target.value));
    // UserProfileDetails.Dob = (moment(new Date(event.target.value)).format('YYYY-MM-DD'));
  };

  const handleGenderChange = (event) => {
    setgenderValue(event.target.value);
    console.log(genderValue);

    for (var j = 0; j < UserPreference.length; j++) {
      if (UserPreference[j].UserProfilePreferenceValue == event.target.value) {
        setGenderPreferenceOptionId(
          UserPreference[j].UserProfilePreferenceOptionId
        );
      }
    }
    setGenderPreferenceValue(event.target.value);
  };

  const onChangeTextMessages = () => {
    if (primaryPhoneAllowText.toLowerCase() == "true") {
      setPrimaryPhoneAllowText("false");
    } else {
      setPrimaryPhoneAllowText("true");
    }
  };

  const onChangeUserSubscriptionDetails = (i) => (e) => {
    let newArr = [...UserSubscriptionDetails];
    // e.target.value = e.target.value.toLowerCase();

    newArr[i].UserProfilePreferenceValue = e.target.value;

    setUserSubscriptionDetails(newArr);
    console.log(UserSubscriptionDetails);
  };

  let addressDetails = "";

  const inputRef = useRef();

  const handlePlaceChanged = () => {
    console.log("entered");
    const [place] = inputRef.current.getPlaces();
    const address = place.address_components;
    setAddressSelected(true); 
    setAddressError(null);

    if (place) {
      console.log(place.formatted_address);
      console.log(place.geometry.location.lat());
      console.log(place.geometry.location.lng());
      var add = [];
      addressDetails += '{"address_components" : ';
      for (var j = 0; j < address.length; j++) {
        var temp = new Object();
        temp["long_name"] = address[j].long_name;
        temp["short_name"] = address[j].short_name;
        temp["types"] = address[j].types;
        add.push(temp);
      }
      addressDetails += JSON.stringify(add);
      addressDetails += "}";
      console.log("add", addressDetails);
      setModelAddressDetails(addressDetails);
    }
    setModelAddressDetails(addressDetails);
  };

  const GoogleMapAPI = ConfigData.apikey;

  switch (step) {
    case "Communication Preferences":
      return (
        <Box sx={{ backgroundColor: "#F7F7F9", p: "25px 0" }}>
          {" "}
          <Box
            sx={{ maxWidth: "750px", margin: "0 auto" }}
            className="account-form"
          >
            <Card className="card_box">
              <CardHeader
                title="Account Settings"
                sx={{ textAlign: "center" }}
              />
              {errormsg.length > 0 && (
                <Box sx={{ m: "0 20px" }}>
                  <div className="error-msg">
                    <p>
                      <span className="k-icon k-i-warning k-i-exception k-i-error mr-10"></span>
                    </p>
                    <p>{errormsg}</p>
                  </div>
                </Box>
              )}
              <Typography
                variant="body2"
                sx={{ fontWeight: 400, m: "20px", mt: 0 }}
              >
                {UserProfileDetails.FullName} (USEA ID{" "}
                {UserProfileDetails.MemberId})
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontWeight: 500, m: "20px", mt: 0, color: "#4c4e64de" }}
              >
                PRIMARY ACCOUNT USER
              </Typography>
              <Form
                initialValues={{}}
                onSubmitClick={handleSaveCommunication}
                render={(formRenderProps) => (
                  <FormElement>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      fullWidth
                      sx={{ m: "20px", mt: 0 }}
                    >
                      <FormControl id="profile" fullWidth>
                        <DropDownList
                          data={optionsPref}
                          onFocus={checkDisable}
                          value={value}
                          onChange={handleChange}
                          disabled={userProfileData.NewUser || prefDisable}
                          id="form-layouts-tabs-select"
                          labelId="form-layouts-tabs-select-label"
                        ></DropDownList>
                      </FormControl>
                    </Grid>
                    <Divider sx={{ m: "0 !important" }} />
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: 500,
                        m: "20px 20px 0 20px !important",
                        color: "#4c4e64de",
                      }}
                    >
                      COMMUNICATION PREFERENCES
                    </Typography>
                    <CardContent>
                      <Grid container spacing={5}>
                        {/* <Grid item xs={12} sm={6} className="input_box">
                          <StandaloneSearchBox
                            onLoad={(ref) => (inputRef.current = ref)}
                            onPlacesChanged={handlePlaceChanged}
                          >
                            <input
                              fullWidth
                              type="text"
                              label="Mailing Address"
                              className="form-control"
                              required={true}
                              value={UserProfileDetails.FormattedMailingAddress}
                              placeholder="Enter Location"
                              validationMessage="Please enter valid Mailing address"
                            />
                          </StandaloneSearchBox>
                        </Grid> */}
                        <Grid item xs={12} sm={6} className="phone-input">
                          <label className="phone-label">
                            Mailing Address<span className="req_field">*</span>
                          </label>
                          <StandaloneSearchBox
                            onLoad={(ref) => (inputRef.current = ref)}
                            onPlacesChanged={handlePlaceChanged}
                          >
                            <input
                              type="text"
                              className="form-control"
                              required={true}
                              defaultValue={
                                UserProfileDetails.FormattedMailingAddress
                              }
                              placeholder="Enter Location"
                              validationMessage="Please enter valid Mailing address"
                              onChange={handleInputChange}
                            />
                          </StandaloneSearchBox>{" "}
                          {addressError && (
                            <div className="unverified">{addressError}</div>
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6} className="phone-input">
                          <label className="phone-label">
                            Primary Phone<span className="req_field">*</span>
                          </label>
                          <MaskedTextBox
                            mask="999-999-9999"
                            required={true}
                            id={"primary_phone"}
                            validationMessage="Please enter valid Phone Number"
                            value={primaryPhone}
                            onFocus={(e) => {
                              checkUpdateDisable();
                            }}
                            // disabled={editPhone}
                            onChange={(e) => {
                              setPrimaryPhone(e.target.value);
                              checkUpdateDisable();
                              validateForm(e);
                            }}
                          />
                          <Box>
                            <FormControlLabel
                              className="checkbox"
                              label="Receive text messages? Standard rates may apply."
                              control={
                                <Checkbox
                                  defaultChecked={
                                    primaryPhoneAllowText.toLowerCase() ==
                                    "true"
                                      ? true
                                      : false
                                  }
                                  onChange={onChangeTextMessages}
                                />
                              }
                            />
                          </Box>
                        </Grid>
                        {showEdit ? (
                          editEmail ? (
                            <></>
                          ) : (
                            <Grid item xs={12} sm={6} className="input_box">
                              <Box>
                                <Box sx={{ mb: 2 }}>
                                  <label>
                                    Email{" "}
                                    <span className="verified">
                                      &nbsp;(Verified)
                                    </span>
                                  </label>
                                </Box>
                                <TextField
                                  fullWidth
                                  name={"email"}
                                  type={"email"}
                                  label={"Email"}
                                  placeholder="Email"
                                  defaultValue={emailVerfied}
                                  disabled={!editEmail}
                                  onChange={(e) => {
                                    setemailUnverified(e.target.value);
                                    validateEmailForm(e);
                                  }}
                                />
                              </Box>
                              <Box sx={{ mt: 2 }}>
                                {editEmail ? (
                                  <Button
                                    fullWidth
                                    variant="contained"
                                    onClick={onUpdateEmail}
                                    disabled={updateEmailDisabled}
                                    className="email-btn"
                                  >
                                    Update
                                  </Button>
                                ) : (
                                  <Button
                                    fullWidth
                                    href="javascript:void(0)"
                                    onClick={() => {
                                      onEditEmail();
                                      onchnageEmail();
                                    }}
                                    component={Link}
                                    variant="contained"
                                    className="email-btn"
                                  >
                                    Edit
                                  </Button>
                                )}
                              </Box>
                            </Grid>
                          )
                        ) : (
                          <></>
                        )}
                        <Grid item xs={12} sm={6} className="input_box">
                          <Box>
                            {isEmailInvalid && (
                              <>
                                <Box sx={{ mb: 1 }}>
                                  {showEdit ? (
                                    <label>
                                      Email{" "}
                                      <span className="unverified">
                                        &nbsp;(Unverified)
                                      </span>
                                    </label>
                                  ) : (
                                    <label>
                                      Email{" "}
                                      <span className="verified">
                                        &nbsp;(Verified)
                                      </span>
                                    </label>
                                  )}
                                </Box>
                                <TextField
                                  fullWidth
                                  name={"email"}
                                  type={"email"}
                                  label={"Email"}
                                  placeholder="Email"
                                  defaultValue={emailVerfied}
                                  disabled={!editEmail}
                                  onChange={(e) => {
                                    setemailUnverified(e.target.value);
                                    validateEmailForm(e);
                                  }}
                                />
                              </>
                            )}
                          </Box>
                          <Box sx={{ mt: 2 }}>
                            {editEmail ? (
                              <Button
                                fullWidth
                                variant="contained"
                                onClick={onUpdateEmail}
                                disabled={updateEmailDisabled}
                                className="email-btn"
                              >
                                Update
                              </Button>
                            ) : showEdit ? (
                              <></>
                            ) : (
                              <Button
                                fullWidth
                                href="javascript:void(0)"
                                onClick={onEditEmail}
                                component={Link}
                                variant="contained"
                                className="email-btn"
                              >
                                Edit
                              </Button>
                            )}
                            <div className="email-msg">
                              <p className="error">{errorEmail}</p>
                              {linkSent ? (
                                showEdit ? (
                                  <p className="verified">
                                    Verify your email! - Check your inbox (or
                                    spam) for our email with a verification
                                    link. You MUST verify your email before
                                    logging in again.
                                  </p>
                                ) : (
                                  <></>
                                )
                              ) : linkInvalid ? (
                                showEdit1 ? (
                                  <p className="unverified">{errormsg}</p>
                                ) : (
                                  <></>
                                )
                              ) : (
                                <></>
                              )}
                            </div>
                          </Box>
                        </Grid>
                        {emailUnverfied ? (
                          <Grid item xs={12} sm={6} className="input_box">
                            <div
                              className={
                                showEdit
                                  ? "mb-3 div-flex hide"
                                  : "mb-3 div-flex"
                              }
                            >
                            <Box sx={{ mb: 1 }} className={hideUnverified ? "hide unverified_lable" : ""}>
                            
                                {" "}
                                <label>
                                  Email
                                  <span className="unverified">
                                    &nbsp;(Unverified)
                                  </span>
                                </label>{" "}
                              </Box>
                              <TextField
                              className={hideUnverified ? "hide unverified_lable" : ""}
                                fullWidth
                                name={"email"}
                                type={"email"}
                                label={"Email"}
                                placeholder="Email"
                                defaultValue={emailUnverfied}
                                disabled
                              />
                              {emailVerification ? (
                                <Box sx={{ mt: 2, width: "100%" }} className={hideUnverified ? "hide unverified_lable " : ""}>
                                  <Button
                                    fullWidth
                                    href="javascript:void(0)"
                                    onClick={(e) => {
                                      onResendEmail(e);
                                    }}
                                    component={Link}
                                    variant="contained"
                                    className="email-btn"
                                  >
                                    Resend
                                  </Button>
                                </Box>
                              ) : (
                                <></>
                              )}
                              {emailVerification ? (
                                <p className= {hideUnverified ? "hide unverified_lable unverified" : "unverified"}>
                                  The Email requested is Expired. Please RESEND
                                  it.
                                </p>
                              ) : (
                                <></>
                              )}
                              {emailVerification ? (
                                linkSent ? (
                                  <p className="verified">
                                    Verify your email! - Check your inbox (or
                                    spam) for our email with a verification
                                    link. You MUST verify your email before
                                    logging in again.
                                  </p>
                                ) : (
                                  <></>
                                )
                              ) : (
                                <></>
                              )}
                            </div>
                          </Grid>
                        ) : (
                          <></>
                        )}
                      </Grid>

                      <Divider
                        sx={{ m: "20px 0 0 !important", width: "110%" }}
                      />

                      <Grid item xs={12} sm={6}>
                        {UserSubscriptionDetails ? (
                          <div>
                            <Typography
                              variant="body2"
                              sx={{
                                fontWeight: 500,
                                m: "20px 20px 0 20px !important",
                                color: "#4c4e64de",
                                fontSize: "16px !important",
                              }}
                            >
                              Email Communications
                            </Typography>
                            <Typography
                              variant="body2"
                              sx={{
                                fontWeight: 400,
                                m: "10px 20px 0 20px !important",
                                color: "#4c4e64de",
                              }}
                            >
                              Subscribe to receive content
                            </Typography>

                            <div className="communications-checkbox">
                              {Object.keys(UserSubscriptionDetails).map(
                                (object, i) => (
                                  <Checkbox
                                    key={
                                      UserSubscriptionDetails[object]
                                        .UserProfilePreferenceId
                                    }
                                    label={
                                      UserSubscriptionDetails[object]
                                        .UserProfilePreferenceDisplayName
                                    }
                                    defaultChecked={
                                      UserSubscriptionDetails[i]
                                        .UserProfilePreferenceValue ===
                                        "true" ||
                                      UserSubscriptionDetails[i]
                                        .UserProfilePreferenceValue === "True"
                                    }
                                    onChange={onChangeUserSubscriptionDetails(
                                      i
                                    )}
                                  />
                                )
                              )}
                            </div>
                          </div>
                        ) : null}
                        <p className="notice">
                          <sup>*</sup>Notifications for transactions within the
                          USEA online services will be issued regardless of
                          above status.
                        </p>
                      </Grid>
                    </CardContent>

                    <Divider sx={{ m: "0 !important" }} />
                    <CardActions sx={{ justifyContent: "center", p: "20px 0" }}>
                      <Button
                        variant="contained"
                        className="btn"
                        disabled={disableSaveandNext}
                        size="large"
                        type="submit"
                        sx={{ backgroundColor: "#000" }}
                      >
                        {loading && <CircularProgress size={20} />}
                        {!loading && "Save"}
                      </Button>
                    </CardActions>
                  </FormElement>
                )}
              />
            </Card>
          </Box>
        </Box>
      );
    case "Affiliate Profile":
      return (
        <Box sx={{ backgroundColor: "#F7F7F9", p: "25px 0" }}>
          {" "}
          <Box
            sx={{ maxWidth: "750px", margin: "0 auto" }}
            className="account-form"
          >
            <Card className="card_box">
              <CardHeader
                title="Account Settings"
                sx={{ textAlign: "center" }}
              />
              <Box>
                {errormsg.length > 0 && (
                  <div className="error-msg">
                    <p>
                      <span className="k-icon k-i-warning k-i-exception k-i-error mr-10"></span>
                    </p>
                    <p>{errormsg}</p>
                  </div>
                )}
              </Box>
              <Typography
                variant="body2"
                sx={{ fontWeight: 400, m: "20px", mt: 0 }}
              >
                {UserProfileDetails.FullName} (USEA ID{" "}
                {UserProfileDetails.MemberId})
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontWeight: 500, m: "20px", mt: 0, color: "#4c4e64de" }}
              >
                PRIMARY ACCOUNT USER
              </Typography>
              <Form
                initialValues={{}}
                onSubmitClick={handleSubmit}
                render={(formRenderProps) => (
                  <FormElement>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      fullWidth
                      sx={{ m: "20px", mt: 0 }}
                    >
                      <FormControl id="profile" fullWidth>
                        <DropDownList
                          data={optionsPref}
                          onFocus={checkDisableCompanyProfile}
                          value={value}
                          onChange={handleChange}
                          disabled={userProfileData.NewUser || prefDisable}
                          defaultValue="Profile"
                          id="form-layouts-tabs-select"
                          labelId="form-layouts-tabs-select-label"
                        ></DropDownList>
                      </FormControl>
                    </Grid>
                    <Divider sx={{ m: "0 !important" }} />
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: 500,
                        m: "20px 20px 0 20px !important",
                        color: "#4c4e64de",
                      }}
                    >
                      Affiliate Profile
                    </Typography>
                    <CardContent>
                      <Grid container spacing={5}>
                        <Grid item xs={12} sm={6} sx={{ pt: "0 !important" }}>
                          <FormLabel>Username</FormLabel>
                          <p className="ml-2 text-spacing"> {username}</p>
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ pt: "0 !important" }}>
                          <FormLabel>Change Password</FormLabel>
                          {msg.length == 0 && (
                            <p className="ml-2 text-spacing">
                              To reset your password, click on the{" "}
                              <a
                                href="javascript:void(0)"
                                onClick={resetPasswordlink}
                              >
                                Reset Your Password link
                              </a>
                            </p>
                          )}
                          {msg.length > 0 && <p className="ml-3">{msg}</p>}
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider sx={{ m: "0 !important" }} />
                    <CardContent>
                      <Grid container spacing={5}>
                        <Grid item xs={12} sm={6} className="input_box">
                          <TextField
                            fullWidth
                            name={"Business/Affiliate Name"}
                            type={"text"}
                            required={true}
                            label={"Business/Affiliate Name"}
                            placeholder="Business/Affiliate Name"
                            value={CompanyName}
                            onChange={(e) => {
                              setLastname(e.target.value);
                              checkDisableCompanyProfile();
                            }}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider sx={{ m: "0 !important" }} />
                    <CardActions sx={{ justifyContent: "center", p: "20px 0" }}>
                      <Button
                        variant="contained"
                        className="btn"
                        disabled={disableSaveandNext}
                        size="large"
                        type="submit"
                        sx={{ backgroundColor: "#000" }}
                      >
                        {loading && <CircularProgress size={20} />}
                        {!loading && "Save Next"}
                      </Button>
                    </CardActions>
                  </FormElement>
                )}
              />
            </Card>
          </Box>
        </Box>
      );

    case "Business Profile":
      return (
        <Box sx={{ backgroundColor: "#F7F7F9", p: "25px 0" }}>
          {" "}
          <Box
            sx={{ maxWidth: "750px", margin: "0 auto" }}
            className="account-form"
          >
            <Card className="card_box">
              <CardHeader
                title="Account Settings"
                sx={{ textAlign: "center" }}
              />
              <Box>
                {errormsg.length > 0 && (
                  <div className="error-msg">
                    <p>
                      <span className="k-icon k-i-warning k-i-exception k-i-error mr-10"></span>
                    </p>
                    <p>{errormsg}</p>
                  </div>
                )}
              </Box>
              <Typography
                variant="body2"
                sx={{ fontWeight: 400, m: "20px", mt: 0 }}
              >
                {UserProfileDetails.FullName} (USEA ID{" "}
                {UserProfileDetails.MemberId})
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontWeight: 500, m: "20px", mt: 0, color: "#4c4e64de" }}
              >
                PRIMARY ACCOUNT USER
              </Typography>
              <Form
                initialValues={{}}
                onSubmitClick={handleSubmit}
                render={(formRenderProps) => (
                  <FormElement>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      fullWidth
                      sx={{ m: "20px", mt: 0 }}
                    >
                      <FormControl id="profile" fullWidth>
                        <DropDownList
                          data={optionsPref}
                          onFocus={checkDisableCompanyProfile}
                          value={value}
                          onChange={handleChange}
                          disabled={userProfileData.NewUser || prefDisable}
                          defaultValue="Profile"
                          id="form-layouts-tabs-select"
                          labelId="form-layouts-tabs-select-label"
                        ></DropDownList>
                      </FormControl>
                    </Grid>
                    <Divider sx={{ m: "0 !important" }} />
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: 500,
                        m: "20px 20px 0 20px !important",
                        color: "#4c4e64de",
                      }}
                    >
                      Business Profile
                    </Typography>
                    <CardContent>
                      <Grid container spacing={5}>
                        <Grid item xs={12} sm={6} sx={{ pt: "0 !important" }}>
                          <FormLabel>Username</FormLabel>
                          <p className="ml-2 text-spacing"> {username}</p>
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ pt: "0 !important" }}>
                          <FormLabel>Change Password</FormLabel>
                          {msg.length == 0 && (
                            <p className="ml-2 text-spacing">
                              To reset your password, click on the{" "}
                              <a
                                href="javascript:void(0)"
                                onClick={resetPasswordlink}
                              >
                                Reset Your Password link
                              </a>
                            </p>
                          )}
                          {msg.length > 0 && <p className="ml-3">{msg}</p>}
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider sx={{ m: "0 !important" }} />
                    <CardContent>
                      <Grid container spacing={5}>
                        <Grid item xs={12} sm={6} className="input_box">
                          <TextField
                            fullWidth
                            name={"Business/Affiliate Name"}
                            type={"text"}
                            required={true}
                            label={"Business/Affiliate Name"}
                            placeholder="Business/Affiliate Name"
                            value={CompanyName}
                            onChange={(e) => {
                              setComapanyName(e.target.value);
                              checkDisableCompanyProfile();
                            }}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider sx={{ m: "0 !important" }} />
                    <CardActions sx={{ justifyContent: "center", p: "20px 0" }}>
                      <Button
                        variant="contained"
                        className="btn"
                        disabled={disableSaveandNext}
                        size="large"
                        type="submit"
                        sx={{ backgroundColor: "#000" }}
                      >
                        {loading && <CircularProgress size={20} />}
                        {!loading && "Save Next"}
                      </Button>
                    </CardActions>
                  </FormElement>
                )}
              />
            </Card>
          </Box>
        </Box>
      );
    default:
      return (
        <Box sx={{ backgroundColor: "#F7F7F9", p: "25px 0" }}>
          {" "}
          <Box
            sx={{ maxWidth: "750px", margin: "0 auto" }}
            className="account-form"
          >
            <Card className="card_box">
              <CardHeader
                title="Account Settings"
                sx={{ textAlign: "center" }}
              />
              <Box>
                {errormsg.length > 0 && (
                  <div className="error-msg">
                    <p>
                      <span className="k-icon k-i-warning k-i-exception k-i-error mr-10"></span>
                    </p>
                    <p>{errormsg}</p>
                  </div>
                )}
              </Box>
              <Typography
                variant="body2"
                sx={{ fontWeight: 400, m: "20px", mt: 0 }}
              >
                {UserProfileDetails.FullName} (USEA ID{" "}
                {UserProfileDetails.MemberId})
              </Typography>
              <Typography
                variant="body2"
                sx={{ fontWeight: 500, m: "20px", mt: 0, color: "#4c4e64de" }}
              >
                PRIMARY ACCOUNT USER
              </Typography>
              <Form
                initialValues={{}}
                onSubmitClick={handleSubmit1}
                render={(formRenderProps) => (
                  <FormElement>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      fullWidth
                      sx={{ m: "20px", mt: 0 }}
                    >
                      <FormControl id="profile" fullWidth>
                        {/* <InputLabel id='form-layouts-tabs-select-label'>User</InputLabel> */}
                        <DropDownList
                          data={optionsPref}
                          onFocus={checkDisable}
                          value={value}
                          onChange={handleChange}
                          disabled={userProfileData.NewUser || prefDisable}
                          defaultValue="Profile"
                          id="form-layouts-tabs-select"
                          labelId="form-layouts-tabs-select-label"
                        ></DropDownList>
                      </FormControl>
                    </Grid>
                    <Divider sx={{ m: "0 !important" }} />
                    <Typography
                      variant="body2"
                      sx={{
                        fontWeight: 500,
                        m: "20px 20px 0 20px !important",
                        color: "#4c4e64de",
                      }}
                    >
                      USER PROFILE
                    </Typography>
                    <CardContent>
                      <Grid container spacing={5}>
                        <Grid item xs={12}>
                          <Typography variant="body2" sx={{ fontWeight: 600 }}>
                            1. Account Details
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ pt: "0 !important" }}>
                          <FormLabel>Username</FormLabel>
                          <p className="ml-2 text-spacing"> {username}</p>
                        </Grid>
                        <Grid item xs={12} sm={6} sx={{ pt: "0 !important" }}>
                          <FormLabel>Change Password</FormLabel>
                          {msg.length === 0 && (
                            <p className="ml-2 text-spacing">
                              To reset your password, click on the{" "}
                              <a
                                href="javascript:void(0)"
                                onClick={resetPasswordlink}
                              >
                                Reset Your Password link
                              </a>
                            </p>
                          )}
                          {msg.length > 0 && <p className="ml-3">{msg}</p>}
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider sx={{ m: "0 !important" }} />
                    <CardContent>
                      <Grid container spacing={5}>
                        <Grid item xs={12}>
                          <Typography variant="body2" sx={{ fontWeight: 600 }}>
                            2. Personal Info
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} className="input_box">
                          <TextField
                            fullWidth
                            required
                            name={"firstName"}
                            type={"text"}
                            label={"First Name"}
                            placeholder="First Name"
                            value={firstName}
                            // required={true}
                            onChange={(e) => {
                              onChangeFirstname(e);
                              checkDisable();
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} className="input_box">
                          <TextField
                            fullWidth
                            name={"lastName"}
                            required={true}
                            type={"text"}
                            label={"Last Name"}
                            placeholder="Last Name"
                            value={lastName}
                            onChange={(e) => {
                              onChangeLastname(e);
                              checkDisable();
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <label>
                            Date of Birth<span className="req_field">*</span>
                          </label>
                          <DatePicker
                            label="Date of Birth"
                            onChange={(e) => {
                              setuserDob(e);
                              checkDisable();
                              parentRequired(e);
                            }}
                            required={true}
                            value={userDob}
                            format="MM-dd-yyyy"
                            dayPlaceholder="XX"
                            monthPlaceholder="XX"
                            yearPlaceholder="XXXX"
                            disabled={isdisableDob}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <FormControl className="gender-dropdown" fullWidth>
                            <DropDownList
                              data={gender}
                              value={genderValue}
                              onChange={handleGenderChange}
                              label={[
                                "How do you describe yourself?",
                                <span className="req_field">*</span>,
                              ]}
                              required={true}
                            />
                          </FormControl>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider sx={{ m: "0 !important" }} />
                    <CardContent>
                      <Grid container spacing={5}>
                        <Grid item xs={12}>
                          <Typography variant="body2" sx={{ fontWeight: 600 }}>
                            3. Emergency Contact
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} className="input_box">
                          <TextField
                            fullWidth
                            required
                            name={"Name"}
                            type={"text"}
                            label={"Name"}
                            value={userProfileEmergencyDetailsName}
                            placeholder="Name"
                            onChange={(e) => {
                              setUserProfileEmergencyDetailsName(
                                e.target.value.trimStart()
                              );
                              checkDisable();
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} className="phone-input">
                          <label className="phone-label">
                            Primary Phone<span className="req_field">*</span>
                          </label>
                          <MaskedTextBox
                            label="Primary Phone"
                            mask="000-000-0000"
                            required={true}
                            validationMessage="Please enter valid Phone Number"
                            value={userProfileEmergencyDetailsPhone}
                            onChange={(e) => {
                              setUserProfileEmergencyDetailsPhone(
                                e.target.value
                              );
                              checkDisable();
                            }}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} className="input_box">
                          <TextField
                            fullWidth
                            required
                            name={"Relationship"}
                            type={"text"}
                            label={"Relationship"}
                            value={userProfileEmergencyDetailsRelation}
                            placeholder="Relationship"
                            onChange={(e) => {
                              setUserProfileEmergencyDetailsRelation(
                                e.target.value.trimStart()
                              );
                              checkDisable();
                            }}
                          />
                        </Grid>
                      </Grid>
                    </CardContent>

                    <CardContent>
                      <div className={hide ? "mb-3" : " mb-3 hide"}>
                        <Divider sx={{ m: "0 !important", width: "110%" }} />
                        <Grid container spacing={5}>
                          <Grid item xs={12}>
                            <Typography
                              variant="body2"
                              sx={{ fontWeight: 600 }}
                            >
                              4. Parent/Guardian
                            </Typography>
                          </Grid>
                          <Grid item xs={12} sm={6} className="phone-input">
                            <label className="phone-label">
                              Name
                              {requiredField ? (
                                <span className="req_field">*</span>
                              ) : (
                                <></>
                              )}
                            </label>
                            <TextField
                              fullWidth
                              name={"Name"}
                              type={"text"}
                              // label={"Name"}
                              placeholder="Name"
                              value={userProfileParentDetailsName}
                              required={requiredField}
                              onChange={(e) => {
                                setUserProfileParentName(
                                  e.target.value.trimStart()
                                );
                                checkDisable();
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} className="phone-input">
                            <label className="phone-label">
                              Phone
                              {requiredField ? (
                                <span className="req_field">*</span>
                              ) : (
                                <></>
                              )}
                            </label>
                            <MaskedTextBox
                              label="Phone"
                              mask="000-000-0000"
                              required={requiredField}
                              validationMessage="Please enter valid Phone Number"
                              value={userProfileParentDetailsPhone}
                              onChange={(e) => {
                                setUserProfileParentPhone(e.target.value);
                                checkDisable();
                              }}
                            />
                          </Grid>
                          <Grid item xs={12} sm={6} className="phone-input">
                            <label className="phone-label">
                              Email
                              {requiredField ? (
                                <span className="req_field">*</span>
                              ) : (
                                <></>
                              )}
                            </label>
                            <TextField
                              fullWidth
                              name={"Email"}
                              type={"email"}
                              // label={"Email"}
                              placeholder="Email"
                              required={requiredField}
                              value={userProfileParentDetailsEmail}
                              onChange={(e) => {
                                setUserProfileParentEmail(
                                  e.target.value.trimStart()
                                );
                                checkDisable();
                              }}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </CardContent>
                    <Divider sx={{ m: "0 !important" }} />
                    <CardActions sx={{ justifyContent: "center", p: "20px 0" }}>
                      <Button
                        variant="contained"
                        className="btn"
                        disabled={disableSaveandNext}
                        size="large"
                        type="submit"
                        sx={{ backgroundColor: "#000" }}
                      >
                        {loading && <CircularProgress size={20} />}
                        {!loading && "Save Next"}
                      </Button>
                    </CardActions>
                  </FormElement>
                )}
              />
            </Card>
          </Box>
        </Box>
      );
  }
}
export default AccountSettings;
