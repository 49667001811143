import React, { useEffect, useRef, useState } from "react";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import { Input, Checkbox } from "@progress/kendo-react-inputs";
import { useLocation } from "react-router-dom";
import ConfigData from "../../ConfigurationData/ConfigData.json";
import axios from "axios";
import moment from "moment";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import { styled } from "@mui/material/styles";
import MuiCard from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import FormControl from "@mui/material/FormControl";

import {
  getToken,
  get,
  getLoginEmail,
  getLoginWithPassword,
  getUserId,
  getTokenFromStorage,
} from "../../api/RestServices";

function AccountVerification() {
  // const navigate = useNavigate();
  let urlAPI = ConfigData.apiURL;
  const [step, setStep] = useState(0);
  const [msg, setMsg] = useState("");
  let appId = ConfigData.appId;
  const queryParams = new URLSearchParams(window.location.search);
  let hashCode = queryParams.get("Hashcode");
  const PageUrl = useLocation();
  let serviceUrl = ConfigData.serviceUrl;
  let curDT = moment().format("MM/DD/YYYY hh:mm:ssA");

  const Card = styled(MuiCard)(({ theme }) => ({
    [theme.breakpoints.up("sm")]: { width: 450 },
  }));
  useEffect(() => {
    axios
      .get(
        urlAPI +
          "GetOSPortalUserActivationVerification?AppId=" +
          appId +
          "&TokenId=" +
          localStorage.getItem("TokenId") +
          "&HashCodeToVerify=" +
          hashCode
      )
      .then(function (response) {
        if (
          response.data.TokenInfoDetails.TokenResponse == "Expired" ||
          response.data.TokenInfoDetails.TokenResponse == "Invalid"
        ) {
          getToken();

          setTimeout(() => {
            axios.get(
              urlAPI +
                "GetOSPortalUserActivationVerification?AppId=" +
                appId +
                "&TokenId=" +
                localStorage.getItem("TokenId") +
                "&HashCodeToVerify=" +
                hashCode
            );
          }, 1000);
        }
        if (
          response.data.UserActivationLinkVerificationDetails.Message ==
            "VALID" &&
          response.data.UserActivationLinkVerificationDetails.LongMessage ==
            "VALID"
        ) {
          setStep(1);
          setMsg(
            "You account has been verified. Please click the link <a href='/login'>USEA Online service login</a> to login to your USEA account."
          );
        } else {
          setStep(2);
          setMsg(
            "We're sorry, but the link you've followed is invalid or has expired. Please try generating a new Verification link by visiting our Create New User Page. If you continue to experience issues, please contact the USEA office at 703-779-0440 for assistance "
          );
        }
      })
      .catch(function (error) {
        const data = {
          AppId: ConfigData.appId,
          TokenId: getTokenFromStorage(),
          UserId: localStorage.getItem("UserId"),
          DateTime: curDT,
          PageUrl: PageUrl.pathname,
          ExceptionDetails: error.stack,
        };
        axios
          .post(urlAPI + "PostOSPortalLoggingExceptions", data)
          .then(function (response) {
            if (response.data.TokenInfoDetails.TokenResponse == "Expired") {
              getToken();
              axios.post(urlAPI + "PostOSPortalLoggingExceptions", data);
            }
          });
      });
  }, []);

  switch (step) {
    case 2:
      return (
        <Box className="content-center">
          <Card className="card_box">
            <CardContent sx={{ p: "30px !important" }}>
              <Box sx={{ textAlign: "center" }}>
                <a href={serviceUrl + "/Dashboard.aspx"}>
                  <img
                    alt=""
                    src="../../assets/images/member_service_portal.png"
                    className="logo-img"
                  />
                </a>
              </Box>
              <Typography
                variant="h5"
                sx={{
                  mb: 3,
                  fontWeight: 600,
                  letterSpacing: "0.18px",
                  fontSize: "22px !important",
                  color: "#003156",
                  textAlign: "center",
                }}
              >
                Account Verification USEA Online
              </Typography>
              <Box className="">
                {msg.length > 0 && (
                  <div className="forgot-msg">
                    <p>
                      <span className="k-icon k-i-check k-i-checkmark"></span>
                    </p>
                    <p>{msg}</p>
                  </div>
                )}
              </Box>
              <p className="formbottom-link">
                Go back to <a href="/login">Change Login Email</a>
              </p>
            </CardContent>
          </Card>
        </Box>
      );
    case 1:
      return (
        <Box className="content-center">
          <Card className="card_box">
            <CardContent sx={{ p: "30px !important" }}>
              <Box sx={{ textAlign: "center" }}>
                <a href={serviceUrl + "/Dashboard.aspx"}>
                  <img
                    alt=""
                    src="../../assets/images/member_service_portal.png"
                    className="logo-img"
                  />
                </a>
              </Box>
              <Typography
                variant="h5"
                sx={{
                  mb: 3,
                  fontWeight: 600,
                  letterSpacing: "0.18px",
                  fontSize: "22px !important",
                  color: "#003156",
                  textAlign: "center",
                }}
              >
                Account Verification USEA Online
              </Typography>
              <Box className="">
                {msg.length > 0 && (
                  <div className="forgot-msg forgot-msg-error">
                    <p>
                      <span className="k-icon k-i-warning k-i-exception k-i-error mr-10"></span>
                    </p>
                    {/* <p>{msg}</p> */}
                    <p dangerouslySetInnerHTML={{ __html: msg }} />
                  </div>
                )}
              </Box>
              <p className="formbottom-link">
                Go to <a href="/login">Create New User Page</a>
              </p>
            </CardContent>
          </Card>
        </Box>
      );
  }
}
export default AccountVerification;
