import React from "react";
import { useState, useEffect, useCallback } from "react";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import ConfigData from "../../ConfigurationData/ConfigData.json";
import { Error } from "@progress/kendo-react-labels";
import {
  Input,
  Checkbox,
  InputSuffix,
  TextBox,
} from "@progress/kendo-react-inputs";
import {
  getToken,
  get,
  getLoginEmail,
  getLoginWithPassword,
  getUserId,
  getTokenFromStorage,
} from "../../api/RestServices";
import axios from "axios";
import moment from "moment";
import { useLocation } from "react-router-dom";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import { styled } from "@mui/material/styles";
import MuiCard from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import IconButton from '@mui/material/IconButton';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import InputAdornment from '@mui/material/InputAdornment';


const Card = styled(MuiCard)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: { width: 450 },
}));
let serviceUrl = ConfigData.serviceUrl;

//const pwdRegex = new RegExp(/^[A-Za-z\d@$!#%*?&]{10,}$/);
const pwdRegex = new RegExp(/^[ A-Za-z0-9@/%^$`{}[]|\:;<>?=#+]{10,}$/);
const conpwdRegex = new RegExp(/^[ A-Za-z0-9@/%^$`{}[]|\:;<>?=#+]{10,}$/);
// const userRegex = new RegExp(/^(?:\d{5,7}|\w+@\w+\.\w{2,3})$/);

const pwdValidator = (value) =>
  pwdRegex.test(value) ? "" : "Password must be at least 10 characters. The first character must be a letter or number.";

const confirmpwdValidator = (value) =>
  conpwdRegex.test(value) ? "" : "Password must be at least 10 characters. The first character must be a letter or number.";

// const userValidator = (value) =>
//   userRegex.test(value) ? "" : "Please enter a valid User Code or Email";

const passwordInput = (fieldRenderProps) => {
  const { validationMessage, visited, ...others } = fieldRenderProps;
  return (
    <div>
      <TextField {...others} />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};

const confirmpasswordInput = (fieldRenderProps) => {
  const { validationMessage, visited, ...others } = fieldRenderProps;
  return (
    <div>
      <TextField {...others} />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};

const UserCodeInput = (fieldRenderProps) => {
  const { validationMessage, visited, ...others } = fieldRenderProps;
  return (
    <div>
      <TextField {...others} />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};
function ResetPassword() {
  const [password, setPassword] = useState("");
  const [userCode, setUserCode] = useState("");
  const [msg, setMsg] = useState("");
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);

  const [confirmPassword, setConfirmPassword] = React.useState("");
  let urlAPI = ConfigData.apiURL;
  const [step, setStep] = useState(0);
  const queryParams = new URLSearchParams(window.location.search);
  let hashCode = queryParams.get("HashCodeToVerify");
  let username = queryParams.get("UserName");
  const PageUrl = useLocation();
  let curDT = moment().format("MM/DD/YYYY hh:mm:ssA");

  useEffect(() => {
    if (username) {
      setShow(true);
    } else {
      setShow(false);
    }
    getToken();

    setTimeout(() => { }, 1000);
  }, []);

  const renewToken = async () => {
    getToken();
    handleChangePassword();
  };

  const handleChangePassword = async () => {
    const data = {
      AppId: ConfigData.appId,
      TokenId: getTokenFromStorage(),
      HashCodeToVerify: hashCode,
      NewPassword: encodeURIComponent(password),
      UserName: username,
    };
    console.log(password);
    console.log(confirmPassword);
    if (password == confirmPassword) {
      axios
        .post(urlAPI + "PostOSPortalResetPassCodeVerify", data)
        .then(function (response) {
          if (
            response.data.TokenInfoDetails.TokenResponse == "Expired" ||
            response.data.TokenInfoDetails.TokenResponse == "Invalid"
          ) {
            getToken();

            renewToken();
            setTimeout(() => {
              axios.post(urlAPI + "PostOSPortalResetPassCodeVerify", data);
            }, 1000);
          }
          if (
            response.data.ExistingUserActivationLinkVerificationDetails
              .Message == "VALID" &&
            response.data.ExistingUserActivationLinkVerificationDetails
              .LongMessage == "VALID"
          ) {
            setStep(1);
            setMsg(
              "Your Password is changed. Please click the link to login to your USEA Account."
            );
          } else {
            setStep(2);
            setMsg(
              "We're sorry, but the link you've followed is invalid or has expired. Please try forgot Password link again. If you continue to experience issues, please contact the USEA office at 703-779-0440 for assistance."
            );
          }
        })
        .catch(function (error) {
          const data = {
            AppId: ConfigData.appId,
            TokenId: getTokenFromStorage(),
            UserId: localStorage.getItem("UserId"),
            DateTime: curDT,
            PageUrl: PageUrl.pathname,
            ExceptionDetails: error.stack,
          };
          axios
            .post(urlAPI + "PostOSPortalLoggingExceptions", data)
            .then(function (response) {
              if (response.data.TokenInfoDetails.TokenResponse == "Expired") {
                getToken();
                axios.post(urlAPI + "PostOSPortalLoggingExceptions", data);
              }
            });
        });
    } else {
      setError("Password not matched");
      console.log("Password not matched");
    }
  };

  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };


  switch (step) {
    case 1:
      return (
        <Box className="content-center">
          <Card sx={{ zIndex: 1 }}>
            <CardContent sx={{ p: "30px !important" }}>
              <Box sx={{ textAlign: "center" }}>
                <a href={serviceUrl + "/Dashboard.aspx"}>
                  <img
                    alt=""
                    src="../../assets/images/member_service_portal.png"
                    className="logo-img"
                  />
                </a>
              </Box>
              <Typography
                variant="h5"
                sx={{
                  mb: 3,
                  fontWeight: 600,
                  letterSpacing: "0.18px",
                  fontSize: "22px !important",
                  color: "#003156",
                  textAlign: "center",
                }}
              >
                Account Verification USEA Online
              </Typography>
              <Box className="">
                {msg.length > 0 && (
                  <div className="forgot-msg">
                    <p>
                      <span className="k-icon k-i-check k-i-checkmark"></span>
                    </p>
                    <p>{msg}</p>
                  </div>
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  mt: 2,
                }}
              >
                <p className="formbottom-link">
                  Go back to <a href="/login">Change Login Email</a>
                </p>
              </Box>
            </CardContent>
          </Card>
        </Box>
      );
    case 2:
      return (
        <Box className="content-center">
          <Card sx={{ zIndex: 1 }}>
            <CardContent sx={{ p: "30px !important" }}>
              <Box sx={{ textAlign: "center" }}>
                <a href={serviceUrl + "/Dashboard.aspx"}>
                  <img
                    alt=""
                    src="../../assets/images/member_service_portal.png"
                    className="logo-img"
                  />
                </a>
              </Box>
              <Typography
                variant="h5"
                sx={{
                  mb: 3,
                  fontWeight: 600,
                  letterSpacing: "0.18px",
                  fontSize: "22px !important",
                  color: "#003156",
                  textAlign: "center",
                }}
              >
                Account Verification USEA Online
              </Typography>
              <Box className="">
                {msg.length > 0 && (
                  <div className="forgot-msg forgot-msg-error">
                    <p>
                      <span className="k-icon k-i-warning k-i-exception k-i-error mr-10"></span>
                    </p>
                    <p>{msg}</p>
                  </div>
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  mt: 2,
                }}
              >
                <p className="formbottom-link">
                  Go back to <a href="/login">Change Login Email</a>
                </p>
              </Box>
            </CardContent>
          </Card>
        </Box>
      );
    default:
      return (
        <Box className="content-center">
          <Card className="card_box">
            <CardContent sx={{ p: "30px !important" }}>
              <Box sx={{ textAlign: "center" }}>
                <a href={serviceUrl + "/Dashboard.aspx"}>
                  <img
                    alt=""
                    src="../../assets/images/member_service_portal.png"
                    className="logo-img"
                  />
                </a>
              </Box>
              <Box sx={{ mb: 2, p: 0 }}>
                <Typography
                  variant="h5"
                  sx={{
                    mb: 3,
                    fontWeight: 600,
                    letterSpacing: "0.18px",
                    fontSize: "24px !important",
                    color: "#003156",
                    textAlign: "center",
                  }}
                >
                  Update Your Password
                </Typography>
              </Box>
              <Form
                initialValues={{
                  username: username,
                }}
                onSubmit={handleChangePassword}
                render={(formRenderProps) => (
                  <FormElement>
                    <Box className={show ? "mb-3" : "mb-3 hide"}>
                      <Field
                        fullWidth
                        name={"username"}
                        type={"text"}
                        placeholder="username"
                        component={UserCodeInput}
                        label={"username"}
                        value={{ username }}
                        disabled
                        className="input_box"
                      />
                    </Box>
                    <Box sx={{ mt: 2 }} className="input_box">
                      <FormControl fullWidth>
                        <Field
                          fullWidth
                          name={"password"}
                          type={passwordVisible ? "text" : "password"}
                          placeholder="Password"
                          component={passwordInput}
                          label={"Password"}
                          validator={pwdValidator}
                          onChange={(e) => setPassword(e.target.value)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={togglePasswordVisibility}
                                >
                                  {passwordVisible ? (
                                    <VisibilityOffIcon />
                                  ) : (
                                    <VisibilityIcon />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </FormControl>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                      <FormControl fullWidth>
                        <Field
                          fullWidth
                          name={"confirmPassword"}
                          type={passwordVisible ? "text" : "password"}
                          placeholder="Re-type Password"
                          component={confirmpasswordInput}
                          label="Re-type Password"
                          onChange={(e) => setConfirmPassword(e.target.value)}
                          validator={confirmpwdValidator}
                          className="input_box"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={togglePasswordVisibility}
                                >
                                  {passwordVisible ? (
                                    <VisibilityOffIcon />
                                  ) : (
                                    <VisibilityIcon />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                        {error.length > 0 && (
                          <div className="pwd-error">
                            <p>{error}</p>
                          </div>
                        )}
                      </FormControl>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                      <Button
                        className="btn"
                        fullWidth
                        size="large"
                        type={"submit"}
                        variant="contained"
                      // disabled={!formRenderProps.allowSubmit}
                      >
                        Update your Password
                      </Button>
                    </Box>
                  </FormElement>
                )}
              />
            </CardContent>
          </Card>
        </Box>
      );
  }
}

export default ResetPassword;
