import React, { useEffect, useState } from "react";
// ** MUI Imports
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CardHeader from "@mui/material/CardHeader";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import CardActions from "@mui/material/CardActions";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Form, Field, FormElement, Text } from "@progress/kendo-react-form";
import { Checkbox } from "@progress/kendo-react-inputs";
import { styled } from "@mui/material/styles";
import MuiFormControlLabel from "@mui/material/FormControlLabel";
import { useNavigate } from "react-router-dom";
import classes from "../MembershipSettings/UpdateCreditCardOnFile.module.css";
import { MaskedTextBox } from "@progress/kendo-react-inputs";
import { useHistory } from "react-router-dom";

import ConfigData from "../../ConfigurationData/ConfigData.json";
import {
  getToken,
  get,
  getLoginEmail,
  getLoginWithPassword,
  getHorseMemberIdFromUrl,
  getUserId,
  getTokenFromStorage,
} from "../../api/RestServices";
import axios from "axios";
import moment from "moment";
import { useLocation } from "react-router-dom";

let urlAPI = ConfigData.apiURL;
const AppId = ConfigData.appId;
const TokenId = getTokenFromStorage();
const horseMemberId = getHorseMemberIdFromUrl();
let userId = localStorage.getItem("UserId");
const InitiatedUserId = localStorage.getItem("UserId");
let serviceUrl = ConfigData.serviceUrl;
let LoggedInUserId = localStorage.getItem("UserId");

const renewToken = async () => {
  getToken();
};
const FormControlLabel = styled(MuiFormControlLabel)(({ theme }) => ({
  "& .MuiFormControlLabel-label": {
    fontSize: "0.875rem",
    color: theme.palette.text.secondary,
  },
}));

function HorsesTransfer(props) {
  const [isDisabled, setIsDisabled] = useState(false);
  const [disableSaveandNext, setDisableSaveandNext] = useState(false);
  const [errormsg, setErrorMsg] = useState("");
  const [isNewManager, setNewManager] = useState(true);
  const [isCancelApprove, setCancelApprove] = useState();

  const [
    HorseTransferInitiationHorseDetailsData,
    setHorseTransferInitiationHorseDetailsData,
  ] = useState("");
  const [
    HorseTransferInitiationHorseDetails,
    setHorseTransferInitiationHorseDetails,
  ] = useState("");

  const [PendingRecordManagerMemberId, setPendingRecordManagerMemberId] =
    useState("");
  const [PendingRecordManagerUserId, setPendingRecordManagerUserId] =
    useState("");
  const [PendingRecordManagerName, setPendingRecordManagerName] = useState("");
  const [PendingRecordManagerEmail, setPendingRecordManagerEmail] =
    useState("");

  const [CurrentHorseManagerName, setCurrentHorseManagerName] = useState("");
  const [CurrentHorseManagerMemberId, setCurrentHorseManagerMemberId] =
    useState("");

  const [HorseMember, setHorseMember] = useState("");
  const [loading, setLoading] = useState(false);
  const [cancelLoading, setCancelLoading] = useState(false);
  const [HorseMemberId, setHorseMemberId] = useState("");



  const [cardName, setCardName] = useState("");
  const [cardNumber, setCardNumber] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [cvv, setCvv] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [isTransferApproved, setIsTransferApproved] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [errorMessage1, setErrorMessage1] = useState("");
  const [isCheckedPay, setisCheckedPay] = useState(false);
  const [errorMessage2, setErrorMessage2] = useState("");
  const [step, setStep] = React.useState();

  const [isTransferApprovedchecked, setisTransferApprovedchecked] =
    useState(false);
  const [HorseTransferStatus, setHorseTransferStatus] = useState("");
  const [HorseTransferStatus3, setHorseTransferStatus3] = useState("");

  const [HorseInitiationUserType, setHorseInitiationUserType] = useState("");

  const [transferStatus, setTransferStatus] = useState("");
  const [statusMessage, setStatusMessage] = useState("");
  const [horseTransferId, setHorseTransferId] = useState(null);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const horseMemberId = queryParams.get("HorseMemberId");
  // const { TransferID } = location.state;
  useEffect(() => {
    if (horseMemberId) {
      const ListDetailsAPI = axios.get(
        `${urlAPI}GETOSPortalHorseTransferStatusListDetails?AppId=${AppId}&TokenId=${TokenId}&LoggedInUserId=${LoggedInUserId}`
      );
      const InitiationDetailsAPI = axios.get(
        `${urlAPI}GetOSPortalHorseTransferInitiationDetails?AppId=${AppId}&TokenId=${TokenId}&HorseMemberId=${horseMemberId}&InitiatedUserId=${InitiatedUserId}`
      );

      // Execute both API calls concurrently
      Promise.all([ListDetailsAPI, InitiationDetailsAPI])
        .then(function (responses) {
          const response1 = responses[0];
          const response2 = responses[1];

          console.log(response1.data);
          console.log(response2.data);

          if (response1.data.TokenInfoDetails.TokenResponse === "Expired") {
            renewToken();
            getToken();
            const ListDetailsAPI = axios.get(
              `${urlAPI}GETOSPortalHorseTransferStatusListDetails?AppId=${AppId}&TokenId=${TokenId}&LoggedInUserId=${LoggedInUserId}`
            );
          }
          if (response2.data.TokenInfoDetails.TokenResponse === "Expired") {
            renewToken();
            getToken();
            const InitiationDetailsAPI = axios.get(
              `${urlAPI}GetOSPortalHorseTransferInitiationDetails?AppId=${AppId}&TokenId=${TokenId}&HorseMemberId=${horseMemberId}&InitiatedUserId=${InitiatedUserId}`
            );
          }
         
          // Set the HorseTransferId state
          console.log(response1);
          if(response1.data.HorseTransferStatusListDetails){
            if(location.state){
               setHorseTransferId(
              location.state.TransferID
            );
            }
          }
          //   setHorseTransferId(
          //     location.state.TransferID
          //   );
        //   if(response1.data.HorseTransferStatusListDetails){
        //   for (let i=0;i<response1.data.HorseTransferStatusListDetails.length;i++){
        //     if(response1.data.HorseTransferStatusListDetails[i].HorseMemberId == horseMemberId ){
        //       setTransferStatus(response1.data.HorseTransferStatusListDetails[i].HorseTransferId);
        //     }
        //   }
        // }
          // }
            setHorseMemberId(
            response2.data.HorseTransferInitiationHorseDetails.HorseMemberId
          );

          // Set the values based on the response data for API 1
          setPendingRecordManagerMemberId(
            response2.data.HorseTransferPendingRecordManager
              .PendingRecordManagerMemberId
          );
          setPendingRecordManagerUserId(
            response2.data.HorseTransferPendingRecordManager
              .PendingRecordManagerUserId
          );
          setPendingRecordManagerName(
            response2.data.HorseTransferPendingRecordManager
              .PendingRecordManagerName
          );
          setPendingRecordManagerEmail(
            response2.data.HorseTransferPendingRecordManager
              .PendingRecordManagerEmail
          );

          setCurrentHorseManagerName(
            response2.data.HorseTransferCurrentHorseManager
              .CurrentHorseManagerName
          );

          setCurrentHorseManagerMemberId(
            response2.data.HorseTransferCurrentHorseManager
              .CurrentHorseManagerMemberId
          );

          setHorseTransferInitiationHorseDetails(
            response2.data.HorseTransferInitiationHorseDetails
          );
          setHorseTransferStatus(
            response1.data.HorseTransferStatusListDetails.HorseTransferStatus
          );
          setHorseTransferStatus3(
            response2.data.HorseTransferInitiationDetails.HorseTransferStatus
          );
          console.log(HorseTransferStatus3);

          setHorseInitiationUserType(
            response2.data.HorseTransferInitiationDetails
              .HorseInitiationUserType
          );

          // const HorseTransferStatus =
          //   response1.data.HorseTransferStatusListDetails[0]
          //     .HorseTransferStatus;
          let HorseTransferStatus='';
          for(let i=0; i< response1.data.HorseTransferStatusListDetails.length;i++){
            if(horseMemberId === response1.data.HorseTransferStatusListDetails[i].HorseMemberId){
            HorseTransferStatus = response1.data.HorseTransferStatusListDetails[i].HorseTransferStatus;
            }
          }
          const HorseTransferStatus2 =
            response2.data.HorseTransferInitiationDetails.HorseTransferStatus;

          const HorseInitiationUserType =
            response2.data.HorseTransferInitiationDetails
              .HorseInitiationUserType;

          // console.log(HorseTransferStatus);
          console.log(HorseTransferStatus2);
          // console.log(HorseInitiationUserType);
            console.log(HorseTransferStatus);
            console.log(HorseInitiationUserType);
          //  
           if (
            HorseInitiationUserType === "NewManager" &&
            HorseTransferStatus2 === "RELEASE FOR TRANSFER."
          ) {
            setStep(3);
          }
          if (
            HorseTransferStatus === "Approved - Awaiting Payment" &&
            HorseInitiationUserType === "NewManager" &&
            HorseTransferStatus2 === "Pending Transfer"
          ) {
            setStep(2);
            
          }
          if (
            HorseTransferStatus === "Approved - Awaiting Payment" &&
            HorseInitiationUserType === "NewManager" &&
            HorseTransferStatus2 === "Initiated"
          ) {
            setStep(1);
          
          }
          if (
            HorseTransferStatus === "Initiated" &&
            HorseInitiationUserType === "NewManager" &&
            HorseTransferStatus2 === "Pending Transfer"
          ) {
            setStep(1);
          }
          
          if (
            HorseTransferStatus === "Cancelled" &&
            HorseInitiationUserType === "NewManager" &&
            HorseTransferStatus2 === "Pending Transfer"
          ) {
            setStep(2);
          }
          if (
            HorseTransferStatus === "Cancelled" &&
            HorseInitiationUserType === "NewManager" &&
            HorseTransferStatus2 === "Initiated"
          ) {
            setStep(2);
          }
          if (
            HorseTransferStatus === "Paid" &&
            HorseInitiationUserType === "NewManager" &&
            HorseTransferStatus2 === "RELEASE FOR TRANSFER."
          ) {
            setStep(2);
          }

          if (
            HorseTransferStatus === "Completed" &&
            HorseInitiationUserType === "NewManager" &&
            HorseTransferStatus2 === "RELEASE FOR TRANSFER."
          ) {
            setStep(2);
          }
           if (
            HorseTransferStatus === "Cancelled" &&
            HorseInitiationUserType === "NewManager" &&
            HorseTransferStatus2 === "RELEASE FOR TRANSFER."
          ) {
            setStep(2);
          }
          if (
            // HorseTransferStatus === "Completed" &&
            HorseInitiationUserType === "CurrentManager" &&
            HorseTransferStatus2 === "RELEASE FOR TRANSFER."
          ) {
            setStep(3);
          }

          const currentStatus =
            response1.data.HorseTransferStatusListDetails.HorseTransferStatus;

          switch (HorseTransferStatus) {
            case "Pending":
              setStatusMessage(
                "The transfer is awaiting approval by the current manager."
              );
              break;
            case "Paid":
              setStatusMessage(
                "The transfer is paid but not completed by the current manager."
              );
              break;
            case "Completed":
              setStatusMessage(
                "The transfer is completed by the current manager."
              );
              break;
            case "Cancelled":
              setStatusMessage(
                "The transfer is cancelled by the current manager."
              );
              break;
            default:
              setStatusMessage(
                "The transfer is awaiting approval by the current manager"
              );
              break;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  }, [horseMemberId]);

  const handleCheckboxChange1 = (event) => {
    setisTransferApprovedchecked(event.target.checked);
    setErrorMessage("");
  };

  const handleapprovetransfer = () => {
    if (isTransferApprovedchecked) {
      alert('hi');
      setIsTransferApproved(true);
    } else {
      // Checkbox is not checked, show an error message or prevent form submission
      setErrorMessage1(
        "Unless the approve checkbox is clicked you cannot approve transfer"
      );
    }
  };

  const handleCheckboxChange = (event) => {
    setIsChecked(event.target.checked);
    setErrorMessage("");
  };

  const navigate = useNavigate();

  const handleCancelAPI = async () => {
    console.log(isChecked);
    if (isChecked) {
      setCancelLoading(true);
      try {
        console.log("horse ta",horseTransferId);
        const response = await axios.post(
          "https://s01.useadevonlineservices.net/USEAOSPortal/OSPortalHorseTransferRequestStatusPost",
          {

            AppId: ConfigData.appId,
            TokenId: getTokenFromStorage(),
            UserId: localStorage.getItem("UserId"),
            NewStatus: 5,
            HorseTransferId: horseTransferId,

          }
        );

        if (response.data.TokenInfoDetails.TokenVerified === "True" && response.data.HorseTransferRequestStatusPostDetails.Message == 'SUCESS') {
          // Handle success here
          console.log("Transfer request cancelled successfully");
          navigate("/horse-table");
        } else {
          // Handle token verification failure or other errors
          console.error(
            "Error cancelling transfer request:",
            response.data.TokenInfoDetails.TokenResponse
          );
        }
      } catch (error) {
        // Handle network or other errors
        console.error("Error cancelling transfer request:", error);
      } finally {
        setCancelLoading(false);
      }
      console.log("Transfer canceled.");
    } else {
      // Checkbox is not checked, show an error message or prevent form submission
      setErrorMessage(
        "Unless the cancel checkbox is clicked you cannot cancel transfer."
      );
    }
  };

  let urlAPI = ConfigData.apiURL;
  let appId = ConfigData.appId;
  const PageUrl = useLocation();
  let curDT = moment().format("MM/DD/YYYY hh:mm:ssA");
  const [UserID, setUserID] = useState(props.UserID);
  // const [MemberUSEAID, setMemberUSEAID] = useState(
  //   props.objModelMembershipDetails.MembershipDataDetails.MemberID
  // );
  const [form, setForm] = useState({
    dataCardType: "",
    dataCardNo: "",
    dataCardUserName: "",
    dataCardExpirationMonth: "",
    dataCardExpirationYear: "",
    dataCardSecurityCode: "",
    dataCardZipcode: "",
    dataCardEmail: "",
  });

  const [dataCardTypeErrorMessage, setDataCardTypeErrorMessage] = useState({});
  const [dataCardUserNameErrorMessage, setDataCardUserNameErrorMessage] =
    useState({});
  const [dataCardNoErrorMessage, setDataCardNoErrorMessage] = useState({});
  const [
    dataCardExpirationMonthErrorMessage,
    setDataCardExpirationMonthErrorMessage,
  ] = useState({});
  const [
    dataCardExpirationYearErrorMessage,
    setDataCardExpirationYearErrorMessage,
  ] = useState({});
  const [
    dataCardSecurityCodeErrorMessage,
    setDataCardSecurityCodeErrorMessage,
  ] = useState({});
  const [dataCardZipcodeErrorMessage, setDataCardZipcodeErrorMessage] =
    useState({});
  const [dataCardEmailErrorMessage, setDataCardEmailErrorMessage] = useState(
    {}
  );

  const [isPaymentSuccess, setIsPaymentSuccess] = useState();
  const [
    dataPaymentFailureShortErrorMessage,
    setDataPaymentFailureShortErrorMessage,
  ] = useState({});
  const [
    dataPaymentFailureLongErrorMessage,
    setDataPaymentFailureLongErrorMessage,
  ] = useState({});

  const onUpdateField = (e) => {
    const nextFormState = {
      ...form,
      [e.target.name]: e.target.value,
    };
    setForm(nextFormState);
  };

  const onSubmitForm = (e) => {
    e.preventDefault();

    const isValid = formValidation();
    console.log(isValid);
    if (isValid) {
      console.log(JSON.stringify(form, null, 2));
      console.log(form.dataCardEmail);
      callPayment()
        .then((response) => {
          console.log("Call Payment done");
          console.log(response);
          if (response) {
            if (
              response.PaymentTransactionStatus.PaymentStatus === 1 &&
              response.PaymentTransactionStatus.PaymentStatusResultCode === "A"
            ) {
              if (response.PaymentTransactionStatus.TransactionStatus === 1) {
                // set the credit card type
                // let strCreditCardType = "";
                // strCreditCardType = form.dataCardType;
                // console.log(form.dataCardType);
                // switch (form.dataCardType) {
                //   case "0":
                //     strCreditCardType = "VISA";
                //     break;
                //   case "1":
                //     strCreditCardType = "MASTERCARD";
                //     break;
                //   case "2":
                //     strCreditCardType = "AMEX";
                //     break;
                //   default:
                //     strCreditCardType = "";
                //     break;
                // }
                // console.log(strCreditCardType);
                // // extract the last 4 digits of the credit card no
                // let strCreditCardNo = "";
                // strCreditCardNo = form.dataCardNo.substring(
                //   form.dataCardNo.length - 4
                // );
                // strCreditCardNo = "#" + strCreditCardNo;
                // // props.objModelMembershipDetails.CreditCardDetails.CreditCardType =
                // //   strCreditCardType;
                // // props.objModelMembershipDetails.CreditCardDetails.CreditCardNo =
                // //   strCreditCardNo;
                // // props.objModelMembershipDetails.CreditCardDetails.CreditCardExpirationMonth =
                // //   form.dataCardExpirationMonth;
                // // props.objModelMembershipDetails.CreditCardDetails.CreditCardExpirationYear =
                // //   form.dataCardExpirationYear;

                // console.log(props.objModelMembershipDetails);
                setIsPaymentSuccess(true);
                if (isCheckedPay) {
                  e.preventDefault();
                  console.log("handleTransferAPI called");
                  const response1 =  axios.post(
                    "https://s01.useadevonlineservices.net/USEAOSPortal/OSPortalHorseTransferRequestStatusPost",
                    {
          
                      AppId: ConfigData.appId,
                      TokenId: getTokenFromStorage(),
                      UserId: localStorage.getItem("UserId"),
                      NewStatus: 3,
                      HorseTransferId: horseTransferId,
          
                    }
                  );
          
                  if (response1.data.TokenInfoDetails.TokenVerified === "True" && response1.data.HorseTransferRequestStatusPostDetails.Message == 'SUCESS') {
                    // Handle success here
                    navigate("/horse-table");
                  } else {
                    // Handle token verification failure or other errors
                    console.error(
                      "Error cancelling transfer request:",
                      response1.data.TokenInfoDetails.TokenResponse
                    );
                  }
                  
              
                } else {
                  setErrorMessage2(
                    "Unless the approve checkbox is clicked you cannot transfer & pay"
                  );
                }
                
                // props.updateIsPaymentDone(true);
              } else {
                setIsPaymentSuccess(false);
                callPaymentErrorDetails().then((response) => {
                  console.log("Call Payment error details done");
                  console.log(response);
                  if (response) {
                    const strPaymentFailureShortMessage = {};
                    const strPaymentFailureLongMessage = {};
                    if (
                      response.PaymentErrorDetails.ShortMessage !== null &&
                      response.PaymentErrorDetails.ShortMessage !== ""
                    ) {
                      strPaymentFailureShortMessage.ErrorMessage =
                        response.PaymentErrorDetails.ShortMessage;
                    }
                    if (
                      response.PaymentErrorDetails.LongMessage !== null &&
                      response.PaymentErrorDetails.LongMessage == ""
                    ) {
                      strPaymentFailureLongMessage.ErrorMessage =
                        response.PaymentErrorDetails.LongMessage;
                    }
                    setDataPaymentFailureShortErrorMessage(
                      strPaymentFailureShortMessage
                    );
                    setDataPaymentFailureLongErrorMessage(
                      strPaymentFailureLongMessage
                    );
                  }
                });
              }
            } else {
              setIsPaymentSuccess(false);
              let strPaymentStatusErrorCode =
                response.PaymentTransactionStatus.PaymentStatusErrorCode;
              callPaymentErrorDetails(strPaymentStatusErrorCode).then(
                (response) => {
                  console.log("Call Payment error details done");
                  console.log(response);
                  if (response) {
                    const strPaymentFailureShortMessage = {};
                    const strPaymentFailureLongMessage = {};
                    if (
                      response.PaymentErrorDetails.ShortMessage !== null &&
                      response.PaymentErrorDetails.ShortMessage !== ""
                    ) {
                      strPaymentFailureShortMessage.ErrorMessage =
                        response.PaymentErrorDetails.ShortMessage;
                    }
                    if (
                      response.PaymentErrorDetails.LongMessage !== null &&
                      response.PaymentErrorDetails.LongMessage !== ""
                    ) {
                      strPaymentFailureLongMessage.ErrorMessage =
                        response.PaymentErrorDetails.LongMessage;
                    }
                    setDataPaymentFailureShortErrorMessage(
                      strPaymentFailureShortMessage
                    );
                    setDataPaymentFailureLongErrorMessage(
                      strPaymentFailureLongMessage
                    );
                  }
                }
              );
            }
          }
        })
        .catch({
          function(error) {
            const data = {
              AppId: ConfigData.appId,
              TokenId: getTokenFromStorage(),
              UserId: localStorage.getItem("UserId"),
              DateTime: curDT,
              PageUrl: PageUrl.pathname,
              ExceptionDetails: error.stack,
            };
            axios
              .post(urlAPI + "PostOSPortalLoggingExceptions", data)
              .then(function (response) {
                if (response.data.TokenInfoDetails.TokenResponse == "Expired") {
                  getToken();
                  axios.post(urlAPI + "PostOSPortalLoggingExceptions", data);
                }
              });
          },
        });
    }
  };
  const formValidation = () => {
    const dataCardTypeErrorMessage = {};
    const dataCardUserNameErrorMessage = {};
    const dataCardNoErrorMessage = {};
    const dataCardExpirationMonthErrorMessage = {};
    const dataCardExpirationYearErrorMessage = {};
    const dataCardSecurityCodeErrorMessage = {};
    const dataCardZipcodeErrorMessage = {};
    const dataCardEmailErrorMessage = {};

    let isValid = true;

    console.log(form.dataCardType);
    // validate card type
    if (form.dataCardType === "") {
      dataCardTypeErrorMessage.ErrorMessage = "Please select Card Type";
      isValid = false;
    } else {
      if (form.dataCardType === "0") {
        if (!form.dataCardNo.startsWith("4")) {
          dataCardTypeErrorMessage.ErrorMessage = "Invalid Card Type";
          isValid = false;
        }
      } else if (form.dataCardType === "1") {
        if (!form.dataCardNo.startsWith("5")) {
          dataCardTypeErrorMessage.ErrorMessage = "Invalid Card Type";
          isValid = false;
        }
      } else if (form.dataCardType === "2") {
        if (!form.dataCardNo.startsWith("3")) {
          dataCardTypeErrorMessage.ErrorMessage = "Invalid Card Type";
          isValid = false;
        }
      }
    }

    // validate card name
    if (form.dataCardUserName === "") {
      dataCardUserNameErrorMessage.ErrorMessage = "Please check Card UserName";
      isValid = false;
    }

    console.log(form.dataCardNo < 1);
    // validate card no
    if (form.dataCardNo === "") {
      dataCardNoErrorMessage.ErrorMessage = "Please check Card No.";
      isValid = false;
    } else if (isNaN(form.dataCardNo)) {
      dataCardNoErrorMessage.ErrorMessage = "Invalid Card No.";
      isValid = false;
    }
    console.log(isValid);

    // validate card expiry month
    if (form.dataCardExpirationMonth === "") {
      dataCardExpirationMonthErrorMessage.ErrorMessage =
        "Please check Card Expiration Month";
      isValid = false;
    } else if (isNaN(form.dataCardExpirationMonth)) {
      dataCardExpirationMonthErrorMessage.ErrorMessage =
        "Invalid Expiration Month";
      isValid = false;
    } else if (form.dataCardExpirationMonth < 1) {
      dataCardExpirationMonthErrorMessage.ErrorMessage =
        "Invalid Expiration Month";
      isValid = false;
    } else if (form.dataCardExpirationMonth > 31) {
      dataCardExpirationMonthErrorMessage.ErrorMessage =
        "Invalid Expiration Month";
      isValid = false;
    }

    // validate card expiry year
    if (form.dataCardExpirationYear === "") {
      dataCardExpirationYearErrorMessage.ErrorMessage =
        "Please check Card Expiration Year";
      isValid = false;
    } else if (isNaN(form.dataCardExpirationYear)) {
      dataCardExpirationMonthErrorMessage.ErrorMessage =
        "Invalid Expiration Year";
      isValid = false;
    } else if (form.dataCardExpirationYear < 1) {
      dataCardExpirationMonthErrorMessage.ErrorMessage =
        "Invalid Expiration Year";
      isValid = false;
    }

    // validate card security code
    if (form.dataCardSecurityCode === "") {
      dataCardSecurityCodeErrorMessage.ErrorMessage =
        "Pelase check Card Security code";
      isValid = false;
    }

    // validate card expiry year
    if (form.dataCardZipcode === "") {
      dataCardZipcodeErrorMessage.ErrorMessage = "Please check Card Zip code";
      isValid = false;
    }

    if (form.dataCardEmail === "") {
      dataCardEmailErrorMessage.ErrorMessage = "Please check Card user Email";
      isValid = false;
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(form.dataCardEmail)
    ) {
      dataCardEmailErrorMessage.ErrorMessage = "Invalid Email address";
      isValid = false;
    }

    if (isValid) {
      console.log("Authenticated", form);
    } else {
      console.log("errors try again");
    }

    setDataCardTypeErrorMessage(dataCardTypeErrorMessage);
    setDataCardUserNameErrorMessage(dataCardUserNameErrorMessage);
    setDataCardNoErrorMessage(dataCardNoErrorMessage);
    setDataCardExpirationMonthErrorMessage(dataCardExpirationMonthErrorMessage);
    setDataCardExpirationYearErrorMessage(dataCardExpirationYearErrorMessage);
    setDataCardSecurityCodeErrorMessage(dataCardSecurityCodeErrorMessage);
    setDataCardZipcodeErrorMessage(dataCardZipcodeErrorMessage);
    setDataCardEmailErrorMessage(dataCardEmailErrorMessage);

    return isValid;
  };

  const getDataIPAddress = async () => {
    return true;
  };

  const callPayment = async () => {
    console.log("entered payment call");

    const inputData = {
      AppId: ConfigData.appId,
      TokenId: getTokenFromStorage(),
      strPaymentAction: "Horse Transfer",
      dAmount: 0.02,
      strCardType: form.dataCardType,
      strNameOnCard: form.dataCardUserName,
      strCreditCardNumber: form.dataCardNo,
      strCardExpiryMonth: form.dataCardExpirationMonth,
      strCardExpiryYear: form.dataCardExpirationYear,
      strCardCvv: form.dataCardSecurityCode,
      strCurrencyCode: "USD",
      strZip: form.dataCardZipcode,
      strEmail: form.dataCardEmail,
      strUserId: localStorage.getItem("UserId"),
      strIdentification: "126835",
      strUserHostAddress: "",
      strClientIP: "192.168.1.11",
      strStaffUserId: "",
    };

    try {
      // Make the POST request
      const postPaymentUpdateURLAPI = `${urlAPI}OSPortalPaymentUpdateDetailsPost`;
      const response = await axios.post(postPaymentUpdateURLAPI, inputData);

      console.log("Post payment call");
      console.log(response.data);

      if (response.data.TokenInfoDetails.TokenResponse === "Expired") {
        renewToken();
      }

      if (
        !response.data.PaymentTransactionStatus ||
        response.data.PaymentTransactionStatus.PaymentStatus !== 1
      ) {
        throw new Error("Payment transaction failed!");
      }

      return response.data;
    } catch (error) {
      console.error("Error:", error);
      // Handle the error here
      throw error;
    }
  };

  const callPaymentErrorDetails = async (strPaymentStatusErrorCode) => {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };

    let getPaymentErrorDetailsUrlAPI =
      urlAPI +
      "GetOSPortalPaymentErrorDetails?AppId=" +
      appId +
      "&TokenId=" +
      getTokenFromStorage() +
      "&strErrorCode=" +
      strPaymentStatusErrorCode;
    const response = await fetch(getPaymentErrorDetailsUrlAPI, requestOptions);
    const data = await response.json();
    if (data.TokenInfoDetails.TokenResponse == "Expired") {
      renewToken();
    }
    if (!response.ok) {
      throw new Error("Data Payment error details cound not be fetched!");
    } else {
      console.log(response);
      return data;
    }
  };

  function onCloseCardDetailsClick(event) {
    const strPaymentFailureShortMessage = {};
    const strPaymentFailureLongMessage = {};

    props.updateShowCardDetailsPopup(!props.isShowCardDetailsPopup);
    setDataPaymentFailureShortErrorMessage(strPaymentFailureShortMessage);
    setDataPaymentFailureLongErrorMessage(strPaymentFailureLongMessage);

    setIsPaymentSuccess(false);
  }

  const handleCheckboxChange2 = (event) => {
    setisCheckedPay(event.target.checked);
    setErrorMessage2("");
  };

  const handleTransferAPI = async (e) => {
    if (isCheckedPay) {
      e.preventDefault();
      console.log("handleTransferAPI called");
      
      onSubmitForm(e, () => {
        console.log('change');
        navigate("/horse-table");
      });
    } else {
      setErrorMessage2(
        "Unless the approve checkbox is clicked you cannot transfer & pay"
      );
    }
  };

  // ================== Status List ================

  const [objstrMembershipSettingsData, setobjstrMembershipSettingsData] =
    useState();
  const [HorseTransferInitiationDetails, setHorseTransferInitiationDetails] =
    useState("");
  const handleSubmit = () => {
    setDisableSaveandNext(true);
    setLoading(true);
    handleCreateAPIRequest().then(function (response) {
      console.log("response");
      console.log(response);
      if (response.UserProfilePostData.Message == "SUCCESS" ) {
        window.open(
          serviceUrl + "/Login.aspx?user=" + localStorage.getItem("UserId"),
          "_self"
        );
        navigate("/horse-table");
      } else {
        setErrorMsg(response.UserProfilePostData.LongMessage);
      }
    });
  };

  const handleTransferStatusList = () => {
    navigate("/horse-table");
  };

  // ============= INITIATE TRANSFER ===================

  const handleCreateAPIRequest = async () => {
    setLoading(true);

    try {
      const strMembershipSettingsData = {
        PreferenceData: [],
      };

      // Push data into PreferenceData array
      const data = {
        AppId: ConfigData.appId,
        TokenId: getTokenFromStorage(),
        InitiationUserId: localStorage.getItem("UserId"),
        HorseMemberId:HorseMemberId,
        NewManagerMemberId: PendingRecordManagerMemberId,
        NewManagerName: PendingRecordManagerName,
        NewManagerEmail: PendingRecordManagerEmail,
      };

      console.log(strMembershipSettingsData);
      setobjstrMembershipSettingsData(strMembershipSettingsData);

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(data),
      };

      const postMembershipSettingsURLAPI =
        urlAPI + "OSPortalHorseTransferInitiateTransferPost";

      const response = await fetch(
        postMembershipSettingsURLAPI,
        requestOptions
      );

      if (!response.ok) {
        console.error("Response status:", response.status);
        const responseText = await response.text();
        console.error("Response text:", responseText);
        throw new Error("Data Account Settings Post could not be fetched!");
      }

      const responseData = await response.json();
      console.log(responseData);

      if (
        responseData &&
        responseData.HorseTransferInitiatePostData.Message === "SUCCESS" ||
        responseData.HorseTransferInitiatePostData.Message === "Approved: Process Payment" 
        
      ) {
        // window.open(
        //   serviceUrl + "/Login.aspx?user=" + localStorage.getItem("UserId"),
        //   "_self"
        // );
        navigate('/horse-table');
      } else {
        setErrorMsg(
          responseData?.HorseTransferInitiatePostData.LongMessage ||
            "An error occurred"
        );
      }
    } catch (error) {
      console.error("Error creating API request:", error);
    } finally {
      setLoading(false);
    }
  };

  switch (step) {
    case 1:
      return (
        <Box sx={{ backgroundColor: "#F7F7F9", p: "25px 0" }}>
          {" "}
          <Box
            sx={{ maxWidth: "750px", margin: "0 auto" }}
            className="account-form"
          >
            <Card className="card_box">
              <CardHeader
                title="Horse Record Management Transfer"
                sx={{ textAlign: "center" }}
              />
              <Form
                initialValues={{}}
                
                render={(formRenderProps) => (
                  <FormElement>
                    <CardContent>
                      <Grid container spacing={5}>
                        <Grid item xs={5}>
                          <Typography
                            variant="body2"
                            sx={{ fontWeight: 600, m: "2!important" }}
                          >
                            {HorseTransferInitiationHorseDetails.HorseName}(#
                            {HorseTransferInitiationHorseDetails.HorseMemberId})
                          </Typography>
                          <Typography
                            sx={{
                              fontWeight: 400,
                              m: "0 !important",
                              fontSize: "14px",
                            }}
                          >
                            {HorseTransferInitiationHorseDetails.HorseMembership}
                          </Typography>
                          <Typography
                            sx={{
                              fontWeight: 400,
                              m: "0 !important",
                              fontSize: "14px",
                            }}
                          >
                            Year Foal:{" "}
                            {
                              HorseTransferInitiationHorseDetails.HorseYearFoaled
                            }{" "}
                            | {HorseTransferInitiationHorseDetails.HorseBreed},{" "}
                            {HorseTransferInitiationHorseDetails.HorseColor},{" "}
                            {HorseTransferInitiationHorseDetails.HorseName},{" "}
                            {
                              HorseTransferInitiationHorseDetails.HorseMembership
                            }
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider sx={{ m: "0 !important" }} />
                    <CardContent>
                      <Grid container spacing={5}>
                        <Grid item xs={12}>
                          <Typography variant="body2" sx={{ fontWeight: 600 }}>
                            Current Record Manager
                          </Typography>
                          <p className="ml-2 horse_text">
                            {HorseTransferStatus3}
                          </p>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider sx={{ m: "0 !important" }} />
                    <CardContent>
                      <Grid container spacing={5}>
                        <Grid item xs={12}>
                          <Typography variant="body2" sx={{ fontWeight: 600 }}>
                            New Record Manager
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} className="input_box">
                          {/* <TextField
                            fullWidth
                            name={"USEA ID"}
                            type={"text"}
                            label={"USEA ID"}
                            placeholder="USEA ID"
                            value={PendingRecordManagerMemberId}
                            onChange={(e) =>
                              setPendingRecordManagerMemberId(e.target.value)
                            }
                          /> */}
                          <Typography variant="body2" sx={{ fontWeight: 400 }}>
                            USEA ID
                          </Typography>
                          <p className="ml-2 horse_text">
                            {PendingRecordManagerMemberId}
                          </p>
                        </Grid>
                        <Grid item xs={12} sm={12} className="input_box">
                          {/* <TextField
                            fullWidth
                            name={"Name"}
                            type={"text"}
                            label={"Name"}
                            placeholder="Name"
                            value={PendingRecordManagerName}
                            onChange={(e) =>
                              setPendingRecordManagerName(e.target.value)
                            }
                          /> */}
                          <Typography variant="body2" sx={{ fontWeight: 400 }}>
                            Name
                          </Typography>
                          <p className="ml-2 horse_text">
                            {PendingRecordManagerName}
                          </p>
                        </Grid>
                        <Grid item xs={12} sm={12} className="input_box">
                          {/* <TextField
                            fullWidth
                            name={"Email"}
                            type={"email"}
                            label={"Email"}
                            placeholder="Email"
                            value={PendingRecordManagerEmail}
                            onChange={(e) =>
                              setPendingRecordManagerEmail(e.target.value)
                            }
                          /> */}
                          <Typography variant="body2" sx={{ fontWeight: 400 }}>
                            Email
                          </Typography>
                          <p className="ml-2 horse_text">
                            {PendingRecordManagerEmail}
                          </p>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider sx={{ m: "0 !important" }} />
                    <CardContent>
                      <Grid container spacing={5}>
                        <Grid item xs={12}>
                          {/* <FormControlLabel
                        className="checkbox"
                        label="I understand that this transfer is not complete until a transfer payment has been made by the new manager."
                        // control={<Checkbox checked={true} />}
                        control={
                          <Checkbox
                            checked={isChecked}
                            onChange={handleCheckboxChange}
                          />
                        }
                      /> */}
                          <label className="horse_checkbox">
                            <input
                              type="checkbox"
                              checked={isChecked}
                              onChange={handleCheckboxChange}
                            />
                            I understand that this transfer is not complete
                            until a transfer payment has been made by the new
                            manager.
                          </label>
                          {errorMessage && (
                            <p style={{ color: "red" }}>{errorMessage}</p>
                          )}
                        </Grid>
                        <Grid item xs={12}>
                          <CardActions
                            sx={{ justifyContent: "center", p: "0" }}
                          >
                            <Button
                              variant="contained"
                              className="btn"
                              size="large"
                              type="submit"
                              sx={{ backgroundColor: "#000" }}
                              onClick={handleCancelAPI}
                            >
                              CANCEL TRANSFER
                            </Button>
                          </CardActions>
                        </Grid>
                      </Grid>
                    </CardContent>

                    <Grid item xs={12}>
                      <CardHeader title="OR" sx={{ textAlign: "center" }} />
                    </Grid>

                    <CardContent>
                      <Grid container spacing={5}>
                        {!isTransferApproved ? (
                          <Grid container spacing={5}>
                            <Grid item xs={12}>
                              <label className="horse_checkbox">
                                <input
                                  type="checkbox"
                                  onChange={handleCheckboxChange1}
                                  checked={isTransferApprovedchecked}
                                />
                                I understand that this transfer is not complete
                                until a transfer payment has been made by the
                                new manager.
                              </label>
                              {errorMessage1 && (
                                <p style={{ color: "red" }}>{errorMessage1}</p>
                              )}
                            </Grid>
                            <Grid item xs={12}>
                              <CardActions
                                sx={{ justifyContent: "center", p: "0" }}
                              >
                                <Button
                                  variant="contained"
                                  className="btn"
                                  size="large"
                                  type="submit"
                                  sx={{ backgroundColor: "#000" }}
                                  onClick={handleapprovetransfer}
                                >
                                  APPROVE TRANSFER
                                </Button>
                              </CardActions>
                            </Grid>
                          </Grid>
                        ) : (
                          <Grid item xs={12}>
                            <div className={classes.divFieldSetDetails}>
                              <fieldset
                                className={
                                  classes.fieldsetUpdateCardDetailsData
                                }
                              >
                                <div className={classes.divUpdateCardDetails}>
                                  <CardHeader
                                    className="payment_title"
                                    title="Update Payment"
                                    sx={{
                                      textAlign: "center",
                                      p: "0 !important",
                                    }}
                                  />
                                </div>
                                <div>
                                  {/* <div className={classes.divCardDetails}>
                                <span className={classes.spanCardDetails}>
                                  <i
                                    class="fa fa-cc-visa fa-3x"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                                <span className={classes.spanCardDetails}>
                                  <i
                                    class="fa fa-cc-mastercard fa-3x"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                                <span className={classes.spanCardDetails}>
                                  <i
                                    class="fa fa-cc-amex fa-3x"
                                    aria-hidden="true"
                                  ></i>
                                </span>
                              </div> */}
                                  {Object.keys(
                                    dataPaymentFailureShortErrorMessage
                                  ).map((key) => {
                                    return (
                                      <div
                                        className={
                                          classes.lblFailureErrorMessages
                                        }
                                      >
                                        {
                                          dataPaymentFailureShortErrorMessage[
                                            key
                                          ]
                                        }
                                        <br />
                                      </div>
                                    );
                                  })}
                                  {Object.keys(
                                    dataPaymentFailureLongErrorMessage
                                  ).map((key) => {
                                    return (
                                      <div
                                        className={
                                          classes.lblFailureErrorMessages
                                        }
                                      >
                                        {
                                          dataPaymentFailureLongErrorMessage[
                                            key
                                          ]
                                        }
                                        <br />
                                        <br />
                                      </div>
                                    );
                                  })}
                                  <Typography
                                    variant="body2"
                                    className="payment_text"
                                  >
                                    Payment Amount
                                  </Typography>
                                  <div className={classes.divPaymentText}>
                                    <div className={classes.divPaymentTextLeft}>
                                      <Typography className="PaymentTextLeft">
                                        $25
                                      </Typography>
                                    </div>
                                    <div
                                      className={classes.divPaymentTextRight}
                                    >
                                      <Typography className="PaymentTextLeft">
                                        Transfer Fee
                                      </Typography>
                                    </div>
                                  </div>
                                  <br />
                                  {isPaymentSuccess ? (
                                    <div>
                                      <span className="PaymentTextLeft">
                                        Card details updated.
                                      </span>
                                    </div>
                                  ) : (
                                    <div>
                                      <div>
                                        <div
                                          className={classes.divCardDataDetails}
                                        >
                                          <Typography
                                            variant="body2"
                                            className="payment_text"
                                            sx={{ mb: "10px !important" }}
                                          >
                                            Card Type
                                          </Typography>
                                          <label className="payment_icon">
                                            <input
                                              type="radio"
                                              class="hide"
                                              name="dataCardType"
                                              value="0"
                                              onChange={onUpdateField}
                                            />
                                            &nbsp;
                                            <i
                                              class="fa fa-cc-visa fa-2x"
                                              aria-hidden="true"
                                            ></i>
                                          </label>
                                          <label className="payment_icon">
                                            <input
                                              type="radio"
                                              class="hide"
                                              name="dataCardType"
                                              value="1"
                                              onChange={onUpdateField}
                                            />
                                            &nbsp;
                                            <i
                                              class="fa fa-cc-mastercard fa-2x"
                                              aria-hidden="true"
                                            ></i>
                                          </label>
                                          <label className="payment_icon">
                                            <input
                                              type="radio"
                                              class="hide"
                                              name="dataCardType"
                                              value="2"
                                              onChange={onUpdateField}
                                            />
                                            &nbsp;
                                            <i
                                              class="fa fa-cc-amex fa-2x"
                                              aria-hidden="true"
                                            ></i>
                                          </label>
                                          {Object.keys(
                                            dataCardTypeErrorMessage
                                          ).map((key) => {
                                            return (
                                              <div
                                                className={classes.lblErrors}
                                              >
                                                {dataCardTypeErrorMessage[key]}
                                              </div>
                                            );
                                          })}
                                        </div>
                                        <div
                                          className={classes.divCardDataDetails}
                                        >
                                          <span
                                            className={
                                              classes.lblCardDetailsPayment
                                            }
                                          >
                                            Name on Card
                                          </span>
                                          <input
                                            type="text"
                                            className={classes.txtboxDetails}
                                            name="dataCardUserName"
                                            // value={
                                            //   props.objModelMembershipDetails
                                            //     .CreditCardDetails.CreditCardName
                                            // }
                                            onChange={onUpdateField}
                                          />
                                          {Object.keys(
                                            dataCardUserNameErrorMessage
                                          ).map((key) => {
                                            return (
                                              <div
                                                className={classes.lblErrors}
                                              >
                                                {
                                                  dataCardUserNameErrorMessage[
                                                    key
                                                  ]
                                                }
                                              </div>
                                            );
                                          })}
                                        </div>
                                        <div
                                          className={classes.divCardDataDetails}
                                        >
                                          <span
                                            className={
                                              classes.lblCardDetailsPayment
                                            }
                                          >
                                            Card Number
                                          </span>
                                          <input
                                            type="text"
                                            className={classes.txtboxDetails}
                                            name="dataCardNo"
                                            onChange={onUpdateField}
                                            maxLength="16"
                                          />
                                          {Object.keys(
                                            dataCardNoErrorMessage
                                          ).map((key) => {
                                            return (
                                              <div
                                                className={classes.lblErrors}
                                              >
                                                {dataCardNoErrorMessage[key]}
                                              </div>
                                            );
                                          })}
                                        </div>
                                        <div
                                          className={classes.divCardDataDetails}
                                        >
                                          <div
                                            className={
                                              classes.divExpirationText
                                            }
                                          >
                                            <div
                                              className={
                                                classes.divExpirationTextLeft
                                              }
                                            >
                                              <span
                                                className={
                                                  classes.lblCardDetailsPayment
                                                }
                                              >
                                                Expiration Date (MM/YYYY)
                                              </span>
                                            </div>
                                            <div
                                              className={
                                                classes.divExpirationTextRight
                                              }
                                            >
                                              <span
                                                className={
                                                  classes.lblCardDetailsPayment
                                                }
                                              >
                                                Security Code
                                              </span>
                                            </div>
                                          </div>
                                          <div
                                            className={
                                              classes.divExpirationText
                                            }
                                          >
                                            <div
                                              className={
                                                classes.divExpirationTextLeft
                                              }
                                            >
                                              {/*                                                         <input type="text"
                                                            className={classes.txtboxExpirationDetails}
                                                            name='dataCardExpirationMonth'
                                                            onChange={onUpdateField}
                                                            maxLength="2"
                                                            max="31" /> */}
                                              <MaskedTextBox
                                                className={
                                                  classes.txtboxExpirationDetails
                                                }
                                                name="dataCardExpirationMonth"
                                                onChange={onUpdateField}
                                                mask="##"
                                                maxLength="2"
                                                max="31"
                                                defaultValue="##"
                                              />
                                              <span> / </span>
                                              <MaskedTextBox
                                                className={
                                                  classes.txtboxExpirationDetails
                                                }
                                                name="dataCardExpirationYear"
                                                onChange={onUpdateField}
                                                maxLength="4"
                                                mask="####"
                                                defaultValue="####"
                                              />
                                            </div>
                                            <div
                                              className={
                                                classes.divExpirationTextRight
                                              }
                                            >
                                              <MaskedTextBox
                                                className={
                                                  classes.txtboxSecurityCodeDetails
                                                }
                                                name="dataCardSecurityCode"
                                                onChange={onUpdateField}
                                                maxLength="4"
                                                mask="####"
                                                defaultValue="####"
                                              />
                                            </div>
                                          </div>
                                          {Object.keys(
                                            dataCardExpirationMonthErrorMessage
                                          ).map((key) => {
                                            return (
                                              <div
                                                className={classes.lblErrors}
                                              >
                                                {
                                                  dataCardExpirationMonthErrorMessage[
                                                    key
                                                  ]
                                                }
                                              </div>
                                            );
                                          })}
                                          {Object.keys(
                                            dataCardExpirationYearErrorMessage
                                          ).map((key) => {
                                            return (
                                              <div
                                                className={classes.lblErrors}
                                              >
                                                {
                                                  dataCardExpirationYearErrorMessage[
                                                    key
                                                  ]
                                                }
                                              </div>
                                            );
                                          })}
                                          {Object.keys(
                                            dataCardSecurityCodeErrorMessage
                                          ).map((key) => {
                                            return (
                                              <div
                                                className={classes.lblErrors}
                                              >
                                                {
                                                  dataCardSecurityCodeErrorMessage[
                                                    key
                                                  ]
                                                }
                                              </div>
                                            );
                                          })}
                                        </div>
                                        <div
                                          className={classes.divCardDataDetails}
                                        >
                                          <span
                                            className={
                                              classes.lblCardDetailsPayment
                                            }
                                          >
                                            Zip/Postal Code
                                          </span>
                                          <input
                                            type="text"
                                            className={classes.txtboxDetails}
                                            name="dataCardZipcode"
                                            onChange={onUpdateField}
                                          />
                                          {Object.keys(
                                            dataCardZipcodeErrorMessage
                                          ).map((key) => {
                                            return (
                                              <div
                                                className={classes.lblErrors}
                                              >
                                                {
                                                  dataCardZipcodeErrorMessage[
                                                    key
                                                  ]
                                                }
                                              </div>
                                            );
                                          })}
                                        </div>
                                        <div
                                          className={classes.divCardDataDetails}
                                        >
                                          <span
                                            className={
                                              classes.lblCardDetailsPayment
                                            }
                                          >
                                            Email
                                          </span>
                                          <br />
                                          <input
                                            type="text"
                                            className={classes.txtboxDetails}
                                            name="dataCardEmail"
                                            onChange={onUpdateField}
                                          />
                                          {Object.keys(
                                            dataCardEmailErrorMessage
                                          ).map((key) => {
                                            return (
                                              <div
                                                className={classes.lblErrors}
                                              >
                                                {dataCardEmailErrorMessage[key]}
                                              </div>
                                            );
                                          })}
                                        </div>
                                        <div className={classes.divButtonGroup}>
                                          {/* <Button
                                            themeColor={"primary"}
                                            onClick={onSubmitForm}
                                          >
                                            Update Card
                                          </Button> */}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                  <div className={classes.divButtonGroup}>
                                    <a
                                      href="#"
                                      className={classes.aCloseLink}
                                      onClick={onCloseCardDetailsClick}
                                    >
                                      Close
                                    </a>
                                  </div>
                                </div>
                              </fieldset>
                              <label className="horse_checkbox">
                                <input
                                  type="checkbox"
                                  checked={isCheckedPay}
                                  onChange={handleCheckboxChange2}
                                />
                                I understand that this transfer is not complete
                                until a transfer payment has been made by the
                                new manager.
                              </label>
                              {errorMessage2 && (
                                <p style={{ color: "red" }}>{errorMessage2}</p>
                              )}
                              <Grid item xs={12}>
                                <CardActions
                                  sx={{ justifyContent: "center", p: "0" }}
                                >
                                  <Button
                                    variant="contained"
                                    className="btn"
                                    size="large"
                                    type="submit"
                                    sx={{ backgroundColor: "#000" }}
                                    onClick={onSubmitForm}
                                  >
                                    TRANSFER & PAY
                                  </Button>
                                </CardActions>
                              </Grid>
                            </div>
                          </Grid>
                        )}
                      </Grid>
                    </CardContent>
                  </FormElement>
                )}
              />
            </Card>
          </Box>
        </Box>
      );
    case 2:
      return (
        <Box sx={{ backgroundColor: "#F7F7F9", p: "25px 0" }}>
          {" "}
          <Box
            sx={{ maxWidth: "750px", margin: "0 auto" }}
            className="account-form"
          >
            <Card className="card_box">
              <CardHeader
                title="Horse Record Management Transfer"
                sx={{ textAlign: "center" }}
              />
              <Form
                initialValues={{}}
                onSubmitClick={handleSubmit}
                render={(formRenderProps) => (
                  <FormElement>
                    <CardContent>
                      <Grid container spacing={5}>
                        <Grid item xs={5}>
                          <Typography
                            variant="body2"
                            sx={{ fontWeight: 600, m: "2!important" }}
                          >
                            {HorseTransferInitiationHorseDetails.HorseName} (#
                            {HorseTransferInitiationHorseDetails.HorseMemberId})
                          </Typography>
                          <Typography
                            sx={{
                              fontWeight: 400,
                              m: "0 !important",
                              fontSize: "14px",
                            }}
                          >
                            {HorseTransferInitiationHorseDetails.HorseMembership}
                            
                          </Typography>
                          <Typography
                            sx={{
                              fontWeight: 400,
                              m: "0 !important",
                              fontSize: "14px",
                            }}
                          >
                            Year Foal:{" "}
                            {
                              HorseTransferInitiationHorseDetails.HorseYearFoaled
                            }{" "}
                            | {HorseTransferInitiationHorseDetails.HorseBreed},{" "}{HorseTransferInitiationHorseDetails.HorseColor}
                            
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider sx={{ m: "0 !important" }} />
                    <CardContent>
                      <Grid container spacing={5}>
                        <Grid item xs={12}>
                          <Typography variant="body2" sx={{ fontWeight: 600 }}>
                            Current Record Manager
                          </Typography>
                          <p className="ml-2 horse_text">
                            {HorseTransferStatus3}
                          </p>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider sx={{ m: "0 !important" }} />
                    <CardContent>
                      <Grid container spacing={5}>
                        <Grid item xs={12}>
                          <Typography variant="body2" sx={{ fontWeight: 600 }}>
                            New Record Manager
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} className="input_box">
                          {/* <TextField
                            fullWidth
                            name={"USEA ID"}
                            type={"text"}
                            label={"USEA ID"}
                            placeholder="USEA ID"
                            value={PendingRecordManagerMemberId}
                            onChange={(e) =>
                              setPendingRecordManagerMemberId(e.target.value)
                            }
                          /> */}
                          <Typography variant="body2" sx={{ fontWeight: 400 }}>
                            USEA ID
                          </Typography>
                          <p className="ml-2 horse_text">
                            {PendingRecordManagerMemberId}
                          </p>
                        </Grid>
                        <Grid item xs={12} sm={12} className="input_box">
                          {/* <TextField
                            fullWidth
                            name={"Name"}
                            type={"text"}
                            label={"Name"}
                            placeholder="Name"
                            value={PendingRecordManagerName}
                            onChange={(e) =>
                              setPendingRecordManagerName(e.target.value)
                            }
                          /> */}
                          <Typography variant="body2" sx={{ fontWeight: 400 }}>
                            Name
                          </Typography>
                          <p className="ml-2 horse_text">
                            {PendingRecordManagerName}
                          </p>
                        </Grid>
                        <Grid item xs={12} sm={12} className="input_box">
                          {/* <TextField
                            fullWidth
                            name={"Email"}
                            type={"email"}
                            label={"Email"}
                            placeholder="Email"
                            value={PendingRecordManagerEmail}
                            onChange={(e) =>
                              setPendingRecordManagerEmail(e.target.value)
                            }
                          /> */}
                          <Typography variant="body2" sx={{ fontWeight: 400 }}>
                            Email
                          </Typography>
                          <p className="ml-2 horse_text">
                            {PendingRecordManagerEmail}
                          </p>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider sx={{ m: "0 !important" }} />
                    <CardContent>
                      <Grid container spacing={5}>
                        <Grid item xs={12}>
                          {/* <p className="unverified">{transferStatus}</p> */}
                          {/* <p>Status: {transferStatus}</p> */}
                          <p className="unverified">{statusMessage}</p>
                          <CardActions
                            sx={{ justifyContent: "center", p: "0" }}
                          >
                            <Button
                              variant="contained"
                              className="btn"
                              disabled={loading}
                              size="large"
                              type="submit"
                              sx={{ backgroundColor: "#000" }}
                              onClick={handleTransferStatusList}
                            >
                              {loading && <CircularProgress size={20} />}
                              {!loading && "TRANSFER STATUS LIST"}
                            </Button>
                          </CardActions>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </FormElement>
                )}
              />
            </Card>
          </Box>
        </Box>
      );
    case 3:
      return (
        <Box sx={{ backgroundColor: "#F7F7F9", p: "25px 0" }}>
          {" "}
          <Box
            sx={{ maxWidth: "750px", margin: "0 auto" }}
            className="account-form"
          >
            <Card className="card_box">
              <CardHeader
                title="Horse Record Management Transfer"
                sx={{ textAlign: "center" }}
              />
              <Form
                initialValues={{}}
                onSubmitClick={handleSubmit}
                render={(formRenderProps) => (
                  <FormElement>
                    <CardContent>
                      <Grid container spacing={5}>
                        <Grid item xs={5}>
                          <Typography
                            variant="body2"
                            sx={{ fontWeight: 600, m: "2!important" }}
                          >
                            {HorseTransferInitiationHorseDetails.HorseName} (#
                            {HorseTransferInitiationHorseDetails.HorseMemberId})
                          </Typography>
                          <Typography
                            sx={{
                              fontWeight: 400,
                              m: "0 !important",
                              fontSize: "14px",
                            }}
                          >
                            {HorseTransferInitiationHorseDetails.HorseMembership}
                            
                          </Typography>
                          <Typography
                            sx={{
                              fontWeight: 400,
                              m: "0 !important",
                              fontSize: "14px",
                            }}
                          >
                            Year Foal:{" "}
                            {
                              HorseTransferInitiationHorseDetails.HorseYearFoaled
                            }{" "}
                            | {HorseTransferInitiationHorseDetails.HorseBreed},{" "}{HorseTransferInitiationHorseDetails.HorseColor}
                           
                           
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider sx={{ m: "0 !important" }} />
                    <CardContent>
                      <Grid container spacing={5}>
                        <Grid item xs={12}>
                          <Typography variant="body2" sx={{ fontWeight: 600 }}>
                            Current Record Manager
                          </Typography>
                          <p className="ml-2 horse_text">
                            {HorseTransferStatus3}
                          </p>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider sx={{ m: "0 !important" }} />
                    <CardContent>
                      <Grid container spacing={5}>
                        <Grid item xs={12}>
                          <Typography variant="body2" sx={{ fontWeight: 600 }}>
                            New Record Manager
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} className="input_box">
                          <TextField
                            fullWidth
                            name={"USEA ID1"}
                            type={"text"}
                            label={"USEA ID"}
                            placeholder="USEA ID"
                            value={PendingRecordManagerMemberId}
                            onChange={(e) =>
                              setPendingRecordManagerMemberId(e.target.value)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} className="input_box">
                          <TextField
                            fullWidth
                            name={"Name"}
                            type={"text"}
                            label={"Name"}
                            placeholder="Name"
                            value={PendingRecordManagerName}
                            onChange={(e) =>
                              setPendingRecordManagerName(e.target.value)
                            }
                          />
                        </Grid>
                        <Grid item xs={12} sm={12} className="input_box">
                          <TextField
                            fullWidth
                            name={"Email"}
                            type={"email"}
                            label={"Email"}
                            placeholder="Email"
                            value={PendingRecordManagerEmail}
                            onChange={(e) =>
                              setPendingRecordManagerEmail(e.target.value)
                            }
                          />
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider sx={{ m: "0 !important" }} />
                    <CardContent>
                      <Grid container spacing={5}>
                        <Grid item xs={12}>
                          <p>
                            Please note that this transfer will only be
                            considered complete upon receipt of a $25 payment.
                            By clicking "Initiate Transfer," you can commence
                            the transfer and proceed to make the necessary
                            payment in order to finalize the transfer process.
                          </p>
                        </Grid>
                        <Grid item xs={12}>
                          <CardActions
                            sx={{ justifyContent: "center", p: "0" }}
                          >
                            <Button
                              variant="contained"
                              className="btn"
                              disabled={loading}
                              size="large"
                              type="submit"
                              sx={{ backgroundColor: "#000" }}
                              onClick={handleCreateAPIRequest}
                            >
                              {loading && <CircularProgress size={20} />}
                              {!loading && "INITIATE TRANSFER"}
                            </Button>
                          </CardActions>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </FormElement>
                )}
              />
            </Card>
          </Box>
        </Box>
      );
    default:
      return (
        <Box sx={{ backgroundColor: "#F7F7F9", p: "25px 0" }}>
          {" "}
          <Box
            sx={{ maxWidth: "750px", margin: "0 auto" }}
            className="account-form"
          >
            <Card className="card_box">
              <CardHeader
                title="Horse Record Management Transfer"
                sx={{ textAlign: "center" }}
              />
              <Form
                initialValues={{}}
                onSubmitClick={handleSubmit}
                render={(formRenderProps) => (
                  <FormElement>
                    <CardContent>
                      <Grid container spacing={5}>
                        <Grid item xs={5}>
                          <Typography
                            variant="body2"
                            sx={{ fontWeight: 600, m: "2!important" }}
                          >
                            {HorseTransferInitiationHorseDetails.HorseName} (#
                            {HorseTransferInitiationHorseDetails.HorseMemberId})
                          </Typography>
                          <Typography
                            sx={{
                              fontWeight: 400,
                              m: "0 !important",
                              fontSize: "14px",
                            }}
                          >
                            {HorseTransferInitiationHorseDetails.HorseMembership}

                          </Typography>
                          <Typography
                            sx={{
                              fontWeight: 400,
                              m: "0 !important",
                              fontSize: "14px",
                            }}
                          >
                            Year Foal:{" "}
                            {
                              HorseTransferInitiationHorseDetails.HorseYearFoaled
                            }{" "}
                            | {HorseTransferInitiationHorseDetails.HorseBreed},{" "}{HorseTransferInitiationHorseDetails.HorseColor}
                          </Typography>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider sx={{ m: "0 !important" }} />
                    <CardContent>
                      <Grid container spacing={5}>
                        <Grid item xs={12}>
                          <Typography variant="body2" sx={{ fontWeight: 600 }}>
                            Current Record Manager
                          </Typography>
                          <p className="ml-2 horse_text">
                            {HorseTransferStatus3}
                          </p>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider sx={{ m: "0 !important" }} />
                    <CardContent>
                      <Grid container spacing={5}>
                        <Grid item xs={12}>
                          <Typography variant="body2" sx={{ fontWeight: 600 }}>
                            New Record Manager
                          </Typography>
                        </Grid>
                        <Grid item xs={12} sm={12} className="input_box">
                          {/* <TextField
                            fullWidth
                            name={"USEA ID"}
                            type={"text"}
                            label={"USEA ID"}
                            placeholder="USEA ID"
                            value={PendingRecordManagerMemberId}
                            onChange={(e) =>
                              setPendingRecordManagerMemberId(e.target.value)
                            }
                          /> */}
                          <Typography variant="body2" sx={{ fontWeight: 400 }}>
                            USEA ID
                          </Typography>
                          <p className="ml-2 horse_text">
                            {PendingRecordManagerMemberId}
                          </p>
                        </Grid>
                        <Grid item xs={12} sm={12} className="input_box">
                          {/* <TextField
                            fullWidth
                            name={"Name"}
                            type={"text"}
                            label={"Name"}
                            placeholder="Name"
                            value={PendingRecordManagerName}
                            onChange={(e) =>
                              setPendingRecordManagerName(e.target.value)
                            }
                          /> */}
                          <Typography variant="body2" sx={{ fontWeight: 400 }}>
                            Name
                          </Typography>
                          <p className="ml-2 horse_text">
                            {PendingRecordManagerName}
                          </p>
                        </Grid>
                        <Grid item xs={12} sm={12} className="input_box">
                          {/* <TextField
                            fullWidth
                            name={"Email"}
                            type={"email"}
                            label={"Email"}
                            placeholder="Email"
                            value={PendingRecordManagerEmail}
                            onChange={(e) =>
                              setPendingRecordManagerEmail(e.target.value)
                            }
                          /> */}
                          <Typography variant="body2" sx={{ fontWeight: 400 }}>
                            Email
                          </Typography>
                          <p className="ml-2 horse_text">
                            {PendingRecordManagerEmail}
                          </p>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider sx={{ m: "0 !important" }} />
                    <CardContent>
                      <Grid container spacing={5}>
                        <Grid item xs={12}>
                          <p>
                            Please note that this transfer will only be
                            considered complete upon receipt of a $25 payment.
                            By clicking "Initiate Transfer," you can commence
                            the transfer and proceed to make the necessary
                            payment in order to finalize the transfer process.
                          </p>
                        </Grid>
                        <Grid item xs={12}>
                          <CardActions
                            sx={{ justifyContent: "center", p: "0" }}
                          >
                            <Button
                              variant="contained"
                              className="btn"
                              disabled={loading}
                              size="large"
                              type="submit"
                              sx={{ backgroundColor: "#000" }}
                              onClick={handleCreateAPIRequest}
                            >
                              {loading && <CircularProgress size={20} />}
                              {!loading && "INITIATE TRANSFER"}
                            </Button>
                          </CardActions>
                        </Grid>
                      </Grid>
                    </CardContent>
                  </FormElement>
                )}
              />
            </Card>
          </Box>
        </Box>
      );
  }
}

export default HorsesTransfer;
