import React,{useEffect} from 'react';
import { Outlet } from 'react-router-dom';
import { useNavigate, useLocation ,createSearchParams} from 'react-router-dom';

import Header from '../MasterFiles/Header';
import ConfigData from "../../ConfigurationData/ConfigData.json";
import { getUserId ,getUserIdAfterLogin} from '../../api/RestServices';


const PublicLayout = () => {

    const user = getUserIdAfterLogin();
    const user1 = getUserId();
    const navigate = useNavigate();
    const prevLocation = useLocation();
    let serviceUrl = ConfigData.serviceUrl;
    const useNavigateSearch = () => {
  return (pathname, params) =>
    navigate({ pathname, search: `?${createSearchParams(params)}` });
};
    const queryParams = new URLSearchParams(window.location.search);

  const navigateSearch = useNavigateSearch();
        console.log(prevLocation);
    useEffect(() => {
       
         if (prevLocation.pathname == '/*'){
            window.open(serviceUrl +'/Login.aspx?user='+localStorage.getItem('UserId'),'_self');

        }else{
            return;
        }
       
          
         
    },[]);

  return (
    <section className="u-clearfix u-section-1">
       <header className="u-clearfix u-header nomenu_header container" data-animation-name="" data-animation-duration="0" data-animation-delay="0" data-animation-direction="">
        <div className="u-clearfix u-sheet u-sheet-1 ">
          <div className="u-clearfix u-expanded-width u-group-elements u-group-elements-1">
            <a href={serviceUrl+'/Dashboard.aspx'} className="u-image u-logo u-image-1" data-image-width="794" data-image-height="424" title="https://portal.useventing.com">
              <img alt="" src="../../assets/images/member_service_portal.png" className="u-logo-image u-logo-image-1" />
            </a>
          </div>
        </div>
      </header> 
      <Outlet />
    </section>
  )
}
export default PublicLayout