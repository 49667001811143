// Header.js
import React, { Component, useState,useEffect} from 'react';
import ConfigData from "../../ConfigurationData/ConfigData.json";

function Logout() {
    let serviceUrl = ConfigData.serviceUrl;
	
 useEffect(() => {
     
        localStorage.removeItem('UserId');
          localStorage.clear();
         window.open(serviceUrl +'/Logout/Logout.aspx','_self');
   
     }, []);
       
   return (
   			<div></div>
      )
}

export default Logout