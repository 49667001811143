import React from "react";
import { useState, useEffect, useCallback } from "react";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import ConfigData from "../../ConfigurationData/ConfigData.json";
import { Error } from "@progress/kendo-react-labels";
import {
  Input,
  Checkbox,
  InputSuffix,
  TextBox,
} from "@progress/kendo-react-inputs";
import {
  getToken,
  get,
  getLoginEmail,
  getLoginWithPassword,
  getUserId,
  getTokenFromStorage,
} from "../../api/RestServices";
import axios from "axios";
import moment from "moment";
import { useLocation } from "react-router-dom";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import { styled } from "@mui/material/styles";
import MuiCard from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import IconButton from '@mui/material/IconButton';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import InputAdornment from '@mui/material/InputAdornment';


const Card = styled(MuiCard)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: { width: 450 },
}));

const pwdRegex = new RegExp(/^[A-Za-z\d@$!#%*?&]{10,}$/);
const conpwdRegex = new RegExp(/^[A-Za-z\d@$!#%*?&]{10,}$/);
// const userRegex = new RegExp(/^(?:\d{5,7}|\w+@\w+\.\w{2,3})$/);

const pwdValidator = (value) =>
  pwdRegex.test(value) ? "" : "Password must be at least 10 characters. The first character must be a letter or number.";

const confirmpwdValidator = (value) =>
  conpwdRegex.test(value) ? "" : "Password must be at least 10 characters. The first character must be a letter or number.";

// const userValidator = (value) =>
//   userRegex.test(value) ? "" : "Please enter a valid User Code or Email";

const passwordInput = (fieldRenderProps) => {
  const { validationMessage, visited, ...others } = fieldRenderProps;
  return (
    <div>
      <TextField {...others} />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};

const confirmpasswordInput = (fieldRenderProps) => {
  const { validationMessage, visited, ...others } = fieldRenderProps;
  return (
    <div>
      <TextField {...others} />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};

const UserCodeInput = (fieldRenderProps) => {
  const { validationMessage, visited, ...others } = fieldRenderProps;
  return (
    <div>
      <TextField {...others} />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};
function EmailVerification() {
  const [password, setPassword] = useState("");
  const [userCode, setUserCode] = useState("");
  const [msg, setMsg] = useState("");
  const [error, setError] = useState("");
  const [show, setShow] = useState(false);

  const [confirmPassword, setConfirmPassword] = React.useState("");
  let urlAPI = ConfigData.apiURL;
  const [step, setStep] = useState(0);
  const queryParams = new URLSearchParams(window.location.search);
  let hashCode = queryParams.get("Hashcode");
  const PageUrl = useLocation();
  let serviceUrl = ConfigData.serviceUrl;
  let curDT = moment().format("MM/DD/YYYY hh:mm:ssA");
  // let initialHashcode = queryParams.get('HashCodeToVerify');
  const AppId = ConfigData.appId;
  const TokenId = getTokenFromStorage();

  useEffect(() => {
    getToken();
    axios
      .get(
        urlAPI +
          "GetOSPortalUserEmailInitialVerification?AppId=" +
          AppId +
          "&TokenId=" +
          TokenId +
          "&HashCodeToVerify=" +
          hashCode
      )
      .then(function (response) {
        console.log(response.data);
        if (
          response.data.TokenInfoDetails.TokenResponse == "Expired" ||
          response.data.TokenInfoDetails.TokenResponse == "Invalid"
        ) {
          getToken();

          setTimeout(() => {
            axios.get(
              urlAPI +
                "GetOSPortalUserEmailUpdateVerification?AppId=" +
                AppId +
                "&TokenId=" +
                TokenId +
                "&Password=" +
                encodeURIComponent(password) +
                "&HashCodeToVerify=" +
                hashCode
            );
          }, 1000);
        }
        if (
          response.data.UserEmailInitialVerificationDetails.Message == "INVALID"
        ) {
          setStep(2);
          setMsg(response.data.UserEmailInitialVerificationDetails.LongMessage);
        }
      })
      .catch(function (error) {
        const data = {
          AppId: ConfigData.appId,
          TokenId: getTokenFromStorage(),
          UserId: localStorage.getItem("UserId"),
          DateTime: curDT,
          PageUrl: PageUrl.pathname,
          ExceptionDetails: error.stack,
        };
        axios
          .post(urlAPI + "PostOSPortalLoggingExceptions", data)
          .then(function (response) {
            if (response.data.TokenInfoDetails.TokenResponse == "Expired") {
              getToken();
              axios.post(urlAPI + "PostOSPortalLoggingExceptions", data);
            }
          });
      });
  }, []);

  const renewToken = async () => {
    getToken();
    handleChangePassword();
  };

  const handleChangePassword = async () => {
    //  const data = {
    //     AppId : ConfigData.appId,
    //     TokenId : getTokenFromStorage(),
    //     HashCodeToVerify : hashCode,

    //     NewPassword : password,
    // }
    console.log(password);
    console.log(confirmPassword);

    // if(password == confirmPassword){
    axios
      .get(
        urlAPI +
          "GetOSPortalUserEmailUpdateVerification?AppId=" +
          AppId +
          "&TokenId=" +
          TokenId +
          "&Password=" +
          encodeURIComponent(password) +
          "&HashCodeToVerify=" +
          hashCode
      )
      .then(function (response) {
        if (
          response.data.TokenInfoDetails.TokenResponse == "Expired" ||
          response.data.TokenInfoDetails.TokenResponse == "Invalid"
        ) {
          getToken();

          renewToken();
          setTimeout(() => {
            axios.get(
              urlAPI +
                "GetOSPortalUserEmailUpdateVerification?AppId=" +
                AppId +
                "&TokenId=" +
                TokenId +
                "&Password=" +
                encodeURIComponent(password) +
                "&HashCodeToVerify=" +
                hashCode
            );
          }, 1000);
        }
        if (
          response.data.UserEmailUpdateVerificationDetails.Message == "VALID"
        ) {
          setStep(1);
          setMsg(response.data.UserEmailUpdateVerificationDetails.LongMessage);
        } else {
          setStep(2);
          setMsg(response.data.UserEmailUpdateVerificationDetails.LongMessage);
        }
      })
      .catch(function (error) {
        const data = {
          AppId: ConfigData.appId,
          TokenId: getTokenFromStorage(),
          UserId: localStorage.getItem("UserId"),
          DateTime: curDT,
          PageUrl: PageUrl.pathname,
          ExceptionDetails: error.stack,
        };
        axios
          .post(urlAPI + "PostOSPortalLoggingExceptions", data)
          .then(function (response) {
            if (response.data.TokenInfoDetails.TokenResponse == "Expired") {
              getToken();
              axios.post(urlAPI + "PostOSPortalLoggingExceptions", data);
            }
          });
      });
    // }else{
    //   setError("Password not matched");
    //   console.log("Password not matched");
    // }
  };

  const [passwordType, setPasswordType] = useState("password");
  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text");
      return;
    }
    setPasswordType("password");
  };

  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  switch (step) {
    case 1:
      return (
        <Box className="content-center">
          <Card className="card_box">
            <CardContent sx={{ p: "30px !important" }}>
              <Box sx={{ textAlign: "center" }}>
                <a href={serviceUrl + "/Dashboard.aspx"}>
                  <img
                    alt=""
                    src="../../assets/images/member_service_portal.png"
                    className="logo-img"
                  />
                </a>
              </Box>
              <Box sx={{ mb: 2, p: 0 }}>
                <Typography
                  variant="h5"
                  sx={{
                    mb: 3,
                    fontWeight: 600,
                    letterSpacing: "0.18px",
                    fontSize: "24px !important",
                    color: "#003156",
                    textAlign: "center",
                  }}
                >
                  Email Verification
                </Typography>
              </Box>
              <Box>
                {msg.length > 0 && (
                  <div className="forgot-msg">
                    <p>
                      <span className="k-icon k-i-check k-i-checkmark"></span>
                    </p>
                    <p>{msg}</p>
                  </div>
                )}
              </Box>
              <p className="formbottom-link">
                Go back to <a href="/login">Change Login Email</a>
              </p>
            </CardContent>
          </Card>
        </Box>
      );
    case 2:
      return (
        <Box className="content-center">
          <Card className="card_box">
            <CardContent sx={{ p: "30px !important" }}>
              <Box sx={{ textAlign: "center" }}>
                <a href={serviceUrl + "/Dashboard.aspx"}>
                  <img
                    alt=""
                    src="../../assets/images/member_service_portal.png"
                    className="logo-img"
                  />
                </a>
              </Box>
              <Box sx={{ mb: 2, p: 0 }}>
                <Typography
                  variant="h5"
                  sx={{
                    mb: 3,
                    fontWeight: 600,
                    letterSpacing: "0.18px",
                    fontSize: "24px !important",
                    color: "#003156",
                    textAlign: "center",
                  }}
                >
                  Email Verification
                </Typography>
              </Box>
              <Box>
                {msg.length > 0 && (
                  <div className="forgot-msg forgot-msg-error">
                    <p>
                      <span className="k-icon k-i-warning k-i-exception k-i-error mr-10"></span>
                    </p>
                    <p>{msg}</p>
                  </div>
                )}
              </Box>
              <p className="formbottom-link">
                Go back to <a href="/login">Change Login Email</a>
              </p>
            </CardContent>
          </Card>
        </Box>
      );
    default:
      return (
        <Box className="content-center">
          <Card className="card_box">
            <CardContent sx={{ p: "30px !important" }}>
              <Box sx={{ textAlign: "center" }}>
                <a href={serviceUrl + "/Dashboard.aspx"}>
                  <img
                    alt=""
                    src="../../assets/images/member_service_portal.png"
                    className="logo-img"
                  />
                </a>
              </Box>
              <Box sx={{ mb: 2, p: 0 }}>
                <Typography
                  variant="h5"
                  sx={{
                    mb: 3,
                    fontWeight: 600,
                    letterSpacing: "0.18px",
                    fontSize: "24px !important",
                    color: "#003156",
                    textAlign: "center",
                  }}
                >
                  Email Verification
                </Typography>
              </Box>
              <Form
                onSubmit={handleChangePassword}
                render={(formRenderProps) => (
                  <FormElement>
                    <Box className="">
                      <div className="mb-3 password-field input_box">
                        <Field
                          fullWidth
                          name={"password"}
                          type={passwordVisible ? "text" : "password"}
                          placeholder="Password"
                          component={passwordInput}
                          label={"Password"}
                          //validator={pwdValidator}
                          onChange={(e) => setPassword(e.target.value)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={togglePasswordVisibility}
                                >
                                  {passwordVisible ? (
                                    <VisibilityOffIcon /> 
                                  ) : (
                                    <VisibilityIcon /> 
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}  
                        />
                        
                      </div>
                    </Box>
                    <Box sx={{ mt: 2 }}>
                      <Button
                        className="btn"
                        fullWidth
                        size="large"
                        type={"submit"}
                        variant="contained"
                        // disabled={!formRenderProps.allowSubmit}
                      >
                        Verify
                      </Button>
                    </Box>
                  </FormElement>
                )}
              />
            </CardContent>
          </Card>
        </Box>
      );
  }
}

export default EmailVerification;
