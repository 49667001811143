// Header.js
import React, { Component, useState} from 'react';
import ConfigData from "../../ConfigurationData/ConfigData.json";

// ** MUI Imports
import Box from '@mui/material/Box'
import IconButton from '@mui/material/IconButton'
import Icon from '../../@core/components/icon';

function Header(props) {
   const { toggleNavVisibility } = props

   const [show,setshow]= useState('');
   const [hide,sethide]= useState('');
    let serviceUrl = ConfigData.serviceUrl;
   
   const handleShow = () =>{ 
      setshow(current => !current);
   }
   const Logout = () => {
          localStorage.removeItem('UserId');
          localStorage.clear();
         window.open(serviceUrl +'/Logout/Logout.aspx','_self');
         
         
      }
         const handleShowLogout = () =>{ 
      sethide(current1 => !current1);
   }

   return (
      <header className='u-header header-section' id="sec-effb" data-animation-name="" data-animation-duration="0" data-animation-delay="0" data-animation-direction="">         
         <div className='container'>
            <div className='header-logo'>
               <a href='/'><img alt="" src="../../assets/images/member_service_portal.png" className="u-logo-image u-logo-image-1 header_logo" /></a>
            </div>
            {/* <div className='mobile-menu' onClick={handleShow}>
               <p><span className={show ? 'k-icon k-i-close' : 'k-icon k-i-menu '}></span></p>                                
            </div> */}
            <div className='mobile-menu'>
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
               <Box className='actions-left' sx={{ mr: 2, display: 'flex', alignItems: 'center' }}>
                  <IconButton color='inherit' sx={{ ml: -2.75 }} onClick={handleShow}>
                     <Icon icon='mdi:menu' />
                  </IconButton>
               </Box>
            </Box>
            </div>
            <div className={show ? 'header-logo-menu active' : 'header-logo-menu'}  >
               <div className='mobile-header-width'>
               <div className='mobile_logo'>
               <a href='/'><img alt="" src="../../assets/images/member_service_portal.png" className="u-logo-image u-logo-image-1 header_logo" /></a>
               </div>                    
               <ul className="">
                  <li className="u-nav-item">
                     <a className="u-button-style u-nav-link u-text-active-palette-1-base u-text-hover-palette-2-base aLinkHeaderJS2" href={serviceUrl + "/Login.aspx?user=" + localStorage.getItem("UserId") + "&ReturnUrl=Dashboard.aspx"}>HOME</a>
                  </li>
                  <li className="u-nav-item has-submenu">
                     <a className="u-button-style u-nav-link u-text-active-palette-1-base u-text-hover-palette-2-base aLinkHeaderJS2" href={serviceUrl + "/Login.aspx?user=" + localStorage.getItem("UserId") + "&ReturnUrl=Dashboard.aspx"}>SERVICES</a>
                     <input type="checkbox" name="dropdown" id="inspiration"/>
                     <label for="inspiration" className="site-nav-mobile-list-item">
                        <span className="k-icon k-i-chevron-down"></span>
                     </label>
                     <div className="u-nav-popup">
                        <ul className="u-h-spacing-20 u-nav u-unstyled u-v-spacing-10">
                           <li className="u-nav-item"><a className="u-button-style u-nav-link" href={serviceUrl + "/Login.aspx?user=" + localStorage.getItem("UserId") + "&ReturnUrl=HorseLifeRegistration/HorseSearch1.aspx"}>HORSES</a></li>
                           <li className="u-nav-item"><a className="u-button-style u-nav-link" href={serviceUrl + "/Login.aspx?user=" + localStorage.getItem("UserId") + "&ReturnUrl=Xentry/XentryEventSelection.aspx"}>ENTRIES</a></li>
                           <li className="u-nav-item"><a className="u-button-style u-nav-link" href={serviceUrl + "/Login.aspx?user=" + localStorage.getItem("UserId") + "&ReturnUrl=RenewMembership/RenewMembership.aspx"}>MEMBERSHIP</a></li>
                        </ul>
                     </div>
                  </li>
                  <li className="u-nav-item"><a className="u-button-style u-nav-link u-text-active-palette-1-base u-text-hover-palette-2-base aLinkHeaderJS2" href="https://www.useventing.com/about/about-us/directory" target="_blank">CONTACT</a></li>
               </ul>
               </div>
            </div>
            <div className='header-account'>
             <ul className="">
                  <li className="u-nav-item has-submenu">
                     <span className="login-profile u-file-icon u-icon u-icon-1 k-icon k-i-user" onClick={handleShowLogout}></span>
                     <div className="u-nav-popup desktop-user-icon logout_text" >
                        <ul className="u-h-spacing-20 u-nav u-unstyled u-v-spacing-10">
                           <li className="u-nav-item"><a className="u-button-style u-nav-link" href="javascript:void(0)"  onClick={Logout} > <Icon icon='mdi:logout-variant' />LOGOUT</a></li>
                        </ul>
                     </div>
                     <div className="u-nav-popup mobile-user-icon logout_text">
                        <ul className={hide ? 'u-h-spacing-20 u-nav u-unstyled u-v-spacing-10 active' : 'u-h-spacing-20 u-nav u-unstyled u-v-spacing-10'}>
                           <li className="u-nav-item"><a className="u-button-style u-nav-link" href="javascript:void(0)"  onClick={Logout} >  <Icon icon='mdi:logout-variant' />LOGOUT</a></li>
                        </ul>
                     </div>
                     <span className='active_btn'><span></span></span>
                  </li>
               </ul>
            </div>
         </div>
      </header>   
   )
}

export default Header