import React, { useEffect, Redirect } from "react";
import logo from "./logo.svg";
import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import MembershipRenewalSettings from "./Modules/MembershipRenewalSettings/MembershipRenewalSettings";
import MemberReceiptsList from "./Modules/MemberReceipts/MemberReceiptsList";
import MemberReceipt from "./Modules/MemberReceipts/MemberReceipt";
import Login from "./Modules/Login/Login";
import WildCard from "./Modules/Login/Wildcard";
import Logout from "./Modules/Login/Logout";
import ForgotPassword from "./Modules/Login/ForgotPassword";

import PublicLayout from "./Modules/PublicLayout/PublicLayout";
import PrivateLayout from "./Modules/PrivateLayout/PrivateLayout";
import ResetPassword from "./Modules/Login/ResetPassword";
import AccountVerification from "./Modules/Login/AccountVerification";
import AccountSettings from "./Modules/Account/accountSettings";
import EmailVerification from "./Modules/Account/EmailVerification";
import BlankLayout from "./Modules/BlankLayout/BlankLayout";
// import Horses from "./Modules/Horses/Horses";
import HorsesTable from "./Modules/Horses/HorsesTable";
import HorsesTransfer from "./Modules/Horses/HorseTransfer";
import EditProfile from "./Modules/Account/editprofile";
// import HorsesStatusList from "./Modules/Horses/HorsesStatusList";

function App() {
  const user = localStorage.getItem("UserId");

  return (
    <div className="App">
      <header className="">
        <div className="">
          <div>
            <Router>
              <Routes>
                <Route element={<BlankLayout />}>
                  <Route exact path="/login" element={<Login />}></Route>
                  <Route
                    exact
                    path="/forgot-password"
                    element={<ForgotPassword />}
                  ></Route>
                  <Route
                    exact
                    path="/change-password"
                    element={<ResetPassword />}
                  ></Route>
                  <Route
                    exact
                    path="/new-user-activation"
                    element={<AccountVerification />}
                  ></Route>
                  <Route
                    exact
                    path="/update-email-verification"
                    element={<EmailVerification />}
                  ></Route>
                </Route>

                <Route element={<PublicLayout />}>
                  {/* <Route exact path='/login' element={< Login />}></Route> */}
                  {/* <Route exact path='/forgot-password' element={< ForgotPassword />}></Route> */}
                  {/* <Route exact path='/change-password' element={< ResetPassword />}></Route> */}
                  {/* <Route exact path='/new-user-activation' element={< AccountVerification />}></Route> */}
                  {/* <Route exact path='/update-email-verification' element={< EmailVerification />}></Route> */}
                  <Route exact path="/logout" element={<Logout />}></Route>
                  <Route exact path="*" element={<WildCard />}></Route>
                </Route>

                <Route element={<PrivateLayout />}>
                  <Route exact path="/" element={<AccountSettings />}></Route>
                  <Route
                    exact
                    path="/auto-renewal-settings"
                    element={<MembershipRenewalSettings />}
                  ></Route>
                  <Route
                    exact
                    path="/receipts-list"
                    element={<MemberReceiptsList />}
                  ></Route>
                  <Route
                    exact
                    path="/receipt"
                    element={<MemberReceipt />}
                  ></Route>
                  <Route
                    exact
                    path="/account-settings"
                    element={<AccountSettings />}
                  ></Route>
                   <Route
                    exact
                    path="/edit-profile"
                    element={<EditProfile />}
                  ></Route>
                  {/* <Route exact path="/horses" element={<Horses />}></Route> */}
                  <Route
                    exact
                    path="/horse-table"
                    element={<HorsesTable />}
                  ></Route>
                  <Route
                    exact
                    path="/horse-transfer"
                    element={<HorsesTransfer />}
                  ></Route>
                  {/* <Route
                    exact
                    path="/horse-status-list"
                    element={<HorsesStatusList />}
                  ></Route> */}
                </Route>
              </Routes>
            </Router>
          </div>
        </div>
      </header>
    </div>
  );
}

export default App;
