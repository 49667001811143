import React, { Component, useState } from "react";
import axios from "axios";
import ConfigData from "../../ConfigurationData/ConfigData.json";
import { Buffer } from "buffer";

import { Button } from "@progress/kendo-react-buttons";
import { MaskedTextBox } from "@progress/kendo-react-inputs";

import classes from "./UpdateCreditCardOnFile.module.css";
import { getTokenFromStorage, getToken } from "../../api/RestServices";
import moment from "moment";
import { useLocation } from "react-router-dom";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { Link } from "@mui/material";
import { Form, Field, FormElement, Text } from "@progress/kendo-react-form";
import MuiFormControlLabel from "@mui/material/FormControlLabel";

const UpdateCreditCardOnFile = (props) => {
  let urlAPI = ConfigData.apiURL;
  let appId = ConfigData.appId;
  const PageUrl = useLocation();
  let curDT = moment().format("MM/DD/YYYY hh:mm:ssA");
  const [UserID, setUserID] = useState(props.UserID);
  const [MemberUSEAID, setMemberUSEAID] = useState(
    props.objModelMembershipDetails.MembershipDataDetails.MemberID
  );
  const [form, setForm] = useState({
    dataCardType: "",
    dataCardNo: "",
    dataCardUserName: "",
    dataCardExpirationMonth: "",
    dataCardExpirationYear: "",
    dataCardSecurityCode: "",
    dataCardZipcode: "",
    dataCardEmail: "",
  });

  const [dataCardTypeErrorMessage, setDataCardTypeErrorMessage] = useState({});
  const [dataCardUserNameErrorMessage, setDataCardUserNameErrorMessage] =
    useState({});
  const [dataCardNoErrorMessage, setDataCardNoErrorMessage] = useState({});
  const [
    dataCardExpirationMonthErrorMessage,
    setDataCardExpirationMonthErrorMessage,
  ] = useState({});
  const [
    dataCardExpirationYearErrorMessage,
    setDataCardExpirationYearErrorMessage,
  ] = useState({});
  const [
    dataCardSecurityCodeErrorMessage,
    setDataCardSecurityCodeErrorMessage,
  ] = useState({});
  const [dataCardZipcodeErrorMessage, setDataCardZipcodeErrorMessage] =
    useState({});
  const [dataCardEmailErrorMessage, setDataCardEmailErrorMessage] = useState(
    {}
  );

  const [isPaymentSuccess, setIsPaymentSuccess] = useState(false);
  const [
    dataPaymentFailureShortErrorMessage,
    setDataPaymentFailureShortErrorMessage,
  ] = useState({});
  const [
    dataPaymentFailureLongErrorMessage,
    setDataPaymentFailureLongErrorMessage,
  ] = useState({});

  const onUpdateField = (e) => {
    const nextFormState = {
      ...form,
      [e.target.name]: e.target.value,
    };
    setForm(nextFormState);
  };

  const onSubmitForm = (e) => {
    e.preventDefault();
    console.log(JSON.stringify(form, null, 2));
    const isValid = formValidation();

    if (isValid) {
      console.log("About to enter payment post api");
      callPayment()
        .then((response) => {
          console.log("Call Payment done");
          console.log(response);
          if (response) {
            if (
              response.PaymentTransactionStatus.PaymentStatus === 1 &&
              response.PaymentTransactionStatus.PaymentStatusResultCode === "A"
            ) {
              if (response.PaymentTransactionStatus.TransactionStatus === 1) {
                // set the credit card type
                let strCreditCardType = "";
                strCreditCardType = form.dataCartType;
                console.log(form.dataCardType);
                switch (form.dataCardType) {
                  case "0":
                    strCreditCardType = "VISA";
                    break;
                  case "1":
                    strCreditCardType = "MASTERCARD";
                    break;
                  case "2":
                    strCreditCardType = "AMEX";
                    break;
                  default:
                    strCreditCardType = "";
                    break;
                }
                console.log(strCreditCardType);
                // extract the last 4 digits of the credit card no
                let strCreditCardNo = "";
                strCreditCardNo = form.dataCardNo.substring(
                  form.dataCardNo.length - 4
                );
                strCreditCardNo = "#" + strCreditCardNo;
                props.objModelMembershipDetails.CreditCardDetails.CreditCardType =
                  strCreditCardType;
                props.objModelMembershipDetails.CreditCardDetails.CreditCardNo =
                  strCreditCardNo;
                props.objModelMembershipDetails.CreditCardDetails.CreditCardExpirationMonth =
                  form.dataCardExpirationMonth;
                props.objModelMembershipDetails.CreditCardDetails.CreditCardExpirationYear =
                  form.dataCardExpirationYear;

                console.log(props.objModelMembershipDetails);
                setIsPaymentSuccess(true);
                props.updateIsPaymentDone(true);
              } else {
                setIsPaymentSuccess(false);
                callPaymentErrorDetails().then((response) => {
                  console.log("Call Payment error details done");
                  console.log(response);
                  if (response) {
                    const strPaymentFailureShortMessage = {};
                    const strPaymentFailureLongMessage = {};
                    if (
                      response.PaymentErrorDetails.ShortMessage !== null &&
                      response.PaymentErrorDetails.ShortMessage !== ""
                    ) {
                      strPaymentFailureShortMessage.ErrorMessage =
                        response.PaymentErrorDetails.ShortMessage;
                    }
                    if (
                      response.PaymentErrorDetails.LongMessage !== null &&
                      response.PaymentErrorDetails.LongMessage == ""
                    ) {
                      strPaymentFailureLongMessage.ErrorMessage =
                        response.PaymentErrorDetails.LongMessage;
                    }
                    setDataPaymentFailureShortErrorMessage(
                      strPaymentFailureShortMessage
                    );
                    setDataPaymentFailureLongErrorMessage(
                      strPaymentFailureLongMessage
                    );
                  }
                });
              }
            } else {
              setIsPaymentSuccess(false);
              let strPaymentStatusErrorCode =
                response.PaymentTransactionStatus.PaymentStatusErrorCode;
              callPaymentErrorDetails(strPaymentStatusErrorCode).then(
                (response) => {
                  console.log("Call Payment error details done");
                  console.log(response);
                  if (response) {
                    const strPaymentFailureShortMessage = {};
                    const strPaymentFailureLongMessage = {};
                    if (
                      response.PaymentErrorDetails.ShortMessage !== null &&
                      response.PaymentErrorDetails.ShortMessage !== ""
                    ) {
                      strPaymentFailureShortMessage.ErrorMessage =
                        response.PaymentErrorDetails.ShortMessage;
                    }
                    if (
                      response.PaymentErrorDetails.LongMessage !== null &&
                      response.PaymentErrorDetails.LongMessage !== ""
                    ) {
                      strPaymentFailureLongMessage.ErrorMessage =
                        response.PaymentErrorDetails.LongMessage;
                    }
                    setDataPaymentFailureShortErrorMessage(
                      strPaymentFailureShortMessage
                    );
                    setDataPaymentFailureLongErrorMessage(
                      strPaymentFailureLongMessage
                    );
                  }
                }
              );
            }
          }
        })
        .catch({
          function(error) {
            const data = {
              AppId: ConfigData.appId,
              TokenId: getTokenFromStorage(),
              UserId: localStorage.getItem("UserId"),
              DateTime: curDT,
              PageUrl: PageUrl.pathname,
              ExceptionDetails: error.stack,
            };
            axios
              .post(urlAPI + "PostOSPortalLoggingExceptions", data)
              .then(function (response) {
                if (response.data.TokenInfoDetails.TokenResponse == "Expired") {
                  getToken();
                  axios.post(urlAPI + "PostOSPortalLoggingExceptions", data);
                }
              });
          },
        });
    }
  };
  const renewToken = async () => {
    getToken();
  };
  const formValidation = () => {
    const dataCardTypeErrorMessage = {};
    const dataCardUserNameErrorMessage = {};
    const dataCardNoErrorMessage = {};
    const dataCardExpirationMonthErrorMessage = {};
    const dataCardExpirationYearErrorMessage = {};
    const dataCardSecurityCodeErrorMessage = {};
    const dataCardZipcodeErrorMessage = {};
    const dataCardEmailErrorMessage = {};

    let isValid = true;

    console.log(form.dataCardType);
    // validate card type
    if (form.dataCardType === "") {
      dataCardTypeErrorMessage.ErrorMessage = "Please select Card Type";
      isValid = false;
    } else {
      if (form.dataCardType === "0") {
        if (!form.dataCardNo.startsWith("4")) {
          dataCardTypeErrorMessage.ErrorMessage = "Invalid Card Type";
          isValid = false;
        }
      } else if (form.dataCardType === "1") {
        if (!form.dataCardNo.startsWith("5")) {
          dataCardTypeErrorMessage.ErrorMessage = "Invalid Card Type";
          isValid = false;
        }
      } else if (form.dataCardType === "2") {
        if (!form.dataCardNo.startsWith("3")) {
          dataCardTypeErrorMessage.ErrorMessage = "Invalid Card Type";
          isValid = false;
        }
      }
    }

    // validate card name
    if (form.dataCardUserName === "") {
      dataCardUserNameErrorMessage.ErrorMessage = "Please check Card UserName";
      isValid = false;
    }

    console.log("test");
    console.log(form.dataCardNo < 1);
    // validate card no
    if (form.dataCardNo === "") {
      dataCardNoErrorMessage.ErrorMessage = "Please check Card No.";
      isValid = false;
    } else if (isNaN(form.dataCardNo)) {
      dataCardNoErrorMessage.ErrorMessage = "Invalid Card No.";
      isValid = false;
    }

    // validate card expiry month
    if (form.dataCardExpirationMonth === "") {
      dataCardExpirationMonthErrorMessage.ErrorMessage =
        "Please check Card Expiration Month";
      isValid = false;
    } else if (isNaN(form.dataCardExpirationMonth)) {
      dataCardExpirationMonthErrorMessage.ErrorMessage =
        "Invalid Expiration Month";
      isValid = false;
    } else if (form.dataCardExpirationMonth < 1) {
      dataCardExpirationMonthErrorMessage.ErrorMessage =
        "Invalid Expiration Month";
      isValid = false;
    } else if (form.dataCardExpirationMonth > 31) {
      dataCardExpirationMonthErrorMessage.ErrorMessage =
        "Invalid Expiration Month";
      isValid = false;
    }

    // validate card expiry year
    if (form.dataCardExpirationYear === "") {
      dataCardExpirationYearErrorMessage.ErrorMessage =
        "Please check Card Expiration Year";
      isValid = false;
    } else if (isNaN(form.dataCardExpirationYear)) {
      dataCardExpirationMonthErrorMessage.ErrorMessage =
        "Invalid Expiration Year";
      isValid = false;
    } else if (form.dataCardExpirationYear < 1) {
      dataCardExpirationMonthErrorMessage.ErrorMessage =
        "Invalid Expiration Year";
      isValid = false;
    }

    // validate card security code
    if (form.dataCardSecurityCode === "") {
      dataCardSecurityCodeErrorMessage.ErrorMessage =
        "Pelase check Card Security code";
      isValid = false;
    }

    // validate card expiry year
    if (form.dataCardZipcode === "") {
      dataCardZipcodeErrorMessage.ErrorMessage = "Please check Card Zip code";
      isValid = false;
    }

    if (form.dataCardEmail === "") {
      dataCardEmailErrorMessage.ErrorMessage = "Please check Card user Email";
      isValid = false;
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(form.dataCardEmail)
    ) {
      dataCardEmailErrorMessage.ErrorMessage = "Invalid Email address";
      isValid = false;
    }

    if (isValid) {
      console.log("Authenticated", form);
    } else {
      console.log("errors try again");
    }

    setDataCardTypeErrorMessage(dataCardTypeErrorMessage);
    setDataCardUserNameErrorMessage(dataCardUserNameErrorMessage);
    setDataCardNoErrorMessage(dataCardNoErrorMessage);
    setDataCardExpirationMonthErrorMessage(dataCardExpirationMonthErrorMessage);
    setDataCardExpirationYearErrorMessage(dataCardExpirationYearErrorMessage);
    setDataCardSecurityCodeErrorMessage(dataCardSecurityCodeErrorMessage);
    setDataCardZipcodeErrorMessage(dataCardZipcodeErrorMessage);
    setDataCardEmailErrorMessage(dataCardEmailErrorMessage);

    return isValid;
  };

  const getDataIPAddress = async () => {
    return true;
  };

  const callPayment = async () => {
    console.log("entered payment call");
    let strDataCardType = "";
    const res = await axios.get("https://geolocation-db.com/json/");
    console.log(res.data);
    let IPAddress = res.data.IPv4.toString();

    if (res.data !== null) {
      console.log("IP Address done");
      console.log(IPAddress);
      switch (form.dataCardType) {
        case "0":
          strDataCardType = "VISA";
          break;
        case "1":
          strDataCardType = "MASTERCARD";
          break;
        case "2":
          strDataCardType = "AMEX";
          break;
        default:
          strDataCardType = "";
          break;
      }

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          AppId: appId,
          TokenId: localStorage.getItem("TokenId"),
          strPaymentAction: "Auto-Renewal Membership Settings",
          dAmount: 0.02,
          strCardType: strDataCardType,
          strNameOnCard: form.dataCardUserName,
          strCreditCardNumber: form.dataCardNo,
          strCardExpiryMonth: form.dataCardExpirationMonth,
          strCardExpiryYear: form.dataCardExpirationYear,
          strCardCvv: form.dataCardSecurityCode,
          strCurrencyCode: "USD",
          strZip: form.dataCardZipcode,
          strEmail: form.dataCardEmail,
          //strUserId: decodedUserId,
          strUserId: UserID,
          strIdentification: MemberUSEAID,
          strUserHostAddress: "",
          strClientIP: IPAddress,
          strStaffUserId: "",
        }),
      };

      console.log(requestOptions);
      let postPaymentUpdateURLAPI = urlAPI + "OSPortalPaymentUpdateDetailsPost";
      const response = await fetch(postPaymentUpdateURLAPI, requestOptions);
      const data = await response.json();
      if (data.TokenInfoDetails.TokenResponse == "Expired") {
        renewToken();
      }
      console.log("Post payment call");
      console.log(response);
      if (!response.ok) {
        throw new Error("Data cound not be fetched!");
      } else {
        console.log(response);
        return data;
      }
    }
  };

  const callPaymentErrorDetails = async (strPaymentStatusErrorCode) => {
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };

    let getPaymentErrorDetailsUrlAPI =
      urlAPI +
      "GetOSPortalPaymentErrorDetails?AppId=" +
      appId +
      "&TokenId=" +
      getTokenFromStorage() +
      "&strErrorCode=" +
      strPaymentStatusErrorCode;
    const response = await fetch(getPaymentErrorDetailsUrlAPI, requestOptions);
    const data = await response.json();
    if (data.TokenInfoDetails.TokenResponse == "Expired") {
      renewToken();
    }
    if (!response.ok) {
      throw new Error("Data Payment error details cound not be fetched!");
    } else {
      console.log(response);
      return data;
    }
  };

  function onCloseCardDetailsClick(event) {
    const strPaymentFailureShortMessage = {};
    const strPaymentFailureLongMessage = {};

    props.updateShowCardDetailsPopup(!props.isShowCardDetailsPopup);
    setDataPaymentFailureShortErrorMessage(strPaymentFailureShortMessage);
    setDataPaymentFailureLongErrorMessage(strPaymentFailureLongMessage);

    setIsPaymentSuccess(false);
  }

  return (
    <div className={classes.divFieldSetDetails}>
      {props.isShowCardDetailsPopup ? (
        <fieldset className={classes.fieldsetUpdateCardDetailsData}>
          <div className={classes.divUpdateCardDetails}>
            <CardHeader
              className="payment_title"
              title="Update Payment"
              sx={{ textAlign: "center", p: "0 !important" }}
            />
          </div>
          <div>
            <div className={classes.divCardDetails}>
              <span className={classes.spanCardDetails}>
                <i class="fa fa-cc-visa fa-3x" aria-hidden="true"></i>
              </span>
              <span className={classes.spanCardDetails}>
                <i class="fa fa-cc-mastercard fa-3x" aria-hidden="true"></i>
              </span>
              <span className={classes.spanCardDetails}>
                <i class="fa fa-cc-amex fa-3x" aria-hidden="true"></i>
              </span>
            </div>
            {Object.keys(dataPaymentFailureShortErrorMessage).map((key) => {
              return (
                <div className={classes.lblFailureErrorMessages}>
                  {dataPaymentFailureShortErrorMessage[key]}
                  <br />
                </div>
              );
            })}
            {Object.keys(dataPaymentFailureLongErrorMessage).map((key) => {
              return (
                <div className={classes.lblFailureErrorMessages}>
                  {dataPaymentFailureLongErrorMessage[key]}
                  <br />
                  <br />
                </div>
              );
            })}
            <Typography variant="body2" className="payment_text">
              Payment Amount
            </Typography>
            <div className={classes.divPaymentText}>
              <div className={classes.divPaymentTextLeft}>
                <Typography className="PaymentTextLeft">$0.02</Typography>
              </div>
              <div className={classes.divPaymentTextRight}>
                <Typography className="PaymentTextLeft">
                  use to validate the card and immediately refunded.
                </Typography>
              </div>
            </div>
            <br />
            {isPaymentSuccess ? (
              <div>
                <span className="PaymentTextLeft">Card details updated.</span>
              </div>
            ) : (
              <div>
                <div>
                  <div className={classes.divCardDataDetails}>
                    <Typography
                      variant="body2"
                      className="payment_text"
                      sx={{ mb: "10px !important" }}
                    >
                      Card Type
                    </Typography>
                    <label className="payment_icon">
                      <input
                        type="radio"
                        class="hide"
                        name="dataCardType"
                        value="0"
                        onChange={onUpdateField}
                      />
                      &nbsp;
                      <i class="fa fa-cc-visa fa-2x" aria-hidden="true"></i>
                    </label>
                    <label className="payment_icon">
                      <input
                        type="radio"
                        class="hide"
                        name="dataCardType"
                        value="1"
                        onChange={onUpdateField}
                      />
                      &nbsp;
                      <i
                        class="fa fa-cc-mastercard fa-2x"
                        aria-hidden="true"
                      ></i>
                    </label>
                    <label className="payment_icon">
                      <input
                        type="radio"
                        class="hide"
                        name="dataCardType"
                        value="2"
                        onChange={onUpdateField}
                      />
                      &nbsp;
                      <i class="fa fa-cc-amex fa-2x" aria-hidden="true"></i>
                    </label>
                    {Object.keys(dataCardTypeErrorMessage).map((key) => {
                      return (
                        <div className={classes.lblErrors}>
                          {dataCardTypeErrorMessage[key]}
                        </div>
                      );
                    })}
                  </div>
                  <div className={classes.divCardDataDetails}>
                    <span className={classes.lblCardDetailsPayment}>
                      Name on Card
                    </span>
                    <input
                      type="text"
                      className={classes.txtboxDetails}
                      name="dataCardUserName"
                      value={
                        props.objModelMembershipDetails.CreditCardDetails
                          .CreditCardName
                      }
                      onChange={onUpdateField}
                    />
                    {Object.keys(dataCardUserNameErrorMessage).map((key) => {
                      return (
                        <div className={classes.lblErrors}>
                          {dataCardUserNameErrorMessage[key]}
                        </div>
                      );
                    })}
                  </div>
                  <div className={classes.divCardDataDetails}>
                    <span className={classes.lblCardDetailsPayment}>
                      Card Number
                    </span>
                    <input
                      type="text"
                      className={classes.txtboxDetails}
                      name="dataCardNo"
                      onChange={onUpdateField}
                      maxLength="16"
                    />
                    {Object.keys(dataCardNoErrorMessage).map((key) => {
                      return (
                        <div className={classes.lblErrors}>
                          {dataCardNoErrorMessage[key]}
                        </div>
                      );
                    })}
                  </div>
                  <div className={classes.divCardDataDetails}>
                    <div className={classes.divExpirationText}>
                      <div className={classes.divExpirationTextLeft}>
                        <span className={classes.lblCardDetailsPayment}>
                          Expiration Date (MM/YYYY)
                        </span>
                      </div>
                      <div className={classes.divExpirationTextRight}>
                        <span className={classes.lblCardDetailsPayment}>
                          Security Code
                        </span>
                      </div>
                    </div>
                    <div className={classes.divExpirationText}>
                      <div className={classes.divExpirationTextLeft}>
                        {/*                                                         <input type="text"
                                                            className={classes.txtboxExpirationDetails}
                                                            name='dataCardExpirationMonth'
                                                            onChange={onUpdateField}
                                                            maxLength="2"
                                                            max="31" /> */}
                        <MaskedTextBox
                          className={classes.txtboxExpirationDetails}
                          name="dataCardExpirationMonth"
                          onChange={onUpdateField}
                          mask="##"
                          maxLength="2"
                          max="31"
                          defaultValue="##"
                        />
                        <span> / </span>
                        <MaskedTextBox
                          className={classes.txtboxExpirationDetails}
                          name="dataCardExpirationYear"
                          onChange={onUpdateField}
                          maxLength="4"
                          mask="####"
                          defaultValue="####"
                        />
                      </div>
                      <div className={classes.divExpirationTextRight}>
                        <MaskedTextBox
                          className={classes.txtboxSecurityCodeDetails}
                          name="dataCardSecurityCode"
                          onChange={onUpdateField}
                          minLength="3" 
                          maxLength="4"
                          mask="####"
                          defaultValue="####"
                        />
                      </div>
                    </div>
                    {Object.keys(dataCardExpirationMonthErrorMessage).map(
                      (key) => {
                        return (
                          <div className={classes.lblErrors}>
                            {dataCardExpirationMonthErrorMessage[key]}
                          </div>
                        );
                      }
                    )}
                    {Object.keys(dataCardExpirationYearErrorMessage).map(
                      (key) => {
                        return (
                          <div className={classes.lblErrors}>
                            {dataCardExpirationYearErrorMessage[key]}
                          </div>
                        );
                      }
                    )}
                    {Object.keys(dataCardSecurityCodeErrorMessage).map(
                      (key) => {
                        return (
                          <div className={classes.lblErrors}>
                            {dataCardSecurityCodeErrorMessage[key]}
                          </div>
                        );
                      }
                    )}
                  </div>
                  <div className={classes.divCardDataDetails}>
                    <span className={classes.lblCardDetailsPayment}>
                      Zip/Postal Code
                    </span>
                    <input
                      type="text"
                      className={classes.txtboxDetails}
                      name="dataCardZipcode"
                      onChange={onUpdateField}
                    />
                    {Object.keys(dataCardZipcodeErrorMessage).map((key) => {
                      return (
                        <div className={classes.lblErrors}>
                          {dataCardZipcodeErrorMessage[key]}
                        </div>
                      );
                    })}
                  </div>
                  <div className={classes.divCardDataDetails}>
                    <span className={classes.lblCardDetailsPayment}>Email</span>
                    <br />
                    <input
                      type="text"
                      className={classes.txtboxDetails}
                      name="dataCardEmail"
                      onChange={onUpdateField}
                    />
                    {Object.keys(dataCardEmailErrorMessage).map((key) => {
                      return (
                        <div className={classes.lblErrors}>
                          {dataCardEmailErrorMessage[key]}
                        </div>
                      );
                    })}
                  </div>
                  <div className={classes.divButtonGroup}>
                    <Button themeColor={"primary"} onClick={onSubmitForm}>
                      Update Card
                    </Button>
                  </div>
                </div>
              </div>
            )}
            <div className={classes.divButtonGroup}>
              <a
                href="#"
                className={classes.aCloseLink}
                onClick={onCloseCardDetailsClick}
              >
                Close
              </a>
            </div>
          </div>
        </fieldset>
      ) : (
        <br />
      )}
    </div>
  );
};

export default UpdateCreditCardOnFile;
