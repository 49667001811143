import React, { useState, useEffect } from 'react';
import ConfigData from "../../ConfigurationData/ConfigData.json";

import MembershipSettings from '../MembershipSettings/MembershipSettings';
import { Buffer } from 'buffer';
import classes from "./MembershipRenewalSettings.module.css";
import { useNavigate } from 'react-router-dom';
import { checkCircleIcon, getToken, getTokenFromStorage, getUserId } from '../../api/RestServices';
import axios from 'axios';
import moment from 'moment';
import { useLocation } from "react-router-dom";
   
const MembershipRenewalSettings = (props) => {
    let urlAPI = ConfigData.apiURL;
    let appId = ConfigData.appId;
    const PageUrl = useLocation();
    let curDT = moment().format("MM/DD/YYYY hh:mm:ssA");
    const [objModelMembershipDetails, setObjModelMembershipDetails] = useState(null);
    const [UserID, setUserID] = useState(null);
    let [TokenResponse, setTokenResponse]=useState(null);
    let [Token, setToken]=useState(null);

    const queryParams = new URLSearchParams(window.location.search);
    
     let userId = '';

    // localStorage.setItem('userID',userId)

    // const callGetMembershipRenewalSettings = async () => {
    //     const requestOptions = {
    //         method: 'GET',
    //         headers: { 'Content-Type': 'application/json' },
    //     };
    //     setUserID(userId);
    //     let getMembershipSettingsURLAPI = urlAPI + 'GETOSPortalMembershipSettingsDetails?AppId=' + appId + '&TokenId=' + getTokenFromStorage() + '&UserId=' + userId;
    //     let response = await fetch(getMembershipSettingsURLAPI, requestOptions);
    //     let  data = await response.json();
    //     setTokenResponse(data.TokenInfoDetails.TokenResponse);

    
          
    //     if (!response.ok) { throw new Error("Renewal Data cound not be fetched!"); }
    //     else { 
    //         return data;
    //     }
    // };

    const renewToken = async () => {
          getToken();
        callGetMembershipRenewalSettings1();
    };
    useEffect(() => {
        if(queryParams.get('UserId')){
            localStorage.setItem('UserId',queryParams.get('UserId'))

        }
     
     userId = localStorage.getItem('UserId');
        getToken();
      
         setTimeout(() => {
              callGetMembershipRenewalSettings1();
        }, 1000);
      callGetMembershipRenewalSettings1();
    }, []);
        const callGetMembershipRenewalSettings1 = ()=>{

            
           
            axios.get(urlAPI + 'GETOSPortalMembershipSettingsDetails?AppId=' + appId + '&TokenId=' + localStorage.getItem('TokenId') + '&UserId=' + userId).then(function(response){
                   setUserID(userId);
              
                if(response.data.TokenInfoDetails.TokenResponse === 'Expired'){
                    renewToken(); 
            
                    setObjModelMembershipDetails(response.data.MembershipSettingsDetails);
                }
                setObjModelMembershipDetails(response.data.MembershipSettingsDetails);

            }).catch(function (error) {
                console.log(error);
                const data = {
                    AppId : ConfigData.appId,
                    TokenId : getTokenFromStorage(),
                    UserId:localStorage.getItem('UserId'),
                    DateTime:curDT,
                    PageUrl:PageUrl.pathname,
                    ExceptionDetails:error.message
                }
                axios.post(urlAPI + 'PostOSPortalLoggingExceptions', data).then(function(response){
                  if(response.data.TokenInfoDetails.TokenResponse=='Expired' ){ getToken();
                    axios.post(urlAPI + 'PostOSPortalLoggingExceptions', data)}
                });
        });
}

    return (
        <section className="u-clearfix u-section-1" id="sec-a169">
            <div className="u-container-style u-expanded-width-xs u-group u-group-1">
                <div className="u-container-layout u-container-layout-1">
                    <div className={classes.container} >
                        {
                            
                            (objModelMembershipDetails != null) ?
                                <MembershipSettings
                                    UserID={UserID}
                                    objModelMembershipDetails={objModelMembershipDetails}
                                   
                                />
                                :
                                <div> 
                                    <br /><br />
                                </div>
                        }
                    </div>
                </div>
            </div>
        </section >
    );
}

export default MembershipRenewalSettings;