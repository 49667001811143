import React,{useEffect} from 'react';
import { Outlet } from 'react-router-dom';
import { useNavigate, useLocation ,createSearchParams} from 'react-router-dom';

import Header from '../MasterFiles/Header';
import ConfigData from "../../ConfigurationData/ConfigData.json";
import { getUserId ,getUserIdAfterLogin} from '../../api/RestServices';


const BlankLayout = () => {

    const user = getUserIdAfterLogin();
    const user1 = getUserId();
    const navigate = useNavigate();
    const prevLocation = useLocation();
    let serviceUrl = ConfigData.serviceUrl;
    const useNavigateSearch = () => {
  return (pathname, params) =>
    navigate({ pathname, search: `?${createSearchParams(params)}` });
};
    const queryParams = new URLSearchParams(window.location.search);

  const navigateSearch = useNavigateSearch();
        console.log(prevLocation);
    useEffect(() => {
       
         if (prevLocation.pathname == '/*'){
            window.open(serviceUrl +'/Login.aspx?user='+localStorage.getItem('UserId'),'_self');

        }else{
            return;
        }
       
          
         
    },[]);

  return (
    <section className="bg-section"> 
      <Outlet />
    </section>
  )
}
export default BlankLayout