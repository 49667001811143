import React, { useEffect, useRef, useState } from "react";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import { Input, Checkbox } from "@progress/kendo-react-inputs";
import { useLocation } from "react-router-dom";
import ConfigData from "../../ConfigurationData/ConfigData.json";
import axios from "axios";
import {
  getToken,
  get,
  getLoginEmail,
  getLoginWithPassword,
  getUserId,
  getTokenFromStorage,
} from "../../api/RestServices";
import moment from "moment";

// ** MUI Components
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import { styled } from "@mui/material/styles";
import MuiCard from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { TextField } from "@mui/material";

const Card = styled(MuiCard)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: { width: 550 },
}));

const emailRegex = new RegExp(/\S+@\S+\.\S+/);
const emailValidator = (value) =>
  emailRegex.test(value) ? "" : "Please enter a valid email.";
const rememberMeValidator = (value) =>
  value ? "" : "It's required to agree with Terms and Conditions.";
const EmailInput = (fieldRenderProps) => {
  const { value, ...others } = fieldRenderProps;

  return (
    <div>
      <TextField value={value} {...others} />
    </div>
  );
};
const usernameInput = (fieldRenderProps) => {
  const { validationMessage, visited, ...others } = fieldRenderProps;
  return (
    <div>
      <TextField {...others} />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};

function ForgotPassword() {
  const location = useLocation();
  const step1 = location.state.step;
  const getemail = location.state.email;
  const getusername = location.state.username;
  const [step, setStep] = useState(step1);
  let urlAPI = ConfigData.apiURL;
  let appId = ConfigData.appId;
  let serviceUrl = ConfigData.serviceUrl;

  const [username, setUsername] = useState();
  const [email, setEmail] = useState("");
  const [msg, setMsg] = useState("");
  const PageUrl = useLocation();
  let curDT = moment().format("MM/DD/YYYY hh:mm:ssA");
  useEffect(() => {
    setEmail(getemail);
    setUsername(getusername);
  }, []);
  const handleonchange = () => {
    setEmail(getemail);
  };

  const handleSendResetLink = () => {
    setStep(3);
    setMsg(
      "If Account exist for " +
        email +
        ", an email with a reset password link will be sent."
    );
  };
  const handleClick = () => {
    let resetUrl = "";
    if (username) {
      resetUrl =
        urlAPI +
        "GetOSPortalResetPassCode?AppId=" +
        appId +
        "&TokenId=" +
        localStorage.getItem("TokenId") +
        "&UserName=" +
        username +
        "&Email=" +
        email;
    } else {
      resetUrl =
        urlAPI +
        "GetOSPortalResetPassCode?AppId=" +
        appId +
        "&TokenId=" +
        localStorage.getItem("TokenId") +
        "&Email=" +
        email;
    }
    axios
      .get(resetUrl)
      .then(function (response) {
        if (
          response.data.TokenInfoDetails.TokenResponse == "Expired" ||
          response.data.TokenInfoDetails.TokenResponse == "Invalid"
        ) {
          getToken();
          console.log("Token");
          setTimeout(() => {
            axios.get(resetUrl);
          }, 1000);
        }
        if (
          response.data.ResetPassCodeDetails.Message == "VALID" &&
          response.data.ResetPassCodeDetails.LongMessage == "VALID"
        ) {
          setStep(3);
          setMsg(
            "If an Account exists for " +
              email +
              ", an email with a reset password link will be sent."
          );
        } else {
          setStep(4);
          setMsg(
            "We are sorry. This is an invalid email/username combination. Please try again and if you continue to have problems, please contact the USEA office at 703-779-0440 for assistance."
          );
        }
      })
      .catch(function (error) {
        const data = {
          AppId: ConfigData.appId,
          TokenId: getTokenFromStorage(),
          UserId: localStorage.getItem("UserId"),
          DateTime: curDT,
          PageUrl: PageUrl.pathname,
          ExceptionDetails: error.stack,
        };
        axios
          .post(urlAPI + "PostOSPortalLoggingExceptions", data)
          .then(function (response) {
            if (response.data.TokenInfoDetails.TokenResponse == "Expired") {
              getToken();
              axios.post(urlAPI + "PostOSPortalLoggingExceptions", data);
            }
          });
      });
  };
  //  const handleLoginPage1  = () =>{
  //    navigate("/login");
  //   setStep(0);
  // }

  switch (step) {
    case 1:
      return (
        <Box className="content-center">
          <Card sx={{ zIndex: 1 }} className="box-shadow">
            <CardContent sx={{ p: "30px !important" }}>
              <Box sx={{ textAlign: "center" }}>
                <a href={serviceUrl + "/Dashboard.aspx"}>
                  <img
                    alt=""
                    src="../../assets/images/member_service_portal.png"
                    className="logo-img"
                  />
                </a>
              </Box>
              <Box sx={{ mb: 2, mt: 2, p: 0 }}>
                <Typography
                  variant="h5"
                  sx={{
                    mb: 1,
                    fontWeight: 600,
                    letterSpacing: "0.18px",
                    fontSize: "24px !important",
                    color: "#003156",
                    textAlign: "center",
                  }}
                >
                  Forgot Password Link
                </Typography>
                <Typography
                  variant="h5"
                  sx={{
                    mb: 1,
                    fontWeight: 600,
                    letterSpacing: "0.18px",
                    fontSize: "19px !important",
                    color: "#000",
                    textAlign: "center",
                  }}
                >
                  Forgot Your Password?
                </Typography>

                {msg.length > 0 && (
                  <Box sx={{ mb: 2 }}>
                    <div className="forgot-msg">
                      <p>
                        <span className="k-icon k-i-check k-i-checkmark"></span>
                      </p>
                      <p>{msg}</p>
                    </div>
                  </Box>
                )}

                <Typography
                  variant="h5"
                  sx={{
                    mb: 3,
                    fontWeight: 600,
                    letterSpacing: "0.18px",
                    fontSize: "16px !important",
                    color: "#000",
                    textAlign: "center",
                  }}
                >
                  We will send you a link to reset your password to the below
                  email.
                </Typography>
              </Box>
              <Form
                initialValues={{
                  email: getemail,
                }}
                onSubmit={handleSendResetLink}
                onSubmitClick={handleClick}
                render={(formRenderProps) => (
                  <FormElement>
                    <Field
                      fullWidth
                      name={"email"}
                      type={"email"}
                      component={EmailInput}
                      label={"Email"}
                      onChange={handleonchange}
                      value={{ email }}
                      disabled
                    />
                    <Box sx={{ mt: 2 }}>
                      <Button
                        className="btn"
                        fullWidth
                        size="large"
                        type={"submit"}
                        variant="contained"
                      >
                        Send Reset Link
                      </Button>
                    </Box>
                    <p className="usea_service_login">
                      Go back to <a href="/login">Change Login Email</a>
                    </p>
                  </FormElement>
                )}
              />
            </CardContent>
          </Card>
        </Box>
      );
    case 2:
      return (
        <Box className="content-center">
          <Card sx={{ zIndex: 1 }} className="box-shadow">
            <CardContent sx={{ p: "30px !important" }}>
              <Box sx={{ textAlign: "center" }}>
                <a href={serviceUrl + "/Dashboard.aspx"}>
                  <img
                    alt=""
                    src="../../assets/images/member_service_portal.png"
                    className="logo-img"
                  />
                </a>
              </Box>
              <Box sx={{ mb: 2, mt: 2, p: 0 }}>
                <Typography
                  variant="h5"
                  sx={{
                    mb: 1,
                    fontWeight: 600,
                    letterSpacing: "0.18px",
                    fontSize: "24px !important",
                    color: "#003156",
                    textAlign: "center",
                  }}
                >
                  Forgot Password Link
                </Typography>
                <Typography
                  variant="h5"
                  sx={{
                    mb: 1,
                    fontWeight: 600,
                    letterSpacing: "0.18px",
                    fontSize: "19px !important",
                    color: "#000",
                    textAlign: "center",
                  }}
                >
                  Forgot Your Password?
                </Typography>

                {msg.length > 0 && (
                  <Box sx={{ mb: 2 }}>
                    <div className="forgot-msg">
                      <p>
                        <span className="k-icon k-i-check k-i-checkmark"></span>
                      </p>
                      <p>{msg}</p>
                    </div>
                  </Box>
                )}

                <Typography
                  variant="h5"
                  sx={{
                    mb: 3,
                    fontWeight: 600,
                    letterSpacing: "0.18px",
                    fontSize: "16px !important",
                    color: "#000",
                    textAlign: "center",
                  }}
                >
                  We will send you a link to reset your password to the below
                  username and email.
                </Typography>
              </Box>
              <Form
                onSubmit={handleSendResetLink}
                onSubmitClick={handleClick}
                initialValues={{
                  email: getemail,
                  username: getusername,
                }}
                render={(formRenderProps) => (
                  <FormElement>
                    <Box sx={{ mt: 2 }}>
                      <Field
                        fullWidth
                        name={"email"}
                        type={"email"}
                        component={EmailInput}
                        label={"Email"}
                        onChange={handleonchange}
                        value={{ email }}
                        disabled
                      />
                    </Box>
                    <Box sx={{ mt: 2 }}>
                      <Field
                        fullWidth
                        name={"username"}
                        type={"text"}
                        component={usernameInput}
                        label={"Username"}
                        value={{ username }}
                        disabled
                      />
                    </Box>
                    <Box sx={{ mt: 2 }}>
                      <Button
                        className="btn"
                        fullWidth
                        size="large"
                        type={"submit"}
                        variant="contained"
                      >
                        Send Reset Link
                      </Button>
                    </Box>
                    <p className="usea_service_login">
                      Go back to <a href="/login">Change Login Email</a>
                    </p>
                  </FormElement>
                )}
              />
            </CardContent>
          </Card>
        </Box>
      );
    case 3:
      return (
        <Box className="content-center">
          <Card sx={{ zIndex: 1 }} className="box-shadow">
            <CardContent sx={{ p: "30px !important" }}>
              <Box sx={{ textAlign: "center" }}>
                <a href={serviceUrl + "/Dashboard.aspx"}>
                  <img
                    alt=""
                    src="../../assets/images/member_service_portal.png"
                    className="logo-img"
                  />
                </a>
              </Box>
              <Box sx={{ mb: 2, mt: 2, p: 0 }}>
                <Typography
                  variant="h5"
                  sx={{
                    mb: 1,
                    fontWeight: 600,
                    letterSpacing: "0.18px",
                    fontSize: "24px !important",
                    color: "#003156",
                    textAlign: "center",
                  }}
                >
                  Forgot Password Sent
                </Typography>
                <Typography
                  variant="h5"
                  sx={{
                    mb: 1,
                    fontWeight: 600,
                    letterSpacing: "0.18px",
                    fontSize: "19px !important",
                    color: "#000",
                    textAlign: "center",
                  }}
                >
                  Forgot Your Password?
                </Typography>

                {msg.length > 0 && (
                  <Box sx={{ mb: 2 }}>
                    <div className="forgot-msg">
                      <p>
                        <span className="k-icon k-i-check k-i-checkmark"></span>
                      </p>
                      <p>{msg}</p>
                    </div>
                  </Box>
                )}
              </Box>
              <p className="usea_service_login">
                Go back to <a href="/login">Change Login Email</a>
              </p>
            </CardContent>
          </Card>
        </Box>
      );
    case 4:
      return (
        <Box className="content-center">
          <Card sx={{ zIndex: 1 }} className="box-shadow">
            <CardContent sx={{ p: "30px !important" }}>
              <Box sx={{ textAlign: "center" }}>
                <a href={serviceUrl + "/Dashboard.aspx"}>
                  <img
                    alt=""
                    src="../../assets/images/member_service_portal.png"
                    className="logo-img"
                  />
                </a>
              </Box>
              <Box sx={{ mb: 2, mt: 2, p: 0 }}>
                {msg.length > 0 && (
                  <Box sx={{ mb: 2 }}>
                    <div className="forgot-msg forgot-msg-error">
                      <p>
                        <span className="k-icon k-i-warning k-i-exception k-i-error mr-10"></span>
                      </p>
                      <p>{msg}</p>
                    </div>
                  </Box>
                )}
              </Box>
              <p className="usea_service_login">
                Go to <a href="/login">USEA Online service login</a>
              </p>
            </CardContent>
          </Card>
        </Box>
      );
  }
}
export default ForgotPassword;
