import React,{useEffect} from 'react';
import { Outlet } from 'react-router-dom';
import { useNavigate, useLocation ,createSearchParams} from 'react-router-dom';

import Header from '../MasterFiles/Header';
import ConfigData from "../../ConfigurationData/ConfigData.json";
import { getUserId ,getUserIdAfterLogin} from '../../api/RestServices';


const Wildcard = () => {

    let serviceUrl = ConfigData.serviceUrl;
    
    useEffect(() => {
            window.open(serviceUrl +'/Login.aspx?ReturnUrl=Dashboard.aspx','_self');
    },[]);

  return (
   <></>
  )
}
export default Wildcard