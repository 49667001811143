import { useEffect } from "react";
import { useNavigate, Outlet, useLocation ,createSearchParams} from 'react-router-dom';
import { getUserId ,getUserIdAfterLogin} from '../../api/RestServices';
import Header from '../MasterFiles/Header';
import ConfigData from "../../ConfigurationData/ConfigData.json";

const PrivateLayout = () => {

    const user = getUserIdAfterLogin();
    const user1 = getUserId();
    const navigate = useNavigate();
    const prevLocation = useLocation();
    let serviceUrl = ConfigData.serviceUrl;
    const useNavigateSearch = () => {
  return (pathname, params) =>
    navigate({ pathname, search: `?${createSearchParams(params)}` });
};
const queryParams = new URLSearchParams(window.location.search);

  const navigateSearch = useNavigateSearch();
        console.log(prevLocation);
    useEffect(() => {
        if(queryParams.get('UserId')){
            localStorage.setItem('UserId',queryParams.get('UserId'))
            
        }else{
        if(!user || user == "null" ){
            navigate(`/login?redirectTo=${prevLocation.pathname}`);

        }else if (prevLocation.pathname == '/'){
            // window.open(serviceUrl +'/Login.aspx?user='+localStorage.getItem('UserId'),'_self');

        }else{
            return;
        }}
       
          
         
    },[]);

    return (
        <section className="u-clearfix u-section-1">
        <Header />
        <Outlet />
        </section>
    )
}
export default PrivateLayout