import { useState, useEffect, useCallback } from "react";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Error } from "@progress/kendo-react-labels";
import {
  Input,
  Checkbox,
  InputSuffix,
  TextBox,
} from "@progress/kendo-react-inputs";
import ForgotPassword from "./ForgotPassword";
// import { Button } from "@progress/kendo-react-buttons";
import { Icon } from "@progress/kendo-react-common";
import { useNavigate, useLocation, createSearchParams } from "react-router-dom";
import ConfigData from "../../ConfigurationData/ConfigData.json";
import queryString from "query-string";
import moment from "moment";

import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import { styled } from "@mui/material/styles";
import MuiCard from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import IconButton from '@mui/material/IconButton';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import InputAdornment from '@mui/material/InputAdornment';

import {
  getToken,
  get,
  getLoginEmail,
  getLoginWithPassword,
  getUserId,
  getUserIdAfterLogin,
  getTokenFromStorage,
} from "../../api/RestServices";

import axios from "axios";


//const pwdRegex = new RegExp(/^[ A-Za-z0-9_@./#&+-]{10,}$/);
const pwdRegex = new RegExp(/^[ A-Za-z0-9@/%^$`{}[]|\:;<>?=#+]{10,}$/);
const emailRegex = new RegExp(/\S+@\S+\.\S+/);

const emailValidator = (value) =>
  emailRegex.test(value) ? "" : "Please enter a valid email.";
const pwdValidator = (value) =>
  pwdRegex.test(value) ? "" : "Password must be at least 10 characters. The first character must be a letter or number.";
const rememberMeValidator = (value) => (value ? "" : "");
const EmailInput = (fieldRenderProps) => {
  const { validationMessage, visited, ...others } = fieldRenderProps;
  return (
    <div>
      <TextField {...others} />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};

const usernameInput = (fieldRenderProps) => {
  const { validationMessage, visited, ...others } = fieldRenderProps;
  return (
    <div>
      <TextField {...others} />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};
const passwordInput = (fieldRenderProps) => {
  const { validationMessage, visited, ...others } = fieldRenderProps;
  return (
    <div>
      <TextField {...others} />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};
const Card = styled(MuiCard)(({ theme }) => ({
  [theme.breakpoints.up("sm")]: { width: 450 },
}));
const FormCheckbox = (fieldRenderProps) => {
  const { validationMessage, visited, ...others } = fieldRenderProps;

  return (
    <div className="remeberme">
      <Checkbox {...others} />
      {visited && validationMessage && <Error>{validationMessage}</Error>}
    </div>
  );
};
const Login = () => {
  const [step, setStep] = useState(0);
  const [username, setUsername] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [msgEmail, setMsgEmail] = useState("");
  const [msgUsername, setMsgUsername] = useState("");
  const PageUrl = useLocation();
  let curDT = moment().format("MM/DD/YYYY hh:mm:ssA");
  const navigate = useNavigate();
  let urlAPI = ConfigData.apiURL;
  let appId = ConfigData.appId;
  let serviceUrl = ConfigData.serviceUrl;

  useEffect(() => {
    getToken();

    getLoginEmail("GETOSPortalLoginEmailTest?AppId=", email).then(function (
      response
    ) {
      if (response.data.TokenInfoDetails.TokenResponse == "Expired") {
        getToken();
      }
    });
  }, []);

  const handleSubmit = () => {
    getLoginEmail("GETOSPortalLoginEmailTest?AppId=", email)
      .then(function (response) {
        console.log("Login details");
        console.log(response);
        localStorage.setItem(
          "TokenResponse",
          response.data.TokenInfoDetails.TokenResponse
        );
        if (response.data.TokenInfoDetails.TokenResponse == "Expired") {
          getToken();
        }

        if (
          response.data.UserLoginEmailDetails.AccountStatus == 1 &&
          response.data.UserLoginEmailDetails.ActiveStatus == 1
        ) {
          if (response.data.UserLoginEmailDetails.UniqueStatus == 1) {
            setStep(1);
          } else {
            setStep(7);
          }
        } else {
          setStep(5);
        }
      })
      .catch(function (error) {
        const data = {
          AppId: ConfigData.appId,
          TokenId: getTokenFromStorage(),
          UserId: localStorage.getItem("UserId"),
          DateTime: curDT,
          PageUrl: PageUrl.pathname,
          ExceptionDetails: error.stack,
        };
        axios
          .post(urlAPI + "PostOSPortalLoggingExceptions", data)
          .then(function (response) {
            if (response.data.TokenInfoDetails.TokenResponse == "Expired") {
              getToken();
              axios.post(urlAPI + "PostOSPortalLoggingExceptions", data);
            }
          });
      });
  };
  const location = useLocation();
  const useNavigateSearch = () => {
    const navigate = useNavigate();
    return (pathname, params) =>
      navigate({ pathname, search: `?${createSearchParams(params)}` });
  };
  const navigateSearch = useNavigateSearch();
  const handleLogin = () => {
    getLoginWithPassword(
      "GETOSPortalLoginCredentialsTest?AppId=",
      username,
      email,
      encodeURIComponent(password)
    )
      .then(function (response) {
        console.log("API Response");
        console.log(response);
        if (response.data.TokenInfoDetails.TokenResponse == "Expired") {
          getToken();
        }
        localStorage.setItem(
          "TokenResponse",
          response.data.TokenInfoDetails.TokenResponse
        );

        localStorage.setItem(
          "UserId",
          response.data.UserLoginCredentialsDetails.UserId
        );

        let user = getUserIdAfterLogin();
        console.log(user);

        if (user) {
          localStorage.setItem("username", email);
          const { redirectTo } = queryString.parse(location.search);
          console.log(redirectTo);
          if (redirectTo == null || !redirectTo) {
            //  window.open(serviceUrl + '/Login.aspx?user=' + user, '_self');
            navigate("/account-settings", {
              state: { step: 1 },
            });
          } else {
            if (redirectTo == "/") {
              navigate("/account-settings", {
                state: { step: 1 },
              });
              // window.open(serviceUrl +'/Login.aspx?user='+localStorage.getItem('UserId'),'_self');
            } else {
              navigateSearch(redirectTo);
            }
          }
        } else {
          setMsgUsername("Username or Password is incorrect.");
        }
      })
      .catch(function (error) {
        const data = {
          AppId: ConfigData.appId,
          TokenId: getTokenFromStorage(),
          UserId: localStorage.getItem("UserId"),
          DateTime: curDT,
          PageUrl: PageUrl.pathname,
          ExceptionDetails: error.stack,
        };
        axios
          .post(urlAPI + "PostOSPortalLoggingExceptions", data)
          .then(function (response) {
            if (response.data.TokenInfoDetails.TokenResponse == "Expired") {
              getToken();
              axios.post(urlAPI + "PostOSPortalLoggingExceptions", data);
            }
          });
      });
  };

  const handleLoginwithUsername = () => {
    getLoginWithPassword(
      "GETOSPortalLoginCredentialsTest?AppId=",
      username,
      email,
      encodeURIComponent(password)
    )
      .then(function (response) {
        localStorage.setItem(
          "UserId",
          response.data.UserLoginCredentialsDetails.UserId
        );
        localStorage.setItem(
          "TokenResponse",
          response.data.TokenInfoDetails.TokenResponse
        );
        if (response.data.TokenInfoDetails.TokenResponse == "Expired") {
          getToken();
        }
        let user = getUserIdAfterLogin();

        if (user) {
          const { redirectTo } = queryString.parse(location.search);

          if (redirectTo == null || !redirectTo) {
            // window.open(serviceUrl +'/Login.aspx?user='+localStorage.getItem('UserId'),'_self');
            navigate("/account-settings", {
              state: { step: 0, password: password },
            });
          } else {
            if (redirectTo == "/") {
              navigate("/account-settings", {
                state: { step: 1, password: password },
              });
              // window.open(serviceUrl +'/Login.aspx?user='+localStorage.getItem('UserId'),'_self');
            } else {
              navigateSearch(redirectTo);
            }
          }
        } else {
          setMsgUsername("Username or Password is incorrect.");
        }
      })
      .catch(function (error) {
        const data = {
          AppId: ConfigData.appId,
          TokenId: getTokenFromStorage(),
          UserId: localStorage.getItem("UserId"),
          DateTime: curDT,
          PageUrl: PageUrl.pathname,
          ExceptionDetails: error.stack,
        };
        axios
          .post(urlAPI + "PostOSPortalLoggingExceptions", data)
          .then(function (response) {
            if (response.data.TokenInfoDetails.TokenResponse == "Expired") {
              getToken();
              axios.post(urlAPI + "PostOSPortalLoggingExceptions", data);
            }
          });
      });
  };

  const [passwordVisible, setPasswordVisible] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleCreateLogin = () => {
    const data = {
      AppId: ConfigData.appId,
      TokenId: getTokenFromStorage(),
      EmailId: email,
      Password: encodeURIComponent(password),
    };

    if (password.length > 9) {
      axios
        .post(urlAPI + "PostOSPortalUserActivationLink", data)
        .then(function (response) {
          if (
            response.data.TokenInfoDetails.TokenResponse == "Expired" ||
            response.data.TokenInfoDetails.TokenResponse == "Invalid"
          ) {
            getToken();
            setTimeout(() => {
              axios.post(urlAPI + "PostOSPortalUserActivationLink", data);
            }, 1000);
          }
          if (
            response.data.UserActivationLinkDetails.Message == "VALID" &&
            response.data.UserActivationLinkDetails.LongMessage == "VALID"
          ) {
            setStep(3);
          }
        })
        .catch(function (error) {
          const data = {
            AppId: ConfigData.appId,
            TokenId: getTokenFromStorage(),
            UserId: localStorage.getItem("UserId"),
            DateTime: curDT,
            PageUrl: PageUrl.pathname,
            ExceptionDetails: error.stack,
          };
          axios
            .post(urlAPI + "PostOSPortalLoggingExceptions", data)
            .then(function (response) {
              if (response.data.TokenInfoDetails.TokenResponse == "Expired") {
                getToken();
                axios.post(urlAPI + "PostOSPortalLoggingExceptions", data);
              }
            });
        });
    }
  };

  const handleLoginPage = () => {
    // navigate('/login',state: { step: 1,email:email,username:username});
    setStep(0);
    // window.open(serviceUrl+'/Login.aspx','_self');
  };
  const handleLoginPage1 = () => {
    //  navigate("/login");
    setStep(0);
  };

  const clickForgot = () => {
    navigate("/forgot-password", {
      state: { step: 1, email: email },
    });
  };
  const clickUserForgot = () => {
    navigate("/forgot-password", {
      state: { step: 2, email: email, username: username },
    });
  };

  const goBackLink = () => {
    setStep(0);
  }
  switch (step) {
    case 1:
      return (
        <Box className="content-center">
          <Card sx={{ zIndex: 1 }} className="card_box">
            <CardContent sx={{ p: "30px !important" }}>
              <Box sx={{ textAlign: "center" }}>
                <a href={serviceUrl + "/Dashboard.aspx"}>
                  <img
                    alt=""
                    src="../../assets/images/member_service_portal.png"
                    className="logo-img"
                  />
                </a>
              </Box>
              <Box sx={{ mb: 2, p: 0 }}>
                <Typography
                  variant="h5"
                  sx={{
                    mb: 3,
                    fontWeight: 600,
                    letterSpacing: "0.18px",
                    fontSize: "19px !important",
                    color: "#003156",
                    textAlign: "center",
                  }}
                >
                  Login to access USEA Member Services
                </Typography>
              </Box>
              {msgUsername.length <= 0 && (<p className="verified" align="center"><b>Welcome Back! Please enter your credentials.</b></p>)}
              <Box sx={{ mb: "20px !important" }}>
                {msgUsername.length > 0 && (
                  <div className="error-msg">
                    <p>
                      <span className="k-icon k-i-warning k-i-exception k-i-error mr-10"></span>
                    </p>
                    <p>
                      {msgUsername}{" "}
                      <a href="javascript:void(0)" onClick={clickForgot}>
                        Forgot Password
                      </a>
                    </p>
                  </div>
                )}
              </Box>
              <Form
                initialValues={{
                  email: email,
                }}
                onSubmit={handleLogin}
                render={(formRenderProps) => (
                  <FormElement>
                    <Box className="input_box">
                      <Field
                        fullWidth
                        name={"email"}
                        type={"email"}
                        component={EmailInput}
                        label={"Email"}
                        placeholder="email"
                        validator={emailValidator}
                        disabled
                      />
                    </Box>
                    <FormControl fullWidth>
                      <Box sx={{ mt: 2 }} className="input_box">
                        <Field
                          fullWidth
                          name={"password"}
                          type={passwordVisible ? "text" : "password"}
                          placeholder="Password"
                          component={passwordInput}
                          label={"Password"}
                          value={{ password }}
                          validator={pwdValidator}
                          onChange={(e) => { setPassword(e.target.value) }}

                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={togglePasswordVisibility}
                                >
                                  {passwordVisible ? (
                                    <VisibilityOffIcon />
                                  ) : (
                                    <VisibilityIcon />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />

                      </Box>
                    </FormControl>
                    <Box sx={{ mt: 2 }}>
                      <Button
                        className="btn"
                        fullWidth
                        size="large"
                        type={"submit"}
                        variant="contained"
                        endIcon={
                          <Icon icon="mdi:chevron-right" fontSize={20} />
                        }
                      // disabled={!formRenderProps.allowSubmit}
                      >
                        Submit
                      </Button>
                    </Box>
                    <p className="usea_service_login">
                      Go back to <a href="javascript:void(0)" onClick={handleLoginPage1}>Change Login Email</a>
                    </p>
                  </FormElement>
                )}
              />
            </CardContent>
          </Card>
        </Box>
      );
    case 2:
      return <div>Logged In Successfully !</div>;
    case 3:
      return (
        <Box className="content-center">
          <Card sx={{ zIndex: 1 }} className="card_box">
            <CardContent sx={{ p: "30px !important" }}>
              <Typography
                variant="h5"
                sx={{
                  mb: 3,
                  fontWeight: 600,
                  letterSpacing: "0.18px",
                  fontSize: "22px !important",
                  color: "#003156",
                  textAlign: "center",
                }}
              >
                Create Login to access USEA Member Services Portal
              </Typography>
              <Box className="forgot-msg">
                <p>
                  <span class="k-icon k-i-check k-i-checkmark"></span>
                </p>
                <p>
                  Welcome to our platform! If you're a new user, check your
                  email for a verification link to complete registration and
                  access our services. Please verify within 24 hours before the
                  link expires. Thank You!
                </p>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexWrap: "wrap",
                  justifyContent: "center",
                  mt: 2,
                }}
              >
                <Typography
                  sx={{ my: "0 !important", mr: 2, color: "text.secondary" }}
                >
                  Go back to
                </Typography>
                <Typography
                  href="javascript:void(0)"
                  component={Link}
                  onClick={handleLoginPage}
                  sx={{ color: "#004890", textDecoration: "none" }}
                >
                  Change Login Email
                </Typography>
              </Box>
            </CardContent>
          </Card>
        </Box>
      );
    case 4:
      return (
        <Box className="content-center">
          <Card sx={{ zIndex: 1 }} className="card_box">
            <CardContent sx={{ p: "30px !important" }}>
              <Box sx={{ textAlign: "center" }}>
                <a href={serviceUrl + "/Dashboard.aspx"}>
                  <img
                    alt=""
                    src="../../assets/images/member_service_portal.png"
                    className="logo-img"
                  />
                </a>
              </Box>
              <Box sx={{ mb: 2, mt: 1, p: 0 }}>
                <Typography
                  variant="h5"
                  sx={{
                    mb: 3,
                    fontWeight: 600,
                    letterSpacing: "0.18px",
                    fontSize: "22px !important",
                    color: "#003156",
                    textAlign: "center",
                  }}
                >
                  Go back to Change Login Email
                </Typography>
              </Box>
              <Box sx={{ mb: 2 }}>
                {msgUsername.length > 0 && (
                  <div className="error-msg">
                    <p>
                      <span className="k-icon k-i-warning k-i-exception k-i-error mr-10"></span>
                    </p>
                    <p>
                      {msgUsername}{" "}
                      <a href="javascript:void(0)" onClick={clickForgot}>
                        Forgot Password
                      </a>
                    </p>
                  </div>
                )}
              </Box>
              <Form
                onSubmit={handleLogin}
                render={(formRenderProps) => (
                  <FormElement>
                    <Box className="input_box">
                      <Field
                        fullWidth
                        name={"email"}
                        type={"email"}
                        component={EmailInput}
                        label={"Email"}
                        placeholder="email"
                        validator={emailValidator}
                        onChange={(e) => setEmail(e.target.value)}
                        value={{ email }}
                        disabled
                      />
                    </Box>
                    <FormControl fullWidth>
                      <Box className="input_box">
                        <Field
                          fullWidth
                          name={"password"}
                          type={passwordVisible ? "text" : "password"}
                          placeholder="Password"
                          component={passwordInput}
                          label={"Password"}
                          value={{ password }}
                          onChange={(e) => setPassword(e.target.value)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={togglePasswordVisibility}
                                >
                                  {passwordVisible ? (
                                    <VisibilityOffIcon />
                                  ) : (
                                    <VisibilityIcon />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                    </FormControl>
                    <Box sx={{ mt: 2 }}>
                      <Button
                        className="btn"
                        fullWidth
                        size="large"
                        type={"submit"}
                        variant="contained"
                        endIcon={
                          <Icon icon="mdi:chevron-right" fontSize={20} />
                        }
                      // disabled={!formRenderProps.allowSubmit}
                      >
                        Submit
                      </Button>
                    </Box>
                  </FormElement>
                )}
              />
            </CardContent>
          </Card>
        </Box>
      );
    case 5:
      return (
        <Box className="content-center">
          <Card sx={{ zIndex: 1 }} className="card_box">
            <CardContent sx={{ p: "30px !important" }}>
              <Box sx={{ textAlign: "center" }}>
                <a href={serviceUrl + "/Dashboard.aspx"}>
                  <img
                    alt=""
                    src="../../assets/images/member_service_portal.png"
                    className="logo-img"
                  />
                </a>
              </Box>
              <Box sx={{ mb: 2, p: 0 }}>
                <Typography
                  variant="h5"
                  sx={{
                    mb: 3,
                    fontWeight: 600,
                    letterSpacing: "0.18px",
                    fontSize: "22px !important",
                    color: "#003156",
                    textAlign: "center",
                  }}
                >
                  Create Login to access USEA Online
                </Typography>
              </Box>
              <Form
                onSubmitClick={handleCreateLogin}
                render={(formRenderProps) => (
                  <FormElement>
                    <p className="unverified">
                      We don’t have an account with this email yet.To make a new account, please type a new password. If you already have an account with a different email, <a href="javascript:void(0)" onClick={goBackLink} color="blue" class="clickHere">click here</a> to go back.
                    </p>
                    <Box className="input_box" sx={{ mb: 2 }}>
                      <Field
                        fullWidth
                        name={"email"}
                        type={"email"}
                        component={EmailInput}
                        placeholder="email"
                        label={"Email"}
                        // validator={emailValidator}
                        value={{ email }}
                        disabled
                      // onChange={e=>setEmail(e.target.value)}
                      />
                    </Box>
                    <FormControl fullWidth>
                      <Box className="input_box">
                        <Field
                          fullWidth
                          name={"password"}
                          type={passwordVisible ? "text" : "password"}
                          placeholder="Password"
                          component={passwordInput}
                          label={"Password"}
                          value={password}
                          validator={pwdValidator}
                          onChange={(e) => setPassword(e.target.value)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={togglePasswordVisibility}
                                >
                                  {passwordVisible ? (
                                    <VisibilityOffIcon />
                                  ) : (
                                    <VisibilityIcon />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>
                      <p>Use 10 or more characters</p>
                    </FormControl>
                    <Box sx={{ mt: 2 }}>
                      <Button
                        className="btn"
                        fullWidth
                        size="large"
                        type={"submit"}
                        variant="contained"
                        endIcon={
                          <Icon icon="mdi:chevron-right" fontSize={20} />
                        }
                      // disabled={!formRenderProps.allowSubmit}
                      >
                        Create Login
                      </Button>
                    </Box>
                    {/* <p className="usea_service_login">
                      Go to{" "}
                      <a href="javascript:void(0)" onClick={handleLoginPage1}>
                        USEA Online Services Login
                      </a>
                    </p> */}
                  </FormElement>
                )}
              />
            </CardContent>
          </Card>
        </Box>
      );

    case 6:
      return <div></div>;
    case 7:
      return (
        <Box className="content-center">
          <Card sx={{ zIndex: 1 }} className="card_box">
            <CardContent sx={{ p: "30px !important" }}>
              <Box sx={{ textAlign: "center" }}>
                <a href={serviceUrl + "/Dashboard.aspx"}>
                  <img
                    alt=""
                    src="../../assets/images/member_service_portal.png"
                    className="logo-img"
                  />
                </a>
              </Box>
              <Box sx={{ mb: 2, p: 0 }}>
                <Typography
                  variant="h5"
                  sx={{
                    mb: 3,
                    fontWeight: 600,
                    letterSpacing: "0.18px",
                    fontSize: "22px !important",
                    color: "#003156",
                    textAlign: "center",
                  }}
                >
                  Login to access USEA Member Services
                </Typography>
              </Box>
              <Box sx={{ mb: 2 }}>
                {msgUsername.length > 0 && (
                  <div className="error-msg">
                    <p>
                      <span className="k-icon k-i-warning k-i-exception k-i-error mr-10"></span>
                    </p>
                    <p>
                      {msgUsername}{" "}
                      <a href="javascript:void(0)" onClick={clickUserForgot}>
                        Forgot Password
                      </a>
                    </p>
                  </div>
                )}
              </Box>
              <Form
                initialValues={{
                  email: email,
                }}
                onSubmit={handleLoginwithUsername}
                render={(formRenderProps) => (
                  <FormElement>
                    {msgUsername.length <= 0 && (<p className="verified" align="center"><b>Welcome Back! Please enter your credentials.</b></p>)}
                    <Box className="input_box">
                      <Field
                        fullWidth
                        name={"email"}
                        type={"email"}
                        component={EmailInput}
                        placeholder="email"
                        label={"Email"}
                        validator={emailValidator}
                        value={{ email }}
                        disabled
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Box>
                    <Box sx={{ mt: 2 }} className="input_box">
                      <Field
                        fullWidth
                        name={"username"}
                        type={"text"}
                        component={usernameInput}
                        placeholder="username"
                        label={"Username"}
                        value={{ username }}
                        onChange={(e) => setUsername(e.target.value)}
                      />
                    </Box>
                    <FormControl fullWidth sx={{ mt: 2 }}>
                      <Box className="input_box">
                        <Field
                          fullWidth
                          name={"password"}
                          type={passwordVisible ? "text" : "password"}
                          placeholder="Password"
                          component={passwordInput}
                          label={"Password"}
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={togglePasswordVisibility}
                                >
                                  {passwordVisible ? (
                                    <VisibilityOffIcon />
                                  ) : (
                                    <VisibilityIcon />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </Box>

                    </FormControl>
                    <Box sx={{ mt: 2 }}>
                      <Button
                        className="btn"
                        fullWidth
                        size="large"
                        type={"submit"}
                        variant="contained"
                        endIcon={
                          <Icon icon="mdi:chevron-right" fontSize={20} />
                        }
                      // disabled={!formRenderProps.allowSubmit}
                      >
                        Login
                      </Button>
                    </Box>
                    <p className="usea_service_login">
                      Go back to <a href="javascript:void(0)" onClick={handleLoginPage1}>Change Login Email</a>
                    </p>
                  </FormElement>
                )}
              />
            </CardContent>
          </Card>
        </Box>
      );

    default:
      return (
        <Box className="content-center">
          <Card sx={{ zIndex: 1 }} className="card_box">
            <CardContent sx={{ p: "30px !important" }}>
              <Box sx={{ textAlign: "center" }}>
                <a href={serviceUrl + "/Dashboard.aspx"}>
                  <img
                    alt=""
                    src="../../assets/images/member_service_portal.png"
                    className="logo-img"
                  />
                </a>
              </Box>
              <Box>
                <Typography
                  variant="h5"
                  sx={{
                    mb: 3,
                    fontWeight: 600,
                    letterSpacing: "0.18px",
                    fontSize: "19px !important",
                    color: "#003156",
                    textAlign: "center",
                  }}
                >
                  Login to access USEA Member Services
                </Typography>
              </Box>
              <Form
                onSubmit={handleSubmit}
                render={(formRenderProps) => (
                  <FormElement>
                    <Box className="input_box">
                      <Field
                        fullWidth
                        name={"email"}
                        type={"email"}
                        component={EmailInput}
                        placeholder="email"
                        label={"Email"}
                        validator={emailValidator}
                        value={{ email }}
                        onChange={(e) => setEmail(e.target.value)}
                      />
                    </Box>
                    <Box sx={{ mt: 2 }}>
                      <Button
                        type={"submit"}
                        className="btn"
                        fullWidth
                        size="large"
                        variant="contained"
                        endIcon={
                          <Icon icon="mdi:chevron-right" fontSize={20} />
                        }
                      // disabled={!formRenderProps.allowSubmit}
                      >
                        Next
                      </Button>
                    </Box>
                  </FormElement>
                )}
              />
            </CardContent>
          </Card>
        </Box>

      );
  }
};

export default Login;
