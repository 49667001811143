import React, { Component, useState, useEffect } from 'react';
import ConfigData from "../../ConfigurationData/ConfigData.json";
import { useNavigate } from 'react-router-dom';
import { Buffer } from 'buffer';
import axios from 'axios';

import classes from "./MemberReceiptsList.module.css";
import { getTokenFromStorage, getToken } from '../../api/RestServices';
import moment from 'moment';
import { useLocation } from "react-router-dom";

// ** MUI Imports
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'
import Box from '@mui/material/Box'
const MemberReceiptsList = (props) => {
    let urlAPI = ConfigData.apiURL;
    let appId = ConfigData.appId;
    // const paymentID = localStorage.getItem('paymentID');

    const queryParams = new URLSearchParams(window.location.search);
    if(queryParams.get('UserId')){
        localStorage.setItem('UserId',queryParams.get('UserId'))

    }
    // let strUserId = queryParams.get('UserId');
    

    const strUserId = localStorage.getItem('UserId');

    const [objModelMemberReceiptListDetails, setObjModelMemberReceiptListDetails] = useState(null);
    const [UserID, setUserID] = useState(null);

    const navigate = useNavigate();
    const PageUrl = useLocation();
    let curDT = moment().format("MM/DD/YYYY hh:mm:ssA");
    const callGetMemberReceiptListDetails = async () => {
        setUserID(strUserId);
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };
        console.log('irst');

        let getMemberReceiptListDetailsURLAPI = urlAPI + 'GETOSPortalMemberReceiptDataDetails?AppId=' + appId + '&TokenId=' + localStorage.getItem('TokenId') + '&UserId=' + strUserId;
        console.log(getMemberReceiptListDetailsURLAPI);

        const response = await fetch(getMemberReceiptListDetailsURLAPI, requestOptions);
        const data = await response.json();

        if (data.TokenInfoDetails.TokenResponse === 'Expired') {
            renewToken();
            setObjModelMemberReceiptListDetails(data.MemberReceiptListDetails);
        }
        if (!response.ok) {
            throw new Error("Member Receipt List Details Data cound not be fetched!");
        }
        else {
              setObjModelMemberReceiptListDetails(data.MemberReceiptListDetails);
            return data;
            
        }
    };
    const renewToken = async () => {
        getToken();
     
        callGetMemberReceiptListDetails();
    };
    useEffect(() => {
        if(queryParams.get('UserId')){
            localStorage.setItem('UserId',queryParams.get('UserId'))
    
        }
        console.log("entered");
         getToken();
         if(localStorage.getItem('TokenId') == undefined){
                setTimeout(() => {
                    callGetMemberReceiptListDetails();
                }, 500);
        }
        callGetMemberReceiptListDetails()
            .then((response) => {
                console.log("Get call Member Receipt List Details");

                if (response) {
                    setObjModelMemberReceiptListDetails(response.MemberReceiptListDetails);
                }
            }).catch(
                function (error) {
                const data = {
                    AppId : ConfigData.appId,
                    TokenId : getTokenFromStorage(),
                    UserId:localStorage.getItem('UserId'),
                    DateTime:curDT,
                    PageUrl:PageUrl.pathname,
                    ExceptionDetails:error.stack
                }
                axios.post(urlAPI + 'PostOSPortalLoggingExceptions', data).then(function(response){
                  if(response.data.TokenInfoDetails.TokenResponse=='Expired' ){ getToken();
                    axios.post(urlAPI + 'PostOSPortalLoggingExceptions', data)}
                });}
            );

    }, []);

    function onReceiptDetailsClick(event, PaymentID) {
        event.preventDefault();
        // console.log("On receipt click");
        // console.log(UserID);
        // console.log(PaymentID);

        //let decodedUserId = Buffer.from(strUserId, 'base64').toString();
        //let decodedUserId = strUserId;
        //sessionStorage.setItem("UserID", UserID);
        //sessionStorage.setItem("DecodedUserID", decodedUserId);
        localStorage.setItem("UserId", UserID);
        localStorage.setItem("PaymentID", PaymentID);

        navigate("/receipt");
    }

    function onManageMyAccountClick(event) {
        event.preventDefault();
        //let encoedUserId = sessionStorage.getItem("UserID");
        let encoedUserId = localStorage.getItem("UserId");
        navigate("/auto-renewal-settings?UserId=" + encoedUserId);
    }

    return (
        <Box sx={{backgroundColor: '#F7F7F9',p:'25px 0'}}>
            <Box sx={{maxWidth:'750px',margin:'0 auto'}} className="account-form">
                <Card className="card_box">
                    {
                    (objModelMemberReceiptListDetails != null
                        && objModelMemberReceiptListDetails.Message
                        && objModelMemberReceiptListDetails.Message != null
                        && objModelMemberReceiptListDetails.Message == "Valid") ?
                        <div>
                            <div>
                                <CardContent>
                                    <Grid container spacing={5}>
                                        <Grid item xs={12} sm={12}>
                                            <div className={classes.divTransactionsMain}>
                                                <h2>Your Payment History</h2>
                                                <a
                                                    href=""
                                                    className={classes.aLink}
                                                    onClick={(e) => onManageMyAccountClick(e)}>
                                                    Manage My Account
                                                </a>
                                                <br /><br />
                                            </div>
                                            <div>
                                                <div className={classes.UserTitle}>
                                                    Customer
                                                </div>
                                                <div>
                                                    <div>
                                                        <span className={classes.spanDetails}>{objModelMemberReceiptListDetails.UserDetails.FullName}</span>
                                                        <span className={classes.spanDetails}> (USEA ID {objModelMemberReceiptListDetails.UserDetails.MemberID})</span>
                                                    </div>
                                                    <div>
                                                        <span className={classes.spanDetails}>{objModelMemberReceiptListDetails.UserDetails.Address}</span>
                                                        <span className={classes.spanDetails}>, </span>
                                                        <span className={classes.spanDetails}>{objModelMemberReceiptListDetails.UserDetails.City}</span>
                                                        <span className={classes.spanDetails}>, </span>
                                                        <span className={classes.spanDetails}>{objModelMemberReceiptListDetails.UserDetails.State}</span>
                                                        <span className={classes.spanDetails}>, </span>
                                                        <span className={classes.spanDetails}>{objModelMemberReceiptListDetails.UserDetails.Zip}</span>
                                                        <span className={classes.spanDetails}>, </span>
                                                        <span className={classes.spanDetails}>{objModelMemberReceiptListDetails.UserDetails.Country}</span>
                                                    </div>
                                                    <div>
                                                        <span className={classes.spanDetails}>{objModelMemberReceiptListDetails.UserDetails.EmailAddress}</span>
                                                    </div>
                                                    <div>
                                                        <span className={classes.spanDetails}>{objModelMemberReceiptListDetails.UserDetails.Phone}</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <div className='recipts-list'>
                                    {(objModelMemberReceiptListDetails.PaymentDetails
                                        && objModelMemberReceiptListDetails.PaymentDetails != null
                                        && Object.keys(objModelMemberReceiptListDetails.PaymentDetails).length > 0) ?
                                        objModelMemberReceiptListDetails.PaymentDetails.map(function (object, i) {
                                            return (
                                                <div key={i}>
                                                    <Divider sx={{ m: ' 0  !important' }} />
                                                    
                                                    <CardContent sx={{p:'10px 20px !important'}}>
                                                            <Grid item xs={12} sm={12}>
                                                                <div className={classes.divReceiptTransactions}>
                                                                    <div className={classes.divReceiptTransactionsLeft}>
                                                                        <div className={classes.divReceiptTransactions}>
                                                                            <span className={classes.spanPaymentData}>{object.PaymentDate}</span>
                                                                            <span className={classes.spanPaymentDataDetails}>{object.PaymentDetail}</span>
                                                                        </div>
                                                                    </div>
                                                                    <div className={classes.divReceiptTransactionsRight}>
                                                                        <div className={classes.divReceiptTransactions}>
                                                                            <span className={classes.spanPaymentData}>${object.PaymentAmount}</span>
                                                                            <a
                                                                                href=""
                                                                                onClick={(e) => onReceiptDetailsClick(e, object.PaymentID)}>
                                                                                <i class="fa fa-share-square fa-2x" aria-hidden="true"></i>
                                                                            </a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </Grid>
                                                    </CardContent>
                                                </div>
                                            )
                                        })
                                        :
                                        (objModelMemberReceiptListDetails.PaymentDetails
                                            && objModelMemberReceiptListDetails.PaymentDetails != null
                                            && Object.key(objModelMemberReceiptListDetails.PaymentDetails).length == 0) ?
                                        
                                                <Box sx={{maxWidth:'750px',margin:'0 auto'}} className="account-form">
                                                    <Card className="card_box">
                                                        <CardContent>
                                                        <Grid container spacing={5}>
                                                            <Grid item xs={12} sm={6}>
                                                                <span className={classes.spanDetails}>No Payment data.</span>
                                                            </Grid>
                                                        </Grid>
                                                        </CardContent>
                                                        
                                                    </Card>
                                                </Box>
                                            :
                                            <Box sx={{maxWidth:'750px',margin:'0 auto'}} className="account-form">
                                                    <Card className="card_box">
                                                        <CardContent>
                                                        <Grid container spacing={5}>
                                                            <Grid item xs={12} sm={6}>
                                                                <span className={classes.spanDetails}>No Payment data.</span>
                                                            </Grid>
                                                        </Grid>
                                                        </CardContent>
                                                        
                                                    </Card>
                                            </Box>
                                    }
                                    <Divider sx={{ m: ' 0  !important' }} />
                                </div>
                            </div>
                        </div >
                        :
                        <Box sx={{maxWidth:'750px',margin:'0 auto'}} className="account-form">
                                <Card className="card_box">
                                    <CardContent>
                                    <Grid container spacing={5}>
                                        <Grid item xs={12} sm={6}>
                                            <span className={classes.spanDetails}>Invalid input data.</span>
                                        </Grid>
                                    </Grid>
                                    </CardContent>
                                    
                                </Card>
                        </Box>
                        }
                </Card>
            </Box>
        </Box>
    );
}

export default MemberReceiptsList;