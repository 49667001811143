import React, { Component, useState, useEffect } from 'react';
import axios from 'axios';

import html2canvas from 'html2canvas';
import { jsPDF } from 'jspdf';
import { useNavigate } from 'react-router-dom';

import ConfigData from "../../ConfigurationData/ConfigData.json";

import classes from "./MemberReceipt.module.css";
import { getTokenFromStorage, getUserId, getToken } from '../../api/RestServices';
import moment from 'moment';
import { useLocation } from "react-router-dom";

// ** MUI Imports
import Card from '@mui/material/Card'
import Grid from '@mui/material/Grid'
import Divider from '@mui/material/Divider'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'
import Box from '@mui/material/Box'

const MemberReceipt = () => {
    let urlAPI = ConfigData.apiURL;

    const PageUrl = useLocation();
  let curDT = moment().format("MM/DD/YYYY hh:mm:ssA");

    const printRef = React.useRef();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(window.location.search);
    //let strUserId  = queryParams.get('UserId');
    //let PaymentID= queryParams.get('PaymentId');
    
    let strUserId  = localStorage.getItem('UserId');
    let PaymentID= localStorage.getItem('PaymentID');

    const [objModelMemberReceiptDetails, setObjModelMemberReceiptDetails] = useState(null);
    let [TokenResponse, setTokenResponse] = useState(null);
    const exportPDFReceipt = async () => {
        /* working */
        const element = printRef.current;
        const canvas = await html2canvas(element);
        const data = canvas.toDataURL('image/png');

        const pdf = new jsPDF("p", "pt", "A4");
        const pdfWidth = pdf.internal.pageSize.width - 40;
        const pdfHeight = pdf.internal.pageSize.height;

        pdf.addImage(data, 'PNG', 20, 20, pdfWidth, pdfHeight);
        pdf.save('Receipt - ' + PaymentID + '.pdf');
        console.log("PDF Details");
        /* working */

        /* alternate working */
        // var HTML_Width = printRef.current.offsetWidth;
        // var HTML_Height = printRef.current.offsetHeight;
        // console.log(HTML_Width + " HTML " + HTML_Height);

        // var top_left_margin = 15;

        // var PDF_Width = HTML_Width + (top_left_margin * 2);
        // var PDF_Height = (PDF_Width * 1.5) + (top_left_margin * 2);
        // console.log(PDF_Width + " PDF " + PDF_Height);

        // var canvas_image_width = HTML_Width;
        // var canvas_image_height = HTML_Height;
        // console.log(canvas_image_width + " canvas_image " + canvas_image_height);

        // var totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1;
        // console.log(totalPDFPages);

        // var imgData = canvas.toDataURL("image/jpeg", 1.0);
        // var pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height]);
        // pdf.addImage(imgData, 'JPG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height);

        // for (var i = 1; i <= totalPDFPages; i++) {
        //     pdf.addPage(PDF_Width, PDF_Height);
        //     pdf.addImage(imgData, 'JPG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), canvas_image_width, canvas_image_height);
        // }

        // pdf.save('Receipt - ' + PaymentID + '.pdf');
        /* alternate working */
    };

    const callGetMemberReceiptDetails = async () => {
        const requestOptions = {
            method: 'GET',
            headers: { 'Content-Type': 'application/json' },
        };

        let appId = ConfigData.appId;

        let getMemberReceiptDetailsURLAPI = urlAPI + 'GETOSPortalMemberReceiptDetails?AppId=' + appId + '&TokenId=' + getTokenFromStorage() + '&UserId=' + strUserId + '&PaymentId=' + PaymentID;
        console.log(getMemberReceiptDetailsURLAPI);
        const response = await fetch(getMemberReceiptDetailsURLAPI, requestOptions);
        const data = await response.json();
        if (data.TokenInfoDetails.TokenResponse === 'Expired') {
            renewToken();
            setObjModelMemberReceiptDetails(data.MemberReceiptDetails);
        }
        if (!response.ok) {
            throw new Error("Member Receipt Details Data cound not be fetched!");
        }
        else {
            setObjModelMemberReceiptDetails(data.MemberReceiptDetails);

            return data;
        }
    };
    const renewToken = async () => {
        getToken();
        callGetMemberReceiptDetails();
    };
    useEffect(() => {
        console.log("entered");
            
         if(localStorage.getItem('TokenId') == undefined){
            getToken();
                setTimeout(() => {
                    callGetMemberReceiptDetails();
                },1000);
        }
        callGetMemberReceiptDetails()
            .then((response) => {
                console.log("Get call Member Receipt Details");
                if (response) {
                    setObjModelMemberReceiptDetails(response.MemberReceiptDetails);
                }
            }).catch(
                function (error) {
                const data = {
                    AppId : ConfigData.appId,
                    TokenId : getTokenFromStorage(),
                    UserId:localStorage.getItem('UserId'),
                    DateTime:curDT,
                    PageUrl:PageUrl.pathname,
                    ExceptionDetails:error.stack
                }
                axios.post(urlAPI + 'PostOSPortalLoggingExceptions', data).then(function(response){
                  if(response.data.TokenInfoDetails.TokenResponse=='Expired' ){ getToken();
                    axios.post(urlAPI + 'PostOSPortalLoggingExceptions', data)}
                });}
            );
    }, []);

    function onPaymentHistoryClick(event) {
        event.preventDefault();
        //let encodedUserId = sessionStorage.getItem("UserID");
        let encodedUserId = localStorage.getItem("UserId");
        console.log("encoded user id");
        console.log(encodedUserId);
        navigate("/receipts-list?UserId=" + encodedUserId);
    }

    return (

        <Box sx={{backgroundColor: '#F7F7F9',p:'25px 0'}}>
            <Box sx={{maxWidth:'750px',margin:'0 auto'}} className="account-form">
                <Card className="card_box">
                    {
                    (objModelMemberReceiptDetails != null
                            && objModelMemberReceiptDetails.Message != null
                            && objModelMemberReceiptDetails.Message == "Valid") ?
                        <div>
                            <div ref={printRef}>
                                <div className={classes.divTransactionsMain}>
                                    <h2>Your Receipt</h2>
                                    <a
                                        href=""
                                        className={classes.aLink}
                                        onClick={(e) => onPaymentHistoryClick(e)}>
                                        Payment History
                                    </a>
                                    <br /><br />
                                    <span className={classes.spanPaymentDate}>
                                        {objModelMemberReceiptDetails.UserDetails.PaymentDate}
                                    </span>
                                </div>
                            </div>
                            <Divider sx={{ m: '20px 0  !important' }} />

                            <div>
                            {objModelMemberReceiptDetails.TransactionDetails.map(function (object, i) {
                                return (
                                    <div key={i}>
                                        <div className={classes.divReceiptTransactions}>
                                            <div className={classes.divReceiptTransactionsLeft}>
                                                <span className={classes.spanItemDetails}>{object.ItemDescription}</span>
                                            </div>
                                            <div className={classes.divReceiptTransactionsRight}>
                                                <span className={classes.spanItemDetails}>${object.ItemAmount}</span>
                                            </div>
                                        </div>
                                        <br />
                                    </div>
                                )
                            })}
                            <div>
                                <Divider sx={{ m: '10px 0  !important' }} />
                                <div className={classes.divReceiptTransactions}>
                                    <div className={classes.divReceiptTransactionsLeft}>
                                        <span className={classes.spanTotalDetails}>Total</span>
                                    </div>
                                    <div className={classes.divReceiptTransactionsRight}>
                                        <span className={classes.spanTotalDetails}>${objModelMemberReceiptDetails.UserDetails.PaymentAmount}</span>
                                    </div>
                                </div>
                                <Divider sx={{ m: '10px 0  !important' }} />
                            </div>
                        </div>

                        <CardContent>
                        <Grid container spacing={5}>
                        <Grid item xs={12} sm={6}>
                            <Typography variant='body2' sx={{ fontWeight: 500, m:'0 0 5px !important', color:'#4c4e64de',fontSize:'20px !important' }}>
                                Customer Details
                            </Typography>
                            <div>
                                <div>
                                    <span className={classes.spanDetails}>{objModelMemberReceiptDetails.UserDetails.FullName}</span>
                                    <span className={classes.spanDetails}> (USEA ID {objModelMemberReceiptDetails.UserDetails.MemberID})</span>
                                </div>
                                <div>
                                    <span className={classes.spanDetails}>{objModelMemberReceiptDetails.UserDetails.Address}</span>
                                    <span className={classes.spanDetails}>, </span>
                                    <span className={classes.spanDetails}>{objModelMemberReceiptDetails.UserDetails.City}</span>
                                    <span className={classes.spanDetails}>, </span>
                                    <span className={classes.spanDetails}>{objModelMemberReceiptDetails.UserDetails.State}</span>
                                    <span className={classes.spanDetails}>, </span>
                                    <span className={classes.spanDetails}>{objModelMemberReceiptDetails.UserDetails.Zip}</span>
                                    <span className={classes.spanDetails}>, </span>
                                    <span className={classes.spanDetails}>{objModelMemberReceiptDetails.UserDetails.Country}</span>
                                </div>
                                <div>
                                    <span className={classes.spanDetails}>{objModelMemberReceiptDetails.UserDetails.EmailAddress}</span>
                                </div>
                                <div>
                                    <span className={classes.spanDetails}>{objModelMemberReceiptDetails.UserDetails.Phone}</span>
                                </div>
                            </div>
                         </Grid>
                         <Grid item xs={12} sm={6}> 
                            <Typography variant='body2' sx={{ fontWeight: 500, m:'0 0 5px !important', color:'#4c4e64de',fontSize:'20px !important' }}>
                                Payment Details
                            </Typography>
                            <div>
                                <div>
                                    <span className={classes.spanDetails}>{objModelMemberReceiptDetails.UserDetails.PaymentDetail}</span>
                                </div>
                                <div>
                                    <span className={classes.spanDetails}>Payment ID: {objModelMemberReceiptDetails.UserDetails.PaymentID}</span>
                                </div>
                            </div>
                        </Grid>
                    </Grid>
                    </CardContent>

                    <CardContent>
                        <Grid container spacing={5}>
                            <Grid item xs={12} sm={12}>
                                <div className={classes.divTransactionsMain}>
                                    <img src="../../../assets/images/usea_logo.jpg" width="100px" height="100px" />
                                    <br />
                                    <span className={classes.receiptLogoDetails}>United States Eventing Association, Inc.</span>
                                    <br />
                                    <span>525 Old Waterford Rd, NW</span>
                                    <br />
                                    <span>Leesburg, VA - 20147</span>
                                    <br />
                                    <span>703-779-0440 | support@useventing.com</span>
                                    <br /><br />
                                </div>
                            </Grid>
                        </Grid>
                    </CardContent>

                            <Box sx={{mb:3}}>
                                <div className={classes.divTransactionsMain}>
                                    <a
                                        href='#'
                                        className={classes.aLink}
                                        onClick={exportPDFReceipt}>
                                        <i class="fa fa-file-pdf-o fa-2x" aria-hidden="true"></i>
                                    </a>
                                    <br />
                                    <a
                                        href='#'
                                        className={classes.aLink}
                                        onClick={exportPDFReceipt}>Download Receipt
                                    </a>
                                </div>
                            </Box>


                        </div>
                        :
                        <div>
                             <Box sx={{maxWidth:'750px',margin:'0 auto'}} className="account-form">
                            <Card className="card_box">
                                <CardContent>
                                <Grid container spacing={5}>
                                    <Grid item xs={12} sm={6}>
                                        <span className={classes.spanDetails}>Invalid input data.</span>
                                    </Grid>
                                </Grid>
                                </CardContent>
                                
                            </Card>
                            </Box>
                        </div>
                    }
                </Card>
            </Box>
        </Box>
    );
};

export default MemberReceipt;