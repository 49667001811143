import React from "react";
import { useRef, useState, useEffect } from "react";

import ConfigData from "../../ConfigurationData/ConfigData.json";
import { Buffer } from "buffer";

import { DropDownList } from "@progress/kendo-react-dropdowns";
import {
  RadioGroup,
  Checkbox,
  NumericTextBox,
} from "@progress/kendo-react-inputs";
// import { Button } from "@progress/kendo-react-buttons";

import UpdateCreditCardOnFile from "./UpdateCreditCardOnFile";

import classes from "./MembershipSettings.module.css";
import {
  getTokenFromStorage,
  getUserId,
  getToken,
} from "../../api/RestServices";

// ** MUI Imports
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import TextField from "@mui/material/TextField";
import CardHeader from "@mui/material/CardHeader";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import { Link } from "@mui/material";
import { Form, Field, FormElement, Text } from "@progress/kendo-react-form";
import MuiFormControlLabel from "@mui/material/FormControlLabel";

const FormControlLabel = styled(MuiFormControlLabel)(({ theme }) => ({
  "& .MuiFormControlLabel-label": {
    fontSize: "0.875rem",
    color: theme.palette.text.secondary,
  },
}));

const MembershipSettings = (props) => {
  let urlAPI = ConfigData.apiURL;
  let appId = ConfigData.appId;
  // let UserID = localStorage.getItem('UserId');
  const [UserID, setUserID] = useState(props.UserID);
  const [isAddressInternational, setIsAddressInternational] = useState(false);
  const [addressDetailsValue, setAddressDetailsValue] = useState("");
  const [isFirstAddressValue, setIsFirstAddressValue] = useState(true);

  const [modelAddressDetails, setModelAddressDetails] = useState({});
  const [modelMembershipDetails, setModelMembershipDetails] = useState({});
  const [modelMagazineSelectionDetails, setModelMagazineSelectionDetails] =
    useState({});
  const [modelAreaDesignationDetails, setModelAreaDesignationDetails] =
    useState({});
  const [modelYoungRiderProgramDetails, setModelYoungRiderProgramDetails] =
    useState({});
  const [modelAdultRiderProgramDetails, setModelAdultRiderProgramDetails] =
    useState({});
  const [modelAnnualDonationDetails, setModelAnnualDonationDetails] = useState(
    {}
  );
  const [modelAutoRenewMembershipDetails, setModelAutoRenewMembershipDetails] =
    useState({});
  const [modelAmateurDetails, setModelAmateurDetails] = useState({});

  const [isShowCardDetailsPopup, setShowCardDetailsPopup] = useState(false);
  const [isPaymentDone, setIsPaymentDone] = useState(false);

  const [dataConfirmAddressErrorMessage, setDataConfirmAddressErrorMessage] =
    useState({});
  const [dataMembershipTypeErrorMessage, setDataMembershipTypeErrorMessage] =
    useState({});
  const [dataAmateurErrorMessage, setDataAmateurErrorMessage] = useState({});
  const [
    dataMagazineSelectionErrorMessage,
    setDataMagazineSElectionErrorMessage,
  ] = useState({});
  const [
    dataAreaDesignationErrorMessage,
    setDataAreaDesignationMonthErrorMessage,
  ] = useState({});
  const [
    dataYoungRiderProgramErrorMessage,
    setDataYoungRiderProgramErrorMessage,
  ] = useState({});
  const [
    dataAdultRiderProgramErrorMessage,
    setDataAdultRiderProgramErrorMessage,
  ] = useState({});
  const [
    dataAnnualDonationAmountErrorMessage,
    setDataAnnualDonationAmountErrorMessage,
  ] = useState({});
  const [data, setData] = useState();
  const autoCompleteRef = useRef();
  const inputRef = useRef();
  const options = {
    fields: ["address_components", "geometry", "icon", "name"],
  };
  const google_api = ConfigData.apikey;

  useEffect(() => {
    if (props.objModelMembershipDetails !== null) {
      if (props.objModelMembershipDetails.AddressDetails !== null) {
        let addressDetails = "";
        modelAddressDetails.AddressDetails = {};
        if (props.objModelMembershipDetails.AddressDetails.Address1 !== "") {
          addressDetails +=
            props.objModelMembershipDetails.AddressDetails.Address1;
          modelAddressDetails.AddressDetails.SelectedAddress1 =
            props.objModelMembershipDetails.AddressDetails.Address1;
        }

        if (props.objModelMembershipDetails.AddressDetails.Address2 !== "") {
          addressDetails +=
            ", " + props.objModelMembershipDetails.AddressDetails.Address2;
          modelAddressDetails.AddressDetails.SelectedAddress2 =
            props.objModelMembershipDetails.AddressDetails.Address2;
        }

        if (props.objModelMembershipDetails.AddressDetails.City !== "") {
          addressDetails +=
            ", " + props.objModelMembershipDetails.AddressDetails.City;
          modelAddressDetails.AddressDetails.SelectedCity =
            props.objModelMembershipDetails.AddressDetails.City;
        }

        if (props.objModelMembershipDetails.AddressDetails.State !== "") {
          addressDetails +=
            ", " + props.objModelMembershipDetails.AddressDetails.State;
          modelAddressDetails.AddressDetails.SelectedState =
            props.objModelMembershipDetails.AddressDetails.State;
        }

        if (props.objModelMembershipDetails.AddressDetails.Zipcode !== "") {
          addressDetails +=
            ", " + props.objModelMembershipDetails.AddressDetails.Zipcode;
          modelAddressDetails.AddressDetails.SelectedZipcode =
            props.objModelMembershipDetails.AddressDetails.Zipcode;
        }

        if (props.objModelMembershipDetails.AddressDetails.Country !== "") {
          addressDetails +=
            ", " + props.objModelMembershipDetails.AddressDetails.Country;
          modelAddressDetails.AddressDetails.Country =
            props.objModelMembershipDetails.AddressDetails.Country;

          if (
            props.objModelMembershipDetails.AddressDetails.Country ===
              "United States" ||
            props.objModelMembershipDetails.AddressDetails.Country === "USA"
          ) {
            setIsAddressInternational(false);
          } else if (
            props.objModelMembershipDetails.AddressDetails.Country ===
              "Canada" &&
            modelMembershipDetails &&
            modelMembershipDetails.MembershipTypeDetails &&
            modelMembershipDetails.MembershipTypeDetails
              .SelectedMembershipTypeValue !== "0"
          ) {
            setIsAddressInternational(true);
          } else if (
            props.objModelMembershipDetails.AddressDetails.Country ===
              "Mexico" &&
            modelMembershipDetails &&
            modelMembershipDetails.MembershipTypeDetails &&
            modelMembershipDetails.MembershipTypeDetails
              .SelectedMembershipTypeValue !== "0"
          ) {
            setIsAddressInternational(true);
          } else if (
            modelMembershipDetails &&
            modelMembershipDetails.MembershipTypeDetails &&
            modelMembershipDetails.MembershipTypeDetails
              .SelectedMembershipTypeValue !== "0"
          ) {
            setIsAddressInternational(true);
          }
        }
        setAddressDetailsValue(addressDetails);
        setIsFirstAddressValue(false);
      }

      modelMembershipDetails.MembershipTypeDetails = {};
      if (props.objModelMembershipDetails.MembershipTypeDetails !== null) {
        modelMembershipDetails.MembershipTypeDetails.SelectedMembershipTypeName =
          props.objModelMembershipDetails.MembershipTypeDetails.MembershipTypeSelection.MembershipTypeName;
        modelMembershipDetails.MembershipTypeDetails.SelectedMembershipTypeValue =
          props.objModelMembershipDetails.MembershipTypeDetails.MembershipTypeSelection.MembershipTypeValue;
      }

      modelMagazineSelectionDetails.MagazineSelectionDetails = {};
      if (props.objModelMembershipDetails.MagazineSelectionDetails !== null) {
        modelMagazineSelectionDetails.MagazineSelectionDetails.SelectedMagazineSelectionName =
          props.objModelMembershipDetails.MagazineSelectionDetails.MagazineSelection.MagazineSelectionName;
        modelMagazineSelectionDetails.MagazineSelectionDetails.SelectedMagazineSelectionValue =
          props.objModelMembershipDetails.MagazineSelectionDetails.MagazineSelection.MagazineSelectionValue;
      }

      modelAreaDesignationDetails.AreaDesignationDetails = {};
      if (props.objModelMembershipDetails.AreaDesignationDetails !== null) {
        modelAreaDesignationDetails.AreaDesignationDetails.SelectedAreaDesignationName =
          props.objModelMembershipDetails.AreaDesignationDetails.AreaDesignationSelection.AreaDesignationName;
        modelAreaDesignationDetails.AreaDesignationDetails.SelectedAreaDesignationValue =
          props.objModelMembershipDetails.AreaDesignationDetails.AreaDesignationSelection.AreaDesignationValue;
      }

      modelYoungRiderProgramDetails.YoungRiderProgramDetails = {};
      if (props.objModelMembershipDetails.YoungRiderProgramDetails !== null) {
        modelYoungRiderProgramDetails.YoungRiderProgramDetails.SelectedYoungRiderProgramDetailsValue =
          props.objModelMembershipDetails.YoungRiderProgramDetails.YoungRiderProgramDetailsSelection;
      }

      modelAdultRiderProgramDetails.AdultRiderProgramDetails = {};
      if (props.objModelMembershipDetails.AdultRiderProgramDetails !== null) {
        modelAdultRiderProgramDetails.AdultRiderProgramDetails.SelectedAdultRiderProgramDetailsValue =
          props.objModelMembershipDetails.AdultRiderProgramDetails.AdultRiderProgramDetailsSelection;
      }

      modelAnnualDonationDetails.AnnualDonationDetails = {};
      if (props.objModelMembershipDetails.AnnualDonationDetails !== null) {
        modelAnnualDonationDetails.AnnualDonationDetails.SelectedAnnualDonationName =
          props.objModelMembershipDetails.AnnualDonationDetails.AnnualDonationDetailsSelection.AnnualDonationDetailsName;
        modelAnnualDonationDetails.AnnualDonationDetails.SelectedAnnualDonationValue =
          props.objModelMembershipDetails.AnnualDonationDetails.AnnualDonationDetailsSelection.AnnualDonationDetailsValue;
        modelAnnualDonationDetails.AnnualDonationDetails.SelectedAnnualDonationAmount =
          props.objModelMembershipDetails.AnnualDonationDetails.AnnualDonationAmount;
      }

      modelAutoRenewMembershipDetails.AutoRenewMembershipDetails = {};
      if (props.objModelMembershipDetails.AutoRenewMembershipDetails !== null) {
        modelAutoRenewMembershipDetails.AutoRenewMembershipDetails.SelectedAutoRenewMembership =
          props.objModelMembershipDetails.AutoRenewMembershipDetails.SelectedAutoRenewMembership;
      }

      modelAmateurDetails.AmateurTypeDetails = {};
      if (
        props.objModelMembershipDetails.AmateurTypeDetails !== null &&
        props.objModelMembershipDetails.AmateurTypeDetails
          .AmateurDetailsSelection !== null
      ) {
        modelAmateurDetails.AmateurTypeDetails.SelectedAmateurTypeValue =
          props.objModelMembershipDetails.AmateurTypeDetails.AmateurDetailsSelection;
      }
    }

    autoCompleteRef.current = new window.google.maps.places.Autocomplete(
      inputRef.current,
      options
    );

    autoCompleteRef.current.addListener("place_changed", async function () {
      const place = await autoCompleteRef.current.getPlace();
      if (place) {
        const address = place.address_components;

        if (address) {
          modelAddressDetails.AddressDetails.SelectedAddress1 =
            address[0].long_name + " " + address[1].long_name;

          modelAddressDetails.AddressDetails.SelectedAddress2 =
            address[2].types[0] === "administrative_area_level_2"
              ? address[1].long_name
              : address[2].types[0] === "sublocality_level_1"
              ? address[2].long_name
              : "";

          modelAddressDetails.AddressDetails.SelectedCity =
            address[2].types[0] === "locality"
              ? address[2].long_name
              : address[2].types[0] === "postal_town"
              ? address[2].long_name
              : address[2].types[0] === "sublocality_level_1"
              ? address[3].long_name
              : address[3].long_name;

          modelAddressDetails.AddressDetails.SelectedState =
            address[2].types[0] === "locality"
              ? address[4].long_name
              : address[2].types[0] === "postal_town"
              ? address[4].short_name
              : address[2].types[0] === "administrative_area_level_2"
              ? address[3].short_name
              : address[2].types[0] === "sublocality_level_1"
              ? address[5].short_name
              : address[2].types[0] === "neighborhood"
              ? address[4].short_name
              : address[5].short_name;

          modelAddressDetails.AddressDetails.Country =
            address[2].types[0] === "locality"
              ? address[5].long_name
              : address[2].types[0] === "postal_town"
              ? address[5].long_name
              : address[2].types[0] === "administrative_area_level_2"
              ? address[4].long_name
              : address[2].types[0] === "sublocality_level_1"
              ? address[6].long_name
              : address[2].types[0] === "neighborhood"
              ? address[5].long_name
              : address[6].long_name;

          modelAddressDetails.AddressDetails.SelectedZipcode =
            address[2].types[0] === "locality"
              ? address[6].long_name
              : address[2].types[0] === "postal_town"
              ? address[6].short_name
              : address[2].types[0] === "administrative_area_level_2"
              ? address[5].short_name
              : address[2].types[0] === "sublocality_level_1"
              ? address.Length > 7
                ? address[7].short_name
                : ""
              : address[2].types[0] === "neighborhood"
              ? address[6].short_name
              : address[7].short_name;

          let addressDetails = "";
          if (modelAddressDetails.AddressDetails.SelectedAddress1)
            addressDetails +=
              modelAddressDetails.AddressDetails.SelectedAddress1;

          if (
            modelAddressDetails.AddressDetails.SelectedAddress2 &&
            modelAddressDetails.AddressDetails.SelectedAddress2 !== ""
          )
            addressDetails +=
              ", " + modelAddressDetails.AddressDetails.SelectedAddress2;

          if (modelAddressDetails.AddressDetails.SelectedCity)
            addressDetails +=
              ", " + modelAddressDetails.AddressDetails.SelectedCity;

          if (modelAddressDetails.AddressDetails.SelectedState)
            addressDetails +=
              ", " + modelAddressDetails.AddressDetails.SelectedState;

          if (
            modelAddressDetails.AddressDetails.SelectedZipcode &&
            modelAddressDetails.AddressDetails.SelectedZipcode !== ""
          )
            addressDetails +=
              ", " + modelAddressDetails.AddressDetails.SelectedZipcode;

          if (modelAddressDetails.AddressDetails.Country)
            addressDetails += ", " + modelAddressDetails.AddressDetails.Country;

          setAddressDetailsValue(addressDetails);

          if (
            modelAddressDetails.AddressDetails.Country === "United States" ||
            modelAddressDetails.AddressDetails.Country === "USA"
          ) {
            setIsAddressInternational(false);
          } else if (
            modelAddressDetails.AddressDetails.Country === "Canada" &&
            modelMembershipDetails &&
            modelMembershipDetails.MembershipTypeDetails &&
            modelMembershipDetails.MembershipTypeDetails
              .SelectedMembershipTypeValue !== "0"
          ) {
            setIsAddressInternational(true);
          } else if (
            modelAddressDetails.AddressDetails.Country === "Mexico" &&
            modelMembershipDetails &&
            modelMembershipDetails.MembershipTypeDetails &&
            modelMembershipDetails.MembershipTypeDetails
              .SelectedMembershipTypeValue !== "0"
          ) {
            setIsAddressInternational(true);
          } else if (
            modelMembershipDetails &&
            modelMembershipDetails.MembershipTypeDetails &&
            modelMembershipDetails.MembershipTypeDetails
              .SelectedMembershipTypeValue !== "0"
          ) {
            setIsAddressInternational(true);
          }
        }
      }
    });
  }, [modelAddressDetails, modelMembershipDetails]);

  function onCardDetailsClick(event) {
    event.preventDefault();
    setShowCardDetailsPopup(!isShowCardDetailsPopup);
  }

  function updateShowCardDetailsPopup(childShowCardDetailsPopup) {
    setShowCardDetailsPopup(childShowCardDetailsPopup);
  }

  function updateIsPaymentDone(childIsPaymentDone) {
    setIsPaymentDone(childIsPaymentDone);
  }

  const onChangeMembershipType = (event) => {
    let strSelectedMembershipTypeName = "";
    let strSelectedMembershipTypeValue = "";

    if (event.target.value) {
      if (
        event.target.value !== null &&
        event.target.value.MembershipTypeName &&
        event.target.value.MembershipTypeName !== null
      )
        strSelectedMembershipTypeName = event.target.value.MembershipTypeName;

      if (
        event.target.value !== null &&
        event.target.value.MembershipTypeValue !== null
      )
        strSelectedMembershipTypeValue = event.target.value.MembershipTypeValue;

      modelMembershipDetails.MembershipTypeDetails.SelectedMembershipTypeName =
        strSelectedMembershipTypeName;
      modelMembershipDetails.MembershipTypeDetails.SelectedMembershipTypeValue =
        strSelectedMembershipTypeValue;
    }
  };

  const onChangeMagazineSelection = (event) => {
    let strSelectedMagazineSelectionName = "";
    let strSelectedMagazineSelectionValue = "";
    if (event.target.value) {
      if (
        event.target.value !== null &&
        event.target.value.MagazineSelectionName &&
        event.target.value.MagazineSelectionName !== null
      )
        strSelectedMagazineSelectionName =
          event.target.value.MagazineSelectionName;

      if (
        event.target.value !== null &&
        event.target.value.MagazineSelectionValue &&
        event.target.value.MagazineSelectionValue !== null
      )
        strSelectedMagazineSelectionValue =
          event.target.value.MagazineSelectionValue;

      modelMagazineSelectionDetails.MagazineSelectionDetails.SelectedMagazineSelectionName =
        strSelectedMagazineSelectionName;
      modelMagazineSelectionDetails.MagazineSelectionDetails.SelectedMagazineSelectionValue =
        strSelectedMagazineSelectionValue;

      if (
        modelMagazineSelectionDetails.MagazineSelectionDetails
          .SelectedMagazineSelectionValue === "0"
      )
        setIsAddressInternational(false);
      else {
        if (
          modelAddressDetails.AddressDetails.Country === "United States" ||
          modelAddressDetails.AddressDetails.Country === "USA"
        )
          setIsAddressInternational(false);
        else if (modelAddressDetails.AddressDetails.Country === "Canada")
          setIsAddressInternational(true);
        else if (modelAddressDetails.AddressDetails.Country === "Mexico")
          setIsAddressInternational(true);
        else setIsAddressInternational(true);
      }
    }
  };

  const onChangeAreaDesignation = (event) => {
    let strSelectedAreaDesignationName = "";
    let strSelectedAreaDesignationValue = "";
    if (event.target.value) {
      if (
        event.target.value !== null &&
        event.target.value.AreaDesignationName &&
        event.target.value.AreaDesignationName !== null
      )
        strSelectedAreaDesignationName = event.target.value.AreaDesignationName;

      if (
        event.target.value !== null &&
        event.target.value.AreaDesignationValue &&
        event.target.value.AreaDesignationValue !== null
      )
        strSelectedAreaDesignationValue =
          event.target.value.AreaDesignationValue;

      modelAreaDesignationDetails.AreaDesignationDetails.SelectedAreaDesignationName =
        strSelectedAreaDesignationName;
      modelAreaDesignationDetails.AreaDesignationDetails.SelectedAreaDesignationValue =
        strSelectedAreaDesignationValue;
    }
  };

  const onChangeAnnualDonation = (event) => {
    let strSelectedAnnualDonationName = "";
    let strSelectedAnnualDonationValue = "";
    if (event.target.value) {
      if (
        event.target.value !== null &&
        event.target.value.AnnualDonationDetailsName &&
        event.target.value.AnnualDonationDetailsName !== null
      )
        strSelectedAnnualDonationName =
          event.target.value.AnnualDonationDetailsName;

      if (
        event.target.value !== null &&
        event.target.value.AnnualDonationDetailsValue &&
        event.target.value.AnnualDonationDetailsValue !== null
      )
        strSelectedAnnualDonationValue =
          event.target.value.AnnualDonationDetailsValue;

      modelAnnualDonationDetails.AnnualDonationDetails.SelectedAnnualDonationName =
        strSelectedAnnualDonationName;
      modelAnnualDonationDetails.AnnualDonationDetails.SelectedAnnualDonationValue =
        strSelectedAnnualDonationValue;
    }
  };

  const onChangeAnnualDonationAmount = (event) => {
    let iSelectedDonationAmount = "";
    if (event.target.value) {
      if (event.target.value !== null)
        iSelectedDonationAmount = event.target.value;

      modelAnnualDonationDetails.AnnualDonationDetails.SelectedAnnualDonationAmount =
        iSelectedDonationAmount;
    }
  };

  const onChangeAmateur = (event) => {
    let strSelectedAmateurValue = "";
    if (event.value !== null) strSelectedAmateurValue = event.value;

    modelAmateurDetails.AmateurTypeDetails.SelectedAmateurTypeValue =
      strSelectedAmateurValue;
  };

  const onChangeYoungRiderProgram = (event) => {
    let strSelectedYoungRiderProgramValue = "";
    if (event.value !== null) strSelectedYoungRiderProgramValue = event.value;

    modelYoungRiderProgramDetails.YoungRiderProgramDetails.SelectedYoungRiderProgramDetailsValue =
      strSelectedYoungRiderProgramValue;
  };

  const onChangeAdultRiderProgram = (event) => {
    let strSelectedAdultRiderProgramValue = "";
    if (event.value !== null) strSelectedAdultRiderProgramValue = event.value;

    modelAdultRiderProgramDetails.AdultRiderProgramDetails.SelectedAdultRiderProgramDetailsValue =
      strSelectedAdultRiderProgramValue;
  };

  const onChangeAutoRenewMembership = (event) => {
    let bSelectedAutoRenewMembershipValue = false;
    if (event.value !== null) bSelectedAutoRenewMembershipValue = event.value;

    modelAutoRenewMembershipDetails.AutoRenewMembershipDetails.SelectedAutoRenewMembership =
      bSelectedAutoRenewMembershipValue;
  };

  const onSubmitSaveSettings = (e) => {
    e.preventDefault();

    const isValid = formValidation();

    if (isValid) {
      console.log("submit details");
      console.log("Valid details to submit to post API");
      callMembershipSettingsPost().then((response) => {
        console.log("Call Membership Settings Post done");
        console.log(response);
        if (response.MembershipSettingsPostData.Message === "Success") {
          console.log(data);
          alert("Renewal Message settings saved!");
        } else {
          alert("Renewal Message settings not saved! Please check for errors.");
        }
      });
    }
  };

  const formValidation = () => {
    const dataConfirmAddressErrorMessage = {};
    const dataMembershipTypeErrorMessage = {};
    const dataAmateurErrorMessage = {};
    const dataMagazineSelectionErrorMessage = {};
    const dataAreaDesignationErrorMessage = {};
    const dataYoungRiderProgramErrorMessage = {};
    const dataAdultRiderProgramErrorMessage = {};
    const dataAnnualDonationAmountErrorMessage = {};

    let isValid = true;

    console.log(props.objModelSelectedMembershipDetails);
    // validate Confirm Address
    if (
      modelAddressDetails !== null &&
      modelAddressDetails.AddressDetails.Country === ""
    ) {
      dataConfirmAddressErrorMessage.ErrorMessage =
        "Please confirm the Address.";
      isValid = false;
    }

    // validate Membership Type
    if (
      modelMembershipDetails !== null &&
      modelMembershipDetails.MembershipTypeDetails
        .SelectedMembershipTypeValue === -1
    ) {
      dataMembershipTypeErrorMessage.ErrorMessage =
        "Please select the Membership Type.";
      isValid = false;
    }

    // validate Amateur Type
    if (
      modelAmateurDetails !== null &&
      modelAmateurDetails.AmateurTypeDetails.SelectedAmateurTypeValue === "-1"
    ) {
      dataAmateurErrorMessage.ErrorMessage =
        "Please select the Amateur status.";
      isValid = false;
    }

    // validate Magazine Selection
    if (
      modelMagazineSelectionDetails !== null &&
      modelMagazineSelectionDetails.MagazineSelectionDetails
        .SelectedMagazineSelectionValue === -1
    ) {
      dataMagazineSelectionErrorMessage.ErrorMessage =
        "Please select the Magazine selection.";
      isValid = false;
    }

    // validate Area Designation
    if (
      modelAreaDesignationDetails !== null &&
      modelAreaDesignationDetails.AreaDesignationDetails
        .SelectedAreaDesignationValue === "00000000-0000-0000-0000-000000000000"
    ) {
      dataAreaDesignationErrorMessage.ErrorMessage =
        "Please select the Area Designation for Awards & Programs.";
      isValid = false;
    }

    // validate Young Rider Program Options
    if (
      modelYoungRiderProgramDetails !== null &&
      modelYoungRiderProgramDetails.YoungRiderProgramDetails
        .SelectedYoungRiderProgramDetailsValue === ""
    ) {
      dataYoungRiderProgramErrorMessage.ErrorMessage =
        "Please select the Young Rider Program options.";
      isValid = false;
    }

    // validate Adult Rider Program Options
    if (
      modelAdultRiderProgramDetails !== null &&
      modelAdultRiderProgramDetails.AdultRiderProgramDetails
        .SelectedAdultRiderProgramDetailsValue === ""
    ) {
      dataAdultRiderProgramErrorMessage.ErrorMessage =
        "Please select the Adult Rider Program options.";
      isValid = false;
    }

    // validate Annual Donation
    if (
      modelAnnualDonationDetails !== null &&
      modelAnnualDonationDetails.AnnualDonationDetails
        .SelectedAnnualDonationValue !==
        "00000000-0000-0000-0000-000000000000" &&
      (modelAnnualDonationDetails.AnnualDonationDetails
        .SelectedAnnualDonationAmount === "" ||
        (modelAnnualDonationDetails.AnnualDonationDetails
          .SelectedAnnualDonationAmount !== "" &&
          parseInt(
            modelAnnualDonationDetails.AnnualDonationDetails
              .SelectedAnnualDonationAmount,
            10
          ) < 5))
    ) {
      dataAnnualDonationAmountErrorMessage.ErrorMessage =
        "Please enter the Donation Amount greater than $5.";
      isValid = false;
    }

    if (isValid) {
      console.log("Authenticated");
    } else {
      console.log("errors try again");
    }

    setDataConfirmAddressErrorMessage(dataConfirmAddressErrorMessage);
    setDataMembershipTypeErrorMessage(dataMembershipTypeErrorMessage);
    setDataAmateurErrorMessage(dataAmateurErrorMessage);
    setDataMagazineSElectionErrorMessage(dataMagazineSelectionErrorMessage);
    setDataAreaDesignationMonthErrorMessage(dataAreaDesignationErrorMessage);
    setDataYoungRiderProgramErrorMessage(dataYoungRiderProgramErrorMessage);
    setDataAdultRiderProgramErrorMessage(dataAdultRiderProgramErrorMessage);
    setDataAnnualDonationAmountErrorMessage(
      dataAnnualDonationAmountErrorMessage
    );

    return isValid;
  };

  const callMembershipSettingsPost = async () => {
    let strMembershipSettingsData = "";

    strMembershipSettingsData += "{";
    strMembershipSettingsData += '"UserID":"' + UserID + '"';

    if (
      modelAddressDetails.AddressDetails &&
      modelAddressDetails.AddressDetails !== null
    ) {
      strMembershipSettingsData += ',"AddressDetails":{';
      strMembershipSettingsData +=
        '"SelectedAddress1":"' +
        modelAddressDetails.AddressDetails.SelectedAddress1 +
        '"';
      if (modelAddressDetails.AddressDetails.SelectedAddress2 != null)
        strMembershipSettingsData +=
          ',"SelectedAddress2":"' +
          modelAddressDetails.AddressDetails.SelectedAddress2 +
          '"';
      else strMembershipSettingsData += ',"SelectedAddress2":""';
      strMembershipSettingsData +=
        ',"SelectedCity":"' +
        modelAddressDetails.AddressDetails.SelectedCity +
        '"';
      strMembershipSettingsData +=
        ',"SelectedState":"' +
        modelAddressDetails.AddressDetails.SelectedState +
        '"';
      strMembershipSettingsData +=
        ',"SelectedZipcode":"' +
        modelAddressDetails.AddressDetails.SelectedZipcode +
        '"';
      strMembershipSettingsData +=
        ',"SelectedCountry":"' +
        modelAddressDetails.AddressDetails.Country +
        '"';
      strMembershipSettingsData += "}";
    }

    if (
      modelMembershipDetails.MembershipTypeDetails &&
      modelMembershipDetails.MembershipTypeDetails !== null
    ) {
      strMembershipSettingsData += ',"MembershipTypeDetails":{';
      strMembershipSettingsData +=
        '"SelectedMembershipTypeName":"' +
        modelMembershipDetails.MembershipTypeDetails
          .SelectedMembershipTypeName +
        '"';
      strMembershipSettingsData +=
        ',"SelectedMembershipTypeValue":' +
        modelMembershipDetails.MembershipTypeDetails
          .SelectedMembershipTypeValue;
      strMembershipSettingsData += "}";
    }

    if (
      modelAmateurDetails.AmateurTypeDetails &&
      modelAmateurDetails.AmateurTypeDetails !== null
    ) {
      strMembershipSettingsData += ',"AmateurTypeDetails":{';
      strMembershipSettingsData +=
        '"SelectedAmateurTypeValue":' +
        modelAmateurDetails.AmateurTypeDetails.SelectedAmateurTypeValue;
      strMembershipSettingsData += "}";
    }

    if (
      modelMagazineSelectionDetails.MagazineSelectionDetails &&
      modelMagazineSelectionDetails.MagazineSelectionDetails !== null
    ) {
      strMembershipSettingsData += ',"MagazineSelectionDetails":{';
      strMembershipSettingsData +=
        '"SelectedMagazineSelectionName":"' +
        modelMagazineSelectionDetails.MagazineSelectionDetails
          .SelectedMagazineSelectionName +
        '"';
      strMembershipSettingsData +=
        ',"SelectedMagazineSelectionValue":' +
        modelMagazineSelectionDetails.MagazineSelectionDetails
          .SelectedMagazineSelectionValue;
      strMembershipSettingsData += "}";
    }

    if (
      modelAreaDesignationDetails.AreaDesignationDetails &&
      modelAreaDesignationDetails.AreaDesignationDetails !== null
    ) {
      if (
        modelAreaDesignationDetails.AreaDesignationDetails
          .SelectedAreaDesignationValue ==
        "00000000-0000-0000-0000-000000000000"
      ) {
        strMembershipSettingsData += ',"AreaDesignationDetails":{';
        strMembershipSettingsData +=
          '{"SelectedAreaDesignationName":"' +
          modelAreaDesignationDetails.AreaDesignationDetails
            .SelectedAreaDesignationValue +
          '"';
        strMembershipSettingsData += ',"SelectedAreaDesignationValue":""';
        strMembershipSettingsData += "}";
      } else {
        strMembershipSettingsData += ',"AreaDesignationDetails":{';
        strMembershipSettingsData +=
          '"SelectedAreaDesignationName":"' +
          modelAreaDesignationDetails.AreaDesignationDetails
            .SelectedAreaDesignationName +
          '"';
        strMembershipSettingsData +=
          ',"SelectedAreaDesignationValue":"' +
          modelAreaDesignationDetails.AreaDesignationDetails
            .SelectedAreaDesignationValue +
          '"';
      }
      strMembershipSettingsData += "}";
    }

    if (
      modelAnnualDonationDetails.AnnualDonationDetails &&
      modelAnnualDonationDetails.AnnualDonationDetails !== null
    ) {
      strMembershipSettingsData += ',"AnnualDonationDetails":{';
      if (
        modelAnnualDonationDetails.AnnualDonationDetails
          .SelectedAnnualDonationValue != "00000000-0000-0000-0000-000000000000"
      )
        strMembershipSettingsData +=
          '"SelectedAnnualDonationAmount":' +
          modelAnnualDonationDetails.AnnualDonationDetails
            .SelectedAnnualDonationAmount;
      else strMembershipSettingsData += '"SelectedAnnualDonationAmount":0';
      strMembershipSettingsData +=
        ',"SelectedAnnualDonationName":"' +
        modelAnnualDonationDetails.AnnualDonationDetails
          .SelectedAnnualDonationName +
        '"';
      strMembershipSettingsData +=
        ',"SelectedAnnualDonationValue":"' +
        modelAnnualDonationDetails.AnnualDonationDetails
          .SelectedAnnualDonationValue +
        '"';
      strMembershipSettingsData += "}";
    }

    if (
      modelAutoRenewMembershipDetails.AutoRenewMembershipDetails &&
      modelAutoRenewMembershipDetails.AutoRenewMembershipDetails !== null
    ) {
      strMembershipSettingsData += ',"AutoRenewMembershipDetails":{';

      strMembershipSettingsData += modelAutoRenewMembershipDetails
        .AutoRenewMembershipDetails.SelectedAutoRenewMembership
        ? '"SelectedAutoRenewMembership":1'
        : '"SelectedAutoRenewMembership":0';
      strMembershipSettingsData += "}";
    }

    let YoungRiderProgramIsAvailable = false;
    strMembershipSettingsData += ',"ProgramDetails":[';
    if (
      modelYoungRiderProgramDetails &&
      modelYoungRiderProgramDetails.YoungRiderProgramDetails &&
      modelYoungRiderProgramDetails.YoungRiderProgramDetails !== null &&
      modelYoungRiderProgramDetails.YoungRiderProgramDetails
        .SelectedYoungRiderProgramDetailsValue &&
      modelYoungRiderProgramDetails.YoungRiderProgramDetails
        .SelectedYoungRiderProgramDetailsValue !== null
    ) {
      if (
        modelYoungRiderProgramDetails.YoungRiderProgramDetails
          .SelectedYoungRiderProgramDetailsValue ===
        "00000000-0000-0000-0000-000000000000"
      ) {
        strMembershipSettingsData +=
          '{"AreaID":"' +
          modelAreaDesignationDetails.AreaDesignationDetails
            .SelectedAreaDesignationValue +
          '"';
        strMembershipSettingsData += ',"AreaMemberGroupTypeID":""';
        strMembershipSettingsData += "}";
      } else {
        strMembershipSettingsData +=
          '{"AreaID":"' +
          modelAreaDesignationDetails.AreaDesignationDetails
            .SelectedAreaDesignationValue +
          '"';
        strMembershipSettingsData +=
          ',"AreaMemberGroupTypeID":"' +
          modelYoungRiderProgramDetails.YoungRiderProgramDetails
            .SelectedYoungRiderProgramDetailsValue +
          '"';
        strMembershipSettingsData += "}";
      }
      YoungRiderProgramIsAvailable = true;
    }

    console.log(modelAdultRiderProgramDetails);
    if (
      modelAdultRiderProgramDetails &&
      modelAdultRiderProgramDetails.AdultRiderProgramDetails &&
      modelAdultRiderProgramDetails.AdultRiderProgramDetails !== null &&
      modelAdultRiderProgramDetails.AdultRiderProgramDetails
        .SelectedAdultRiderProgramDetailsValue &&
      modelAdultRiderProgramDetails.AdultRiderProgramDetails
        .SelectedAdultRiderProgramDetailsValue !== null
    ) {
      if (YoungRiderProgramIsAvailable) strMembershipSettingsData += ",";

      if (
        modelAdultRiderProgramDetails.AdultRiderProgramDetails
          .SelectedAdultRiderProgramDetailsValue ===
        "00000000-0000-0000-0000-000000000000"
      ) {
        strMembershipSettingsData +=
          '{"AreaID":"' +
          modelAreaDesignationDetails.AreaDesignationDetails
            .SelectedAreaDesignationValue +
          '"';
        strMembershipSettingsData += ',"AreaMemberGroupTypeID":""';
        strMembershipSettingsData += "}";
      } else {
        strMembershipSettingsData +=
          '{"AreaID":"' +
          modelAreaDesignationDetails.AreaDesignationDetails
            .SelectedAreaDesignationValue +
          '"';
        strMembershipSettingsData +=
          ',"AreaMemberGroupTypeID":"' +
          modelAdultRiderProgramDetails.AdultRiderProgramDetails
            .SelectedAdultRiderProgramDetailsValue +
          '"';
        strMembershipSettingsData += "}";
      }
    }

    strMembershipSettingsData += "]";
    strMembershipSettingsData += "}";
    console.log(strMembershipSettingsData);
    setData(strMembershipSettingsData);
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        AppId: appId,
        TokenId: localStorage.getItem("TokenId"),
        MembershipSettingsData: strMembershipSettingsData,
      }),
    };

    let postMembershipSettingsURLAPI =
      urlAPI + "OSPortalMembershipSettingsDetailsPost";
    const response = await fetch(postMembershipSettingsURLAPI, requestOptions);
    if (!response.ok) {
      throw new Error("Data Membership Settings Post cound not be fetched!");
      console.log(response);
    } else {
      console.log(response);
      return response.json();
    }
  };

  return (
    <Box sx={{ backgroundColor: "#F7F7F9", p: "25px 0" }}>
      <Box
        sx={{ maxWidth: "750px", margin: "0 auto" }}
        className="account-form"
      >
        <Card className="card_box">
          <form onSubmit={onSubmitSaveSettings}>
            <CardHeader
              className={classes.lblMembershipRenewalSettings}
              title="Auto-Renewal Settings"
              sx={{ textAlign: "center", pb: 2, fontWeight: "500 !important" }}
            />
            <Typography
              className={classes.lblSubMembershipRenewalSettings}
              variant="body2"
              sx={{
                fontWeight: 500,
                m: "0 15px !important",
                color: "#4c4e64de",
                textAlign: "center",
              }}
            >
              Update the options below to change your auto-renewal and
              quick-renewal default settings.
            </Typography>

            <Divider sx={{ m: "20px 0  !important" }} />

            <CardContent>
              <Grid container spacing={5}>
                <Grid item xs={12} sm={6}>
                  {props.objModelMembershipDetails != null &&
                  props.objModelMembershipDetails.MembershipDataDetails !=
                    null ? (
                    <div>
                      <div className={classes.lblMembershipTypeTop}>
                        Name :{" "}
                        {
                          props.objModelMembershipDetails.MembershipDataDetails
                            .MemberFullName
                        }
                      </div>
                      <div className={classes.lblMembershipTypeTop}>
                        DOB :{" "}
                        {
                          props.objModelMembershipDetails.MembershipDataDetails
                            .MemberDOB
                        }
                      </div>
                      <div className={classes.lblMembershipTypeTop}>
                        Member ID :{" "}
                        {
                          props.objModelMembershipDetails.MembershipDataDetails
                            .MemberID
                        }
                      </div>
                    </div>
                  ) : (
                    <div />
                  )}
                </Grid>
              </Grid>
            </CardContent>

            <Divider sx={{ m: "20px 0 !important" }} />

            <div>
              {props.objModelMembershipDetails != null &&
              props.objModelMembershipDetails.MembershipDataDetails != null &&
              props.objModelMembershipDetails.MembershipDataDetails
                .MemberLockStatus == 0 ? (
                <div>
                  <CardContent>
                    <Grid container spacing={5}>
                      <Grid item xs={12} sm={6}>
                        <Box className="input_box">
                          <TextField
                            fullWidth
                            label="Confirm Address Mailings"
                            ref={inputRef}
                            className={classes.txtConfirmAddressMailing}
                            defaultValue={addressDetailsValue || ""}
                          />
                        </Box>
                        {Object.keys(dataConfirmAddressErrorMessage).map(
                          (key) => {
                            return (
                              <div className={classes.lblErrors}>
                                {dataConfirmAddressErrorMessage[key]}
                              </div>
                            );
                          }
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6} className="membership-type">
                        {props.objModelMembershipDetails != null &&
                        props.objModelMembershipDetails.MembershipTypeDetails !=
                          null &&
                        props.objModelMembershipDetails.MembershipTypeDetails
                          .MembershipTypeDataDetails != null ? (
                          <FormControl
                            className="gender-dropdown dropdown"
                            fullWidth
                          >
                            <DropDownList
                              label="Membership Type"
                              className={classes.drpDnMembership}
                              data={
                                props.objModelMembershipDetails
                                  .MembershipTypeDetails
                                  .MembershipTypeDataDetails
                              }
                              textField="MembershipTypeName"
                              valueField="MembershipTypeValue"
                              defaultValue={
                                props.objModelMembershipDetails
                                  .MembershipTypeDetails.MembershipTypeSelection
                              }
                              onChange={onChangeMembershipType}
                            ></DropDownList>
                          </FormControl>
                        ) : (
                          <FormControl
                            className="gender-dropdown dropdown"
                            fullWidth
                          >
                            <DropDownList
                              className={classes.drpDnMembership}
                              onChange={onChangeMembershipType}
                            />
                          </FormControl>
                        )}
                        {Object.keys(dataMembershipTypeErrorMessage).map(
                          (key) => {
                            return (
                              <div className={classes.lblErrors}>
                                {dataMembershipTypeErrorMessage[key]}
                              </div>
                            );
                          }
                        )}
                      </Grid>
                    </Grid>
                  </CardContent>

                  <Divider sx={{ m: "20px 0 !important" }} />

                  <CardContent>
                    <Grid container spacing={5}>
                      <Grid item xs={12} sm={12}>
                        {props.objModelMembershipDetails != null &&
                        props.objModelMembershipDetails.AmateurTypeDetails !=
                          null &&
                        props.objModelMembershipDetails.AmateurTypeDetails
                          .AmateurDetailsSelection != null ? (
                          <div>
                            <Typography
                              variant="body2"
                              sx={{
                                fontWeight: 600,
                                fontSize: "16px !important",
                                color: "#4c4e64de",
                                m: "0!important",
                              }}
                            >
                              I am participating as a:
                            </Typography>
                            <RadioGroup
                              className={classes.lblAdditionalText}
                              data={
                                props.objModelMembershipDetails
                                  .AmateurTypeDetails.AmateurDataDetails
                              }
                              defaultValue={
                                props.objModelMembershipDetails
                                  .AmateurTypeDetails.AmateurDetailsSelection
                              }
                              onChange={onChangeAmateur}
                            />
                          </div>
                        ) : props.objModelMembershipDetails !== null &&
                          props.objModelMembershipDetails.AmateurTypeDetails !==
                            null ? (
                          <div>
                            <div className={classes.lblMembershipType}>
                              I am participating as a:
                            </div>
                            <RadioGroup
                              className={classes.lblAdditionalText}
                              data={
                                props.objModelMembershipDetails
                                  .AmateurTypeDetails.AmateurDataDetails
                              }
                              onChange={onChangeAmateur}
                            />
                          </div>
                        ) : (
                          <div />
                        )}
                        {Object.keys(dataAmateurErrorMessage).map((key) => {
                          return (
                            <div className={classes.lblErrors}>
                              {dataAmateurErrorMessage[key]}
                            </div>
                          );
                        })}
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Divider sx={{ m: "0 0 20px !important" }} />

                  <CardContent>
                    <Grid container spacing={5}>
                      <Grid item xs={12} sm={6}>
                        <div className={classes.lblMembershipTypeTop}></div>
                        {props.objModelMembershipDetails != null &&
                        props.objModelMembershipDetails
                          .MagazineSelectionDetails != null &&
                        props.objModelMembershipDetails.MagazineSelectionDetails
                          .MagazineSelectionDataDetails != null ? (
                          <FormControl
                            className="gender-dropdown dropdown"
                            fullWidth
                          >
                            <DropDownList
                              className={classes.drpDnMembership}
                              label="Magazine Selection"
                              data={
                                props.objModelMembershipDetails
                                  .MagazineSelectionDetails
                                  .MagazineSelectionDataDetails
                              }
                              textField="MagazineSelectionName"
                              valueField="MagazineSelectionValue"
                              defaultValue={
                                props.objModelMembershipDetails
                                  .MagazineSelectionDetails.MagazineSelection
                              }
                              onChange={onChangeMagazineSelection}
                            ></DropDownList>
                          </FormControl>
                        ) : (
                          <FormControl
                            className="gender-dropdown dropdown"
                            fullWidth
                          >
                            <DropDownList
                              className={classes.drpDnMembership}
                              onChange={onChangeMagazineSelection}
                            ></DropDownList>
                          </FormControl>
                        )}
                        {Object.keys(dataMagazineSelectionErrorMessage).map(
                          (key) => {
                            return (
                              <div className={classes.lblErrors}>
                                {dataMagazineSelectionErrorMessage[key]}
                              </div>
                            );
                          }
                        )}
                        <Box sx={{ mt: 2 }}>
                          {isFirstAddressValue ? (
                            props.objModelMembershipDetails != null &&
                            props.objModelMembershipDetails.AddressDetails !==
                              null &&
                            props.objModelMembershipDetails.AddressDetails
                              .Country !== null &&
                            (props.objModelMembershipDetails.AddressDetails
                              .Country === "United States" ||
                              props.objModelMembershipDetails.AddressDetails
                                .Country === "USA") ? (
                              <div
                                className={
                                  classes.divAdditionalMagazineShipping
                                }
                              >
                                <div
                                  className={classes.divAdditionalShippingLeft}
                                >
                                  <span className={classes.lblAdditionalText}>
                                    Additional Magazine Shipping Fees
                                  </span>
                                </div>
                                <div
                                  className={classes.divAdditionalShippingRight}
                                >
                                  <span className={classes.lblAdditionalText}>
                                    {
                                      props.objModelMembershipDetails
                                        .MagazineSelectionDetails.MagazineUSCost
                                    }
                                  </span>
                                </div>
                              </div>
                            ) : isAddressInternational &&
                              props.objModelMembershipDetails !== null &&
                              props.objModelMembershipDetails.AddressDetails !==
                                null &&
                              props.objModelMembershipDetails.AddressDetails
                                .Country !== null &&
                              props.objModelMembershipDetails.AddressDetails
                                .Country === "Canada" ? (
                              <div>
                                <span className={classes.lblInternationAddress}>
                                  Additional shipping fees will apply for
                                  international address
                                </span>
                                <div
                                  className={
                                    classes.divAdditionalMagazineShipping
                                  }
                                >
                                  <div
                                    className={
                                      classes.divAdditionalShippingLeft
                                    }
                                  >
                                    <span className={classes.lblAdditionalText}>
                                      Additional Magazine Shipping Fees
                                    </span>
                                  </div>
                                  <div
                                    className={
                                      classes.divAdditionalShippingRight
                                    }
                                  >
                                    <span className={classes.lblAdditionalText}>
                                      {
                                        props.objModelMembershipDetails
                                          .MagazineSelectionDetails
                                          .MagazineCanadaCost
                                      }
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ) : isAddressInternational &&
                              props.objModelMembershipDetails !== null &&
                              props.objModelMembershipDetails.AddressDetails !==
                                null &&
                              props.objModelMembershipDetails.AddressDetails
                                .Country !== null &&
                              props.objModelMembershipDetails.AddressDetails
                                .Country === "Mexico" ? (
                              <div>
                                <span className={classes.lblInternationAddress}>
                                  Additional shipping fees will apply for
                                  international address
                                </span>
                                <div
                                  className={
                                    classes.divAdditionalMagazineShipping
                                  }
                                >
                                  <div
                                    className={
                                      classes.divAdditionalShippingLeft
                                    }
                                  >
                                    <span className={classes.lblAdditionalText}>
                                      Additional Magazine Shipping Fees
                                    </span>
                                  </div>
                                  <div
                                    className={
                                      classes.divAdditionalShippingRight
                                    }
                                  >
                                    <span className={classes.lblAdditionalText}>
                                      {
                                        props.objModelMembershipDetails
                                          .MagazineSelectionDetails
                                          .MagazineMexicoCost
                                      }
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ) : isAddressInternational ? (
                              <div>
                                <span className={classes.lblInternationAddress}>
                                  Additional shipping fees will apply for
                                  international address
                                </span>
                                <div
                                  className={
                                    classes.divAdditionalMagazineShipping
                                  }
                                >
                                  <div
                                    className={
                                      classes.divAdditionalShippingLeft
                                    }
                                  >
                                    <span className={classes.lblAdditionalText}>
                                      Additional Magazine Shipping Fees
                                    </span>
                                  </div>
                                  <div
                                    className={
                                      classes.divAdditionalShippingRight
                                    }
                                  >
                                    <span className={classes.lblAdditionalText}>
                                      {
                                        props.objModelMembershipDetails
                                          .MagazineSelectionDetails
                                          .MagazineOthersCost
                                      }
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div />
                            )
                          ) : modelAddressDetails != null &&
                            modelAddressDetails.AddressDetails !== null &&
                            modelAddressDetails.AddressDetails.Country !==
                              null &&
                            (modelAddressDetails.AddressDetails.Country ===
                              "United States" ||
                              modelAddressDetails.AddressDetails.Country ===
                                "USA") ? (
                            <div
                              className={classes.divAdditionalMagazineShipping}
                            >
                              <div
                                className={classes.divAdditionalShippingLeft}
                              >
                                <span className={classes.lblAdditionalText}>
                                  Additional Magazine Shipping Fees
                                </span>
                              </div>
                              <div
                                className={classes.divAdditionalShippingRight}
                              >
                                <span className={classes.lblAdditionalText}>
                                  {
                                    props.objModelMembershipDetails
                                      .MagazineSelectionDetails.MagazineUSCost
                                  }
                                </span>
                              </div>
                            </div>
                          ) : isAddressInternational &&
                            modelAddressDetails !== null &&
                            modelAddressDetails.AddressDetails !== null &&
                            modelAddressDetails.AddressDetails.Country !==
                              null &&
                            modelAddressDetails.AddressDetails.Country ===
                              "Canada" ? (
                            <div>
                              <span className={classes.lblInternationAddress}>
                                Additional shipping fees will apply for
                                international address
                              </span>
                              <div
                                className={
                                  classes.divAdditionalMagazineShipping
                                }
                              >
                                <div
                                  className={classes.divAdditionalShippingLeft}
                                >
                                  <span className={classes.lblAdditionalText}>
                                    Additional Magazine Shipping Fees
                                  </span>
                                </div>
                                <div
                                  className={classes.divAdditionalShippingRight}
                                >
                                  <span className={classes.lblAdditionalText}>
                                    {
                                      props.objModelMembershipDetails
                                        .MagazineSelectionDetails
                                        .MagazineCanadaCost
                                    }
                                  </span>
                                </div>
                              </div>
                            </div>
                          ) : isAddressInternational &&
                            modelAddressDetails !== null &&
                            modelAddressDetails.AddressDetails !== null &&
                            modelAddressDetails.AddressDetails.Country !==
                              null &&
                            modelAddressDetails.AddressDetails.Country ===
                              "Mexico" ? (
                            <div>
                              <span className={classes.lblInternationAddress}>
                                Additional shipping fees will apply for
                                international address
                              </span>
                              <div
                                className={
                                  classes.divAdditionalMagazineShipping
                                }
                              >
                                <div
                                  className={classes.divAdditionalShippingLeft}
                                >
                                  <span className={classes.lblAdditionalText}>
                                    Additional Magazine Shipping Fees
                                  </span>
                                </div>
                                <div
                                  className={classes.divAdditionalShippingRight}
                                >
                                  <span className={classes.lblAdditionalText}>
                                    {
                                      props.objModelMembershipDetails
                                        .MagazineSelectionDetails
                                        .MagazineMexicoCost
                                    }
                                  </span>
                                </div>
                              </div>
                            </div>
                          ) : isAddressInternational ? (
                            <div>
                              <span className={classes.lblInternationAddress}>
                                Additional shipping fees will apply for
                                international address
                              </span>
                              <div
                                className={
                                  classes.divAdditionalMagazineShipping
                                }
                              >
                                <div
                                  className={classes.divAdditionalShippingLeft}
                                >
                                  <span className={classes.lblAdditionalText}>
                                    Additional Magazine Shipping Fees
                                  </span>
                                </div>
                                <div
                                  className={classes.divAdditionalShippingRight}
                                >
                                  <span className={classes.lblAdditionalText}>
                                    {
                                      props.objModelMembershipDetails
                                        .MagazineSelectionDetails
                                        .MagazineOthersCost
                                    }
                                  </span>
                                </div>
                              </div>
                            </div>
                          ) : (
                            <div />
                          )}
                        </Box>
                      </Grid>

                      <Grid item xs={12} sm={6}>
                        {props.objModelMembershipDetails !== null &&
                        props.objModelMembershipDetails
                          .AreaDesignationDetails !== null &&
                        props.objModelMembershipDetails.AreaDesignationDetails
                          .AreaDesignationDataDetails !== null ? (
                          <FormControl
                            className="gender-dropdown dropdown"
                            fullWidth
                          >
                            <DropDownList
                              className={classes.drpDnMembership}
                              label="Area Designation for Awards & Programs"
                              data={
                                props.objModelMembershipDetails
                                  .AreaDesignationDetails
                                  .AreaDesignationDataDetails
                              }
                              textField="AreaDesignationName"
                              valueField="AreaDesignationValue"
                              defaultValue={
                                props.objModelMembershipDetails
                                  .AreaDesignationDetails
                                  .AreaDesignationSelection
                              }
                              onChange={onChangeAreaDesignation}
                            ></DropDownList>
                          </FormControl>
                        ) : (
                          <FormControl
                            className="gender-dropdown dropdown"
                            fullWidth
                          >
                            <DropDownList
                              className={classes.drpDnMembership}
                              onChange={onChangeAreaDesignation}
                            ></DropDownList>
                          </FormControl>
                        )}
                        {Object.keys(dataAreaDesignationErrorMessage).map(
                          (key) => {
                            return (
                              <div className={classes.lblErrors}>
                                {dataAreaDesignationErrorMessage[key]}
                              </div>
                            );
                          }
                        )}
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Divider sx={{ m: "20px 0 !important" }} />

                  <CardContent>
                    <Grid container spacing={5}>
                      <Grid item xs={12} sm={12}>
                        {props.objModelMembershipDetails !== null &&
                        props.objModelMembershipDetails
                          .YoungRiderProgramDetails !== null &&
                        props.objModelMembershipDetails.YoungRiderProgramDetails
                          .YoungRiderProgramDetailsSelection !== null ? (
                          <div className="radio_btn">
                            <Typography
                              variant="body2"
                              sx={{
                                fontWeight: 600,
                                fontSize: "16px !important",
                                color: "#4c4e64de",
                                m: "0!important",
                              }}
                            >
                              Young Rider Program Options
                            </Typography>
                            <RadioGroup
                              className={classes.lblAdditionalText}
                              data={
                                props.objModelMembershipDetails
                                  .YoungRiderProgramDetails
                                  .YoungRiderProgramDataDetails
                              }
                              defaultValue={
                                props.objModelMembershipDetails
                                  .YoungRiderProgramDetails
                                  .YoungRiderProgramDetailsSelection
                              }
                              onChange={onChangeYoungRiderProgram}
                            />
                          </div>
                        ) : props.objModelMembershipDetails !== null &&
                          props.objModelMembershipDetails
                            .YoungRiderProgramDetails !== null ? (
                          <div className="radio_btn">
                            <Typography
                              variant="body2"
                              sx={{
                                fontWeight: 600,
                                fontSize: "16px !important",
                                color: "#4c4e64de",
                                m: "0!important",
                              }}
                            >
                              Young Rider Program Options
                            </Typography>
                            <RadioGroup
                              className={classes.lblAdditionalText}
                              data={
                                props.objModelMembershipDetails
                                  .YoungRiderProgramDetails
                                  .YoungRiderProgramDataDetails
                              }
                              onChange={onChangeYoungRiderProgram}
                            />
                          </div>
                        ) : (
                          <div />
                        )}
                        {Object.keys(dataYoungRiderProgramErrorMessage).map(
                          (key) => {
                            return (
                              <div className={classes.lblErrors}>
                                {dataYoungRiderProgramErrorMessage[key]}
                              </div>
                            );
                          }
                        )}
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Divider sx={{ m: "0 0 20px !important" }} />

                  <CardContent>
                    <Grid container spacing={5}>
                      <Grid item xs={12} sm={6}>
                        {props.objModelMembershipDetails != null &&
                        props.objModelMembershipDetails.AnnualDonationDetails !=
                          null &&
                        props.objModelMembershipDetails.AnnualDonationDetails
                          .AnnualDonationDataDetails != null ? (
                          <FormControl
                            className="gender-dropdown dropdown"
                            fullWidth
                          >
                            <DropDownList
                              className={classes.drpDnMembership}
                              label="Annual Donation"
                              data={
                                props.objModelMembershipDetails
                                  .AnnualDonationDetails
                                  .AnnualDonationDataDetails
                              }
                              textField="AnnualDonationDetailsName"
                              valueField="AnnualDonationDetailsValue"
                              defaultValue={
                                props.objModelMembershipDetails
                                  .AnnualDonationDetails
                                  .AnnualDonationDetailsSelection
                              }
                              onChange={onChangeAnnualDonation}
                            ></DropDownList>
                          </FormControl>
                        ) : (
                          <FormControl
                            className="gender-dropdown dropdown"
                            fullWidth
                          >
                            <DropDownList
                              className={classes.drpDnMembership}
                              onChange={onChangeAnnualDonation}
                            ></DropDownList>
                          </FormControl>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <div className={classes.divAnnualDonationDetails}>
                          <span>$ </span>
                          <NumericTextBox
                            className={classes.nboxAnnualDonation}
                            defaultValue={
                              props.objModelMembershipDetails
                                .AnnualDonationDetails.AnnualDonationAmount
                                ? props.objModelMembershipDetails
                                    .AnnualDonationDetails.AnnualDonationAmount
                                : null
                            }
                            min={5}
                            max={5000}
                            step={5}
                            onChange={onChangeAnnualDonationAmount}
                          />
                        </div>
                        {Object.keys(dataAnnualDonationAmountErrorMessage).map(
                          (key) => {
                            return (
                              <div className={classes.lblErrors}>
                                {dataAnnualDonationAmountErrorMessage[key]}
                              </div>
                            );
                          }
                        )}
                      </Grid>
                    </Grid>
                  </CardContent>
                  <Divider sx={{ m: "20px 0 !important" }} />

                  <CardContent>
                    <Grid container spacing={5}>
                      <Grid item xs={12} sm={12}>
                        <Typography
                          className={classes.lblMembershipType}
                          variant="body2"
                          sx={{
                            fontWeight: 600,
                            fontSize: "16px !important",
                            color: "#4c4e64de",
                            m: "0 0 10px !important",
                          }}
                        >
                          {
                            props.objModelMembershipDetails
                              .AutoRenewMembershipDetails
                              .AutoRenewMembershipTitle
                          }
                        </Typography>
                        <FormControlLabel
                          className="membership_type"
                          label={
                            props.objModelMembershipDetails
                              .AutoRenewMembershipDetails
                              .AutoRenewMembershipName
                          }
                          control={
                            <Checkbox
                              defaultValue={
                                props.objModelMembershipDetails
                                  .AutoRenewMembershipDetails
                                  .AutoRenewMembershipValue
                              }
                              onChange={onChangeAutoRenewMembership}
                            />
                          }
                        />
                      </Grid>
                    </Grid>
                  </CardContent>

                  <Divider sx={{ m: "20px 0 0 !important" }} />
                  <CardContent>
                    <Grid container spacing={5}>
                      <Grid item xs={12} sm={12}>
                        <Typography
                          className={classes.lblMembershipType}
                          variant="body2"
                          sx={{
                            fontWeight: 600,
                            fontSize: "16px !important",
                            color: "#4c4e64de",
                            m: "0 0 10px !important",
                            textAlign: "center",
                          }}
                        >
                          Credit Card On File
                        </Typography>
                        <div className={classes.divCreditCardDetails}>
                          {(() => {
                            if (
                              !isPaymentDone &&
                              props.objModelMembershipDetails.CreditCardDetails
                                .CreditCardExpirationStatus == "NO CARD"
                            )
                              return (
                                <Typography
                                  className={classes.divNoCreditCardOnFile}
                                  variant="body2"
                                  sx={{
                                    fontWeight: 600,
                                    fontSize: "16px !important",
                                    color: "blue !important",
                                    m: "0 0 10px !important",
                                    textAlign: "left",
                                  }}
                                >
                                  NO CARD ON FILE
                                </Typography>
                              );
                            else if (
                              !isPaymentDone &&
                              props.objModelMembershipDetails.CreditCardDetails
                                .CreditCardExpirationStatus == "EXPIRED"
                            )
                              return (
                                <div
                                  className={classes.divCreditCardOnFileExpired}
                                >
                                  <div
                                    className={classes.divCreditCardOnFileLeft}
                                  >
                                    <span>
                                      {
                                        props.objModelMembershipDetails
                                          .CreditCardDetails.CreditCardType
                                      }{" "}
                                      #
                                      {
                                        props.objModelMembershipDetails
                                          .CreditCardDetails.CreditCardNo
                                      }
                                    </span>
                                  </div>
                                  <div
                                    className={classes.divCreditCardOnFileRight}
                                  >
                                    <span>
                                      {
                                        props.objModelMembershipDetails
                                          .CreditCardDetails
                                          .CreditCardExpirationMonth
                                      }
                                      /
                                      {
                                        props.objModelMembershipDetails
                                          .CreditCardDetails
                                          .CreditCardExpirationYear
                                      }
                                    </span>
                                    <span>
                                      {" "}
                                      (
                                      {
                                        props.objModelMembershipDetails
                                          .CreditCardDetails
                                          .CreditCardExpirationStatus
                                      }
                                      )
                                    </span>
                                  </div>
                                </div>
                              );
                            else if (
                              !isPaymentDone &&
                              props.objModelMembershipDetails.CreditCardDetails
                                .CreditCardExpirationStatus == "VALID"
                            )
                              return (
                                <div
                                  className={
                                    classes.divCreditCardOnFileNotExpired
                                  }
                                >
                                  <div
                                    className={classes.divCreditCardOnFileLeft}
                                  >
                                    <span>
                                      {
                                        props.objModelMembershipDetails
                                          .CreditCardDetails.CreditCardType
                                      }{" "}
                                      #
                                      {
                                        props.objModelMembershipDetails
                                          .CreditCardDetails.CreditCardNo
                                      }
                                    </span>
                                  </div>
                                  <div
                                    className={classes.divCreditCardOnFileRight}
                                  >
                                    <span>
                                      {
                                        props.objModelMembershipDetails
                                          .CreditCardDetails
                                          .CreditCardExpirationMonth
                                      }
                                      /
                                      {
                                        props.objModelMembershipDetails
                                          .CreditCardDetails
                                          .CreditCardExpirationYear
                                      }
                                    </span>
                                  </div>
                                </div>
                              );
                            else if (isPaymentDone)
                              return (
                                <div
                                  className={
                                    classes.divCreditCardOnFileNotExpired
                                  }
                                >
                                  <div
                                    className={classes.divCreditCardOnFileLeft}
                                  >
                                    <span>
                                      {
                                        props.objModelMembershipDetails
                                          .CreditCardDetails.CreditCardType
                                      }{" "}
                                      #
                                      {
                                        props.objModelMembershipDetails
                                          .CreditCardDetails.CreditCardNo
                                      }
                                    </span>
                                  </div>
                                  <div
                                    className={classes.divCreditCardOnFileRight}
                                  >
                                    <span>
                                      {
                                        props.objModelMembershipDetails
                                          .CreditCardDetails
                                          .CreditCardExpirationMonth
                                      }
                                      /
                                      {
                                        props.objModelMembershipDetails
                                          .CreditCardDetails
                                          .CreditCardExpirationYear
                                      }
                                    </span>
                                  </div>
                                </div>
                              );
                          })()}
                        </div>
                        <div className="addpymennt_btn">
                          {isShowCardDetailsPopup ? (
                            <Button
                              themeColor={"primary"}
                              iconClass="fa fa-solid fa-minus"
                              onClick={onCardDetailsClick}
                            >
                              Add/Update Payment
                            </Button>
                          ) : (
                            <Button
                              themeColor={"primary"}
                              iconClass="fa fa-solid fa-plus"
                              onClick={onCardDetailsClick}
                            >
                              Add/Update Payment
                            </Button>
                          )}
                        </div>
                      </Grid>
                      <Grid item xs={12} sm={12}>
                        <UpdateCreditCardOnFile
                          UserID={UserID}
                          objModelMembershipDetails={
                            props.objModelMembershipDetails
                          }
                          objModelSelectedMembershipDetails={
                            props.objModelSelectedMembershipDetails
                          }
                          isShowCardDetailsPopup={isShowCardDetailsPopup}
                          updateShowCardDetailsPopup={
                            updateShowCardDetailsPopup
                          }
                          isPaymentDone={isPaymentDone}
                          updateIsPaymentDone={updateIsPaymentDone}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>

                  <Divider sx={{ m: "0 !important" }} />
                  <div className={classes.divSaveSettings}>
                    <Button
                      className="renewal_btn"
                      sx={{
                        backgroundColor: "#000",
                        color: "#fff",
                        lineHeight: 2,
                      }}
                      type="Submit"
                    >
                      SAVE SETTINGS
                    </Button>
                  </div>
                </div>
              ) : (
                <div>
                  <br />
                  <span className={classes.lblAutoRenewalSettings}>
                    This membership account is currently undergoing updates and
                    maintenance. If you have any questions, please contact the
                    office at 703-779-0440 (1) or{" "}
                    <u>
                      <a
                        href="mailto:support@useventing.com"
                        className={classes.lblAutoRenewalSettings}
                      >
                        support@useventing.com
                      </a>
                    </u>
                    .
                  </span>
                </div>
              )}
            </div>
          </form>
        </Card>
      </Box>
    </Box>
  );
};

export default MembershipSettings;
